import React, { useMemo, useRef, useState } from 'react'
import { ImageOverlay, MapContainer, Marker } from 'react-leaflet'
import { useParams } from 'react-router-dom'
import { CRS, LatLngTuple } from 'leaflet'
import useViewBuildingDetail from 'pages/building/buildingDetailViewMode/hooks/useViewBuildingDetail'
import { numberIcon } from 'pages/building/floor/area/CustomMarker'
import { useRecoilState } from 'recoil'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import { FloorDetail, FloorMapElement, Point } from 'types/building'

import CloseIcon from 'components/icons/CloseIcon'

export default function CommunityMiniMap() {
  const { id } = useParams()
  const mapContainerPreviewRef = useRef<any>(null)
  const [previewImgBounds, setPreviewImgBounds] = useState<LatLngTuple>([0, 0])

  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)

  const {
    data: floorDetail,
    isValidating: isValidatingFloor,
    mutate: refetchFloor
  } = useSWR<FloorDetail>(
    currentViewer?.selectedFloor
      ? {
          url: `v1/tour/floors/${currentViewer?.selectedFloor}`
        }
      : null,
    {
      revalidateOnMount: false
    }
  )

  const { data: mapItems } = useSWR<FloorMapElement>(
    currentViewer?.selectedFloor && !isValidatingFloor
      ? {
          url: 'v1/tour/points',
          params: {
            buildingId: id,
            floorId: currentViewer?.selectedFloor
          }
        }
      : null,
    {
      revalidateOnMount: false
    }
  )

  const imagePath = useMemo(
    () => floorDetail?.mapFile.s3Path || '',
    [floorDetail?.mapFile.s3Path]
  )
  const points = useMemo(() => mapItems?.points || [], [mapItems?.points])

  const { mapOnReady } = useViewBuildingDetail({
    imagePath,
    refetchFloor
  })

  const handleCloseMiniMap = () => {
    setCurrentViewer((prev) => ({ ...prev, isOpenMiniMap: false }))
  }

  const markerEventHandler = (point: Point) => {
    return {
      click() {
        setCurrentViewer((prev) => ({
          ...prev,
          selectedArea: `${point.areaId}`,
          selectedPoint: point.id
        }))
        handleCloseMiniMap()
      }
    }
  }

  return imagePath ? (
    <div className="absolute top-0 left-0 w-full h-full flex bg-[#00000099] z-[700]">
      <div className="m-auto flex w-[380px] sm:w-[1006px] h-[580px] bg-white relative rounded-[20px]">
        <div
          className="absolute top-5 right-5 cursor-pointer"
          onClick={handleCloseMiniMap}
        >
          <CloseIcon />
        </div>
        <div className="w-[360px] h-[450px] sm:w-[700px] sm:h-[490px] m-auto">
          <MapContainer
            ref={mapContainerPreviewRef}
            center={[-99999, -99999]}
            zoom={0}
            zoomSnap={0.1}
            zoomDelta={0.5}
            className="area-map-container"
            crs={CRS.Simple}
            zoomControl={false}
            attributionControl={false}
            preferCanvas
            whenReady={() => {
              mapOnReady(mapContainerPreviewRef, setPreviewImgBounds)
            }}
          >
            {points.map((point) => (
              <Marker
                key={`preview-marker-${point.id}`}
                draggable={false}
                bubblingMouseEvents={false}
                eventHandlers={markerEventHandler(point)}
                zIndexOffset={
                  point.id === currentViewer.selectedPoint
                    ? 450
                    : 400 + point.order
                }
                // leaflet position is latlng with y=lat x=lng
                position={[point.y, point.x]}
                icon={numberIcon(
                  point,
                  false,
                  point.id === currentViewer.selectedPoint
                )}
              />
            ))}

            <ImageOverlay url={imagePath} bounds={[[0, 0], previewImgBounds]} />
          </MapContainer>
        </div>
      </div>
    </div>
  ) : null
}
