import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Menu, Typography } from 'antd'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useDirectory from 'hooks/useDirectory'
import useSWR from 'swr'
import { Folders } from 'types/building'

import Spinner from 'components/atoms/Spinner'
import AddIcon from 'components/icons/AddIcon'
import EditPencilIcon from 'components/icons/EditPencilIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'
import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

import BCPAddEditFolderModal from './BCPAddEditFolderModal'
import InstructionMenu from './MenuInstruction'

const expandIcon = ({ isOpen }: any) => {
  return <RotatedArrowIcon className={isOpen ? 'rotate-180' : '-rotate-90'} />
}

export default function DocumentMenu() {
  const { id } = useParams()

  const [selectedFolder, setSelectedFolder] = useState<{
    doc: Folders
    subDoc?: Folders
  }>()
  const [option, setOption] = useState('main-menu')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedCustomFolder, setSelectedCustomFolder] = useState<{
    id: number
    categoryId: number
    label: string
  }>()

  const { getDirectory } = useDirectory()

  const {
    data: bcpCustomFolders,
    isValidating,
    mutate
  } = useSWR(
    option === 'main-menu' && getDirectory() === 'bcp'
      ? { url: `v1/bcp/buildings/${id}/folders` }
      : null
  )

  const handleMainMenu = () => setOption('main-menu')

  const handleDocumentMenu = (doc: Folders, subDoc?: Folders) => {
    setSelectedFolder({
      doc,
      subDoc
    })
    setOption('document-menu')
  }

  const handleCloseModal = () => {
    setSelectedCustomFolder(undefined)
    setIsOpenModal(false)
  }

  const handleSelectFolder = (
    id: number,
    categoryId: number,
    label: string
  ) => {
    setSelectedCustomFolder({ id, categoryId, label })
    setIsOpenModal(true)
  }

  const items: ItemType<MenuItemType>[] = useMemo(() => {
    const bcpFolders = [
      ...Const.BCP_BUILDING_FOLDER,
      ...(bcpCustomFolders
        ?.sort((a: any, b: any) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
        .map((item: any) => ({
          id: item.id,
          categoryId: item.categoryId,
          label: item.name,
          isFileHolder: false,
          isCustomFolder: true,
          children: item.childFolders.map((folder: any) => ({
            id: folder.categoryId,
            categoryId: folder.categoryId,
            label: folder.name,
            isFileHolder: true,
            children: null
          }))
        })) || [])
    ]

    const buildingFolderTree =
      getDirectory() === 'bcp' ? bcpFolders : Const.BUILDING_FOLDER

    return buildingFolderTree.map((item) => ({
      key: item?.isCustomFolder ? `custom-${item.id}` : item.id,
      label: (
        <div
          className="grid grid-cols-[1fr_auto] items-center gap-[10px]"
          onClick={() => item.isFileHolder && handleDocumentMenu(item)}
        >
          <div
            className="flex items-center gap-2 mr-auto text-white hover:!text-[#A1C6B4] overflow-hidden bcp-custom-menu-hover"
            onClick={(e) => {
              if (item.isCustomFolder) {
                e.stopPropagation()
                handleSelectFolder(item.id, item.categoryId, item.label)
              }
            }}
          >
            <div className="text-[13px] leading-[19.5px] text-ellipsis overflow-hidden">
              {item.label}
            </div>
            {item.isCustomFolder ? (
              <EditPencilIcon className="my-auto h-[14px] w-[14px]" />
            ) : null}
          </div>
          {item.isFileHolder && <RotatedArrowIcon className="-rotate-90" />}
        </div>
      ),
      children: item.children?.map((subItem: any) => ({
        key: item?.isCustomFolder ? `custom-${subItem.id}` : subItem.id,
        label: (
          <div
            className="flex justify-between gap-10 cursor-pointer my-auto"
            onClick={() => handleDocumentMenu(item, subItem)}
          >
            <Typography className="text-white text-[13px] leading-[19.5px] my-auto">
              {subItem.label}
            </Typography>
            <RightArrowIcon color="white" size={12} />
          </div>
        )
      }))
    }))
  }, [bcpCustomFolders, getDirectory])

  return (
    <>
      {isValidating && <Spinner />}
      {option === 'main-menu' && !isValidating && (
        <div>
          <Menu
            theme="dark"
            inlineIndent={0}
            mode="inline"
            items={items}
            rootClassName="custom-document-menu"
            expandIcon={expandIcon}
            className="folder-list-menu"
          />
          {getDirectory() === 'bcp' && bcpCustomFolders?.length !== 3 && (
            <div className="flex">
              <div
                className="ml-auto mr-[11px] my-[9px] cursor-pointer"
                onClick={() => setIsOpenModal(true)}
              >
                <AddIcon />
              </div>
            </div>
          )}
        </div>
      )}
      {option === 'document-menu' && (
        <InstructionMenu
          handleBack={handleMainMenu}
          selectedFolder={selectedFolder}
        />
      )}
      {isOpenModal && (
        <BCPAddEditFolderModal
          selectedCustomFolder={selectedCustomFolder}
          refetchFolders={mutate}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}
