import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

import Link from 'components/atoms/Link'
import Title from 'components/atoms/Title'

export default function ResetPasswordSuccess() {
  const { t } = useTranslation()

  return (
    <>
      <Title title="resetPassword.resetSuccessTitle" />

      <p className=" text-lg my-5">{t('resetPassword.subTitle1')}</p>
      <p className=" text-lg my-5">{t('resetPassword.subTitle2')}</p>

      <Link
        to="/signin"
        className="text-primary font-semibold flex justify-center underline"
      >
        <Button type="primary" block size="large" className="mb-6">
          {t('resetPassword.buttonBackSignin')}
        </Button>
      </Link>
    </>
  )
}
