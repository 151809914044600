import { useEffect } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import CommunityBuildingDetail from 'pages/building/communitBuildingDetail'
import Homepage from 'pages/homepage'

import BcpLayout from 'components/layouts/BcpLayout'
import ShimizuLayout from 'components/layouts/ShimizuLayout'
import TourLayout from 'components/layouts/TourLayout'
import BgCommonLayout from 'components/templates/BgCommonLayout'
import DefaultLayout from 'components/templates/DefaultLayout'
import EmptyLayout from 'components/templates/EmptyLayout'

import authRoutes from './authRoutes'
import bcpRoutes from './bcpRoutes'
import ProtectedRoute from './ProtectedRoute'
import publicRoutes from './publicRoutes'
import RedirectHomeRoute from './RedirectHomeRoute'
import shimizuRoutes from './shimizuRoutes'
import tourRoutes from './tourRoutes'

export default function Routes() {
  const { profile, mutate } = useAuth()

  useEffect(() => {
    window.addEventListener('storage', () => mutate())
    return () => {
      window.removeEventListener('storage', () => mutate())
    }
  }, [mutate])

  return (
    <Switch>
      {authRoutes.map(
        ({ path, children, layout: RootLayout = DefaultLayout }) => (
          <Route key={path} path={path} element={<RedirectHomeRoute />}>
            {children.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={<RootLayout>{element}</RootLayout>}
              />
            ))}
          </Route>
        )
      )}

      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          index
          element={
            <BgCommonLayout>
              <Homepage />
            </BgCommonLayout>
          }
        />
      </Route>

      <Route element={<ShimizuLayout />}>
        {shimizuRoutes.map(
          ({
            path,
            children,
            layout: RootLayout = DefaultLayout,
            guardOnLogged
          }) => (
            <Route
              key={path}
              path={path}
              element={
                guardOnLogged ? <RedirectHomeRoute /> : <ProtectedRoute />
              }
            >
              {children.map(
                ({
                  role,
                  index,
                  path,
                  element,
                  layout: NestedLayout = RootLayout
                }) =>
                  (!role || !profile || role.includes(profile.role)) && (
                    <Route
                      key={path}
                      path={path}
                      index={index}
                      element={<NestedLayout>{element}</NestedLayout>}
                    />
                  )
              )}
            </Route>
          )
        )}
      </Route>

      <Route element={<BcpLayout />}>
        {bcpRoutes.map(
          ({
            path,
            children,
            layout: RootLayout = DefaultLayout,
            guardOnLogged
          }) => (
            <Route
              key={path}
              path={path}
              element={
                guardOnLogged ? <RedirectHomeRoute /> : <ProtectedRoute />
              }
            >
              {children.map(
                ({
                  role,
                  index,
                  path,
                  element,
                  layout: NestedLayout = RootLayout
                }) =>
                  (!role || !profile || role.includes(profile.role)) && (
                    <Route
                      key={path}
                      path={path}
                      index={index}
                      element={<NestedLayout>{element}</NestedLayout>}
                    />
                  )
              )}
            </Route>
          )
        )}
      </Route>

      <Route element={<TourLayout />}>
        {tourRoutes.map(
          ({
            path,
            children,
            layout: RootLayout = DefaultLayout,
            guardOnLogged
          }) => (
            <Route
              key={path}
              path={path}
              element={
                guardOnLogged ? <RedirectHomeRoute /> : <ProtectedRoute />
              }
            >
              {children.map(
                ({
                  role,
                  index,
                  path,
                  element,
                  layout: NestedLayout = RootLayout
                }) =>
                  (!role || !profile || role.includes(profile.role)) && (
                    <Route
                      key={path}
                      path={path}
                      index={index}
                      element={<NestedLayout>{element}</NestedLayout>}
                    />
                  )
              )}
            </Route>
          )
        )}
        <Route
          path="community/buildings/:id"
          element={
            <EmptyLayout>
              <CommunityBuildingDetail />
            </EmptyLayout>
          }
        />
      </Route>

      {publicRoutes.children.map(
        ({ name, path, element, layout: Layout = DefaultLayout }) => (
          <Route key={name} path={path} element={<Layout>{element}</Layout>} />
        )
      )}
    </Switch>
  )
}
