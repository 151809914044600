import L from 'leaflet'
import Utils from 'utils'

const numberIcon = (item: any, isInArea: boolean, isSelected?: boolean) => {
  const icon = L.divIcon({
    className: `rounded-full !w-8 !h-8 flex ${
      item.order < 100 ? 'text-base' : 'text-sm'
    } text-white !-mt-[16px] !-ml-[16px] ${Utils.getPointColor(
      item,
      isInArea,
      isSelected
    )}`,
    html: `<div style="margin:auto;">${item.order}</div>`
  })
  return icon
}

const smallNumberIcon = (item: any, selectedId?: string) => {
  const icon = L.divIcon({
    className: `rounded-full !w-[10px] !h-[10px] flex ${
      item.order < 100 ? 'text-[6px]' : 'text-[5px]'
    } text-white ${
      item.id === selectedId ? 'theme-bg-primary' : 'bg-[#021120]'
    }`,

    html: `<div style="margin:auto;">${item.order}</div>`
  })
  return icon
}

const rotatedMarkerIcon = (rotationAngle: any) => {
  const icon = new L.DivIcon({
    iconUrl: '/assets/view.png',
    iconAnchor: [35, 50],
    className: 'rotating-marker',
    html: `<img src="/assets/view.png" draggable="false" style="clip-path: polygon(0 0, 50% 100%, 100% 0); height:50px !important; width:70px !important;transform: rotate(${rotationAngle}deg);transform-origin: 50% 100%;" />`
  })
  return icon
}

const rotatedMarkerIconDense = (rotationAngle: any) => {
  const icon = new L.DivIcon({
    iconUrl: '/assets/dense-view.png',
    iconAnchor: [35, 50],
    className: 'rotating-marker',
    html: `<img src="/assets/dense-view.png" draggable="false" style="clip-path: polygon(0 0, 50% 100%, 100% 0); height:50px !important; width:70px !important;transform: rotate(${rotationAngle}deg);transform-origin: 50% 100%;" />`
  })
  return icon
}

export {
  numberIcon,
  smallNumberIcon,
  rotatedMarkerIcon,
  rotatedMarkerIconDense
}
