import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import { CurrentUser } from 'models/User'
import useSWR from 'swr'
import { BuildingLeftMenu, FloorPointList } from 'types/building'

import Spinner from 'components/atoms/Spinner'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import DropdownMenu from '../MenuDropdown'

import FloorPoint from './FloorPointElement'

type Props = {
  profile: CurrentUser | null | undefined
  handleBack: () => void
  handleSelectOption: (option: BuildingLeftMenu) => void
}

export default function ExportImages({
  profile,
  handleBack,
  handleSelectOption
}: Props) {
  const { t } = useTranslation()

  const { id } = useParams()

  const { data, isValidating } = useSWR<FloorPointList>({
    url: `v1/buildings/${id}/floor-point-list`
  })

  return (
    <div>
      <div className="flex justify-between align-middle h-[40px] ml-[10px] cursor-pointer">
        <div className="flex gap-3 cursor-pointer" onClick={handleBack}>
          <div className="my-auto">
            <LeftArrowIcon />
          </div>
          <Typography.Text className="text-inherit text-white font-[700] text-[16px] my-auto">
            {t('translation.buildingInformation')}
          </Typography.Text>
        </div>
        <div>
          <DropdownMenu
            handleSelectOption={handleSelectOption}
            profile={profile}
          />
        </div>
      </div>
      <Spinner spinning={isValidating}>
        <div>
          <div className="h-[36px] px-[8px] pl-[12px] border-0 border-y border-solid theme-border-secondary flex items-center">
            <Typography.Text className="text-white text-[13px]">
              {t('translation.exportImages')}
            </Typography.Text>
          </div>
          {data ? (
            <>
              <div className="py-[10px]">
                <div className="flex flex-col gap-[10px]">
                  {(data.floors || []).map((floor) => (
                    <FloorPoint key={floor.id} floor={floor} />
                  ))}
                </div>
              </div>
              <FloorPoint
                floor={{
                  name: t('translation.totalPointBuilding'),
                  totalImage360: data.totalImage360Building,
                  totalPoint: data.totalPointBuilding,
                  id: 0,
                  order: 0
                }}
              />
            </>
          ) : null}
        </div>
      </Spinner>
    </div>
  )
}
