import { Navigate, Outlet } from 'react-router-dom'
import useAuth from 'hooks/useAuth'

export default function RedirectHomeRoute() {
  const { profile } = useAuth()

  const path = '/'

  if (profile) return <Navigate to={path} replace />

  return <Outlet />
}
