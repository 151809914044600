import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Col,
  Form,
  FormInstance,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Typography
} from 'antd'
import axios from 'axios'
import Const from 'constants/constant'
import FormComponent from 'constants/form'
import useAuth from 'hooks/useAuth'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { CurrentOrganization } from 'models/Organization'
import { Role } from 'models/User'
import useSWR from 'swr'
import { OrderByType } from 'types'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

import ModalDeleteOrganization from '../../organizationDetail/components/ModalDelete'

import ModalManageBranchAdmin from './BranchAdminManager/ModalManageBranchAdmin'
import FormItemSelectUser from './FormSelectUserCompact'

type Props = {
  form: FormInstance
  organization?: CurrentOrganization
}
export default function FormRegister({ form, organization }: Props) {
  const zipcodeField = Form.useWatch('zipcode', form)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isManageBranchAdmin, setIsManageBranchAdmin] = useState<boolean>(false)
  const [isButtonInvalid, setIsButtonInvalid] = useState<boolean>(false)
  const [organizationName, setOrganizationName] = useState<string>()
  const [versionAcessValue, setVersionAcessValue] = useState({
    torisetsu: 1,
    bcp: 0,
    tour: 0
  })

  const { t } = useTranslation()
  const { profile } = useAuth()
  const { rrdNavigate, navigate } = useDirectory()
  const params = useParams()
  const pathDetailAPI = params.id
    ? `/v1/organizations/${params.id}`
    : 'v1/organizations'

  const { mutate } = useSWR({
    url: `/v1/organizations`,
    params: {
      sort: 'id',
      sortOrder: OrderByType.descend,
      pageSize: Const.DEFAULT_PAGINATION.defaultPageSize,
      page: Const.DEFAULT_PAGINATION.current,
      fields: 'buildingCount'
    }
  })

  const { trigger, isMutating } = useMutation(pathDetailAPI)

  const handleGetCodeAddress = async () => {
    try {
      const res = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${
          zipcodeField?.trim() || organization?.zipcode
        }`
      )
      const address = res.data.results[0]
      form.setFieldsValue({
        zipcode: zipcodeField?.trim(),
        prefCode: Number(address.prefcode),
        cityName: address.address2,
        streetName: address.address3
      })
    } catch (error) {
      // TODO
    }
  }

  const onFinish = (value: any) => {
    try {
      setIsButtonInvalid(true)
      if (!isMutating) {
        const versionAccess =
          versionAcessValue.bcp +
          versionAcessValue.torisetsu +
          versionAcessValue.tour
        const data =
          profile?.role === Role.SA ? { ...value, versionAccess } : value
        if (profile?.role === Role.SA && versionAccess === 0) {
          notification.error({
            message: '１つ以上契約にチェックしてください'
          })
          return
        }
        trigger(
          {
            method: 'post',
            data
          },
          {
            onSuccess: () => {
              notification.success({
                key: 'updatable',
                message: t('organization.successNotification')
              })
              form.setFieldsValue(Utils.trimObjectValues(value))
              mutate()
              !params.id ? navigate('/organizations') : null
            },
            onError: (error) => {
              if (error?.response?.data?.error?.key === 'email_domain_unique') {
                Utils.handleError(error, t, form)
              } else {
                navigate('/organizations')
              }
            }
          }
        )
      }
      setOrganizationName(form.getFieldValue('name'))
    } catch (error) {
    } finally {
      setIsButtonInvalid(false)
    }
  }

  const handleDelete = () => {
    !isMutating &&
      trigger(
        {
          method: 'delete',
          data: null
        },
        {
          onSuccess: () => {
            notification.success({
              key: 'updatable',
              message: t('translation.deleteMessage')
            })
            mutate()
            navigate('/organizations')
          },
          onError: () => {
            navigate('/organizations')
          }
        }
      )
  }

  const handleOpenModalDelete = () => {
    setIsDeleting(true)
  }

  const isZipCodeInvalid = () => {
    const pattern: RegExp = /^\d{7}$/
    if (
      (zipcodeField &&
        (!pattern.test(zipcodeField.trim()) || !zipcodeField.trim())) ||
      !zipcodeField
    ) {
      return true
    }
    return false
  }

  const detectVersionAccess = useCallback((value: number) => {
    switch (value) {
      case 1:
        return { torisetsu: 1, bcp: 0, tour: 0 }
      case 2:
        return { torisetsu: 0, bcp: 2, tour: 0 }
      case 3:
        return { torisetsu: 1, bcp: 2, tour: 0 }
      case 4:
        return { torisetsu: 0, bcp: 0, tour: 4 }
      case 5:
        return { torisetsu: 1, bcp: 0, tour: 4 }
      case 6:
        return { torisetsu: 0, bcp: 2, tour: 4 }
      case 7:
        return { torisetsu: 1, bcp: 2, tour: 4 }
      default:
        return { torisetsu: 0, bcp: 0, tour: 0 }
    }
  }, [])

  useEffect(() => {
    setOrganizationName(organization?.name || '')
  }, [organization])

  useEffect(() => {
    if (organization?.versionAccess) {
      setVersionAcessValue(detectVersionAccess(organization.versionAccess))
    }
  }, [detectVersionAccess, organization])

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        className="bg-white relative"
        colon={false}
        requiredMark={false}
      >
        <Row className="px-5 py-3 theme-bg-primary text-white font-bold">
          <Col span={6}>{t('organization.titleForm')}</Col>
          <Col span={18}>{t('organization.content')}</Col>
        </Row>

        {FormComponent.FORM_REGISTER(t).map((item: any) => {
          if (item.type === 'string' && params.id) {
            return (
              <Row
                key={item.label}
                className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center"
              >
                <Col span={6}>
                  <div className="flex items-center gap-3">
                    {item.label && <div>{item.label}</div>}
                    {item.required && (
                      <div className="flex">
                        <div className="text-[#B80808] text-xs">※</div>
                        <div className="text-[#B80808] text-xs">
                          {t('common.form.required')}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={18}>{organization?.id}</Col>
              </Row>
            )
          }
          if (item.type === 'input') {
            return (
              <Row
                key={item.label}
                className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center"
              >
                <Col span={6}>
                  <div className="flex items-center gap-3">
                    {item.label && <div>{t(`organization.${item.label}`)}</div>}
                    {item.required && (
                      <div className="flex">
                        <div className="text-[#B80808] text-xs">※</div>
                        <div className="text-[#B80808] text-xs">
                          {t('common.form.required')}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={item.label}
                    className="mb-0"
                    rules={[
                      ...item.validates,
                      {
                        transform: (value: string) => value.trim()
                      }
                    ]}
                  >
                    <Input
                      className="w-[300px] h-[46px]"
                      placeholder={item.placeholder}
                    />
                  </Form.Item>
                  {item.label === 'zipcode' && (
                    <Button
                      className="absolute right-5 top-1/2 -translate-y-1/2"
                      sizing="w-[120px] h-[40px]"
                      type="primary"
                      onClick={handleGetCodeAddress}
                      disabled={isZipCodeInvalid()}
                    >
                      {t('organization.buttonZipCode')}
                    </Button>
                  )}
                </Col>
              </Row>
            )
          }
          if (item.type === 'select') {
            return (
              <Row
                key={item.label}
                className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center"
              >
                <Col span={6}>
                  <div className="flex items-center gap-3">
                    {item.label && <div>{t(`organization.${item.label}`)}</div>}
                    {item.required && (
                      <div className="flex">
                        <div className="text-[#B80808] text-xs">※</div>
                        <div className="text-[#B80808] text-xs">
                          {t('common.form.required')}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={item.label}
                    className="mb-0"
                    rules={item.validates}
                  >
                    <Select
                      className="!w-[300px] !h-[46px] ant-select-selector custom-dropdown"
                      placeholder={item.placeholder}
                      suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
                    >
                      {item?.children.map((children: any) => {
                        return (
                          <Select.Option
                            key={children.prefCode}
                            value={children.prefCode}
                          >
                            {children.prefName}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )
          }
          return null
        })}

        {params && params.id && profile?.role === Role.SA && (
          <Row className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center">
            <Col span={6}>{t('organization.managerId')}</Col>
            <Col span={18}>
              <Button
                type="primary"
                sizing="w-[120px] h-10"
                onClick={() => setIsManageBranchAdmin(true)}
              >
                {t('organization.managerId')}
              </Button>
            </Col>
          </Row>
        )}

        {params && !params.id && <FormItemSelectUser />}

        {profile?.role === Role.SA ? (
          <>
            <Row className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center">
              <Col span={6}>{t('translation.versionAsignment')}</Col>
              <Col span={4}>
                <Typography className="text-[#021120] text-base">
                  {t('translation.torisetsu')}
                </Typography>
              </Col>
              <Col span={14}>
                <Radio.Group
                  className="flex gap-5"
                  value={versionAcessValue.torisetsu}
                  onChange={(e) =>
                    setVersionAcessValue((prev) => ({
                      ...prev,
                      torisetsu: e.target.value
                    }))
                  }
                >
                  <Radio value={1} className="flex-row-reverse">
                    <Typography className="text-[#021120] text-sm">
                      {t('translation.contracted')}
                    </Typography>
                  </Radio>
                  <Radio value={0} className="flex-row-reverse">
                    <Typography className="text-[#021120] text-sm">
                      {t('translation.uncontracted')}
                    </Typography>
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center">
              <Col span={6} />
              <Col span={4}>
                <Typography className="text-[#021120] text-base">
                  BCP
                </Typography>
              </Col>
              <Col span={14}>
                <Radio.Group
                  className="flex gap-5"
                  value={versionAcessValue.bcp}
                  onChange={(e) =>
                    setVersionAcessValue((prev) => ({
                      ...prev,
                      bcp: e.target.value
                    }))
                  }
                >
                  <Radio value={2} className="flex-row-reverse">
                    <Typography className="text-[#021120] text-sm">
                      {t('translation.contracted')}
                    </Typography>
                  </Radio>
                  <Radio value={0} className="flex-row-reverse">
                    <Typography className="text-[#021120] text-sm">
                      {t('translation.uncontracted')}
                    </Typography>
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center">
              <Col span={6} />
              <Col span={4}>
                <Typography className="text-[#021120] text-base">
                  {t('translation.tour')}
                </Typography>
              </Col>
              <Col span={14}>
                <Radio.Group
                  className="flex gap-5"
                  value={versionAcessValue.tour}
                  onChange={(e) =>
                    setVersionAcessValue((prev) => ({
                      ...prev,
                      tour: e.target.value
                    }))
                  }
                >
                  <Radio value={4} className="flex-row-reverse">
                    <Typography className="text-[#021120] text-sm">
                      {t('translation.contracted')}
                    </Typography>
                  </Radio>
                  <Radio value={0} className="flex-row-reverse">
                    <Typography className="text-[#021120] text-sm">
                      {t('translation.uncontracted')}
                    </Typography>
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </>
        ) : null}

        <div className="flex justify-between pt-10 pb-5 theme-bg-main-layout">
          {params && params.id && profile?.role === 'SA' ? (
            <Button
              className="bg-[#B80808]"
              sizing="w-[180px] h-[60px]"
              onClick={handleOpenModalDelete}
            >
              {t('organization.buttonDelete')}
            </Button>
          ) : null}
          <div className="flex gap-14 ml-auto">
            <Button onClick={() => rrdNavigate(-1)} sizing="w-[180px] h-[60px]">
              {t('organization.buttonCancel')}
            </Button>

            <Button
              disabled={isButtonInvalid}
              htmlType="submit"
              className="theme-bg-primary"
              sizing="w-[180px] h-[60px]"
            >
              {t('organization.buttonSubmit')}
            </Button>
          </div>
        </div>
      </Form>

      {isManageBranchAdmin && profile?.role === Role.SA && (
        <ModalManageBranchAdmin setIsOpenModal={setIsManageBranchAdmin} />
      )}

      {isDeleting && (
        <ModalDeleteOrganization
          key={organization?.id}
          organizationName={organizationName || ''}
          handleDelete={handleDelete}
          isModalOpen={isDeleting}
          setIsModalOpen={setIsDeleting}
        />
      )}
    </>
  )
}
FormRegister.defaultProps = {
  organization: null
}
