import CheckSSO from 'pages/auth/check-sso'
import ForgotPassword from 'pages/auth/forgot-password'
import Login from 'pages/auth/login'
import ResetPassword from 'pages/auth/reset-password'
import SmzSignin from 'pages/auth/smz-signin'
import { RouteObject } from 'types'

import BgCommonLayout from 'components/templates/BgCommonLayout'

const authRoutes: RouteObject[] = [
  {
    name: 'auth-routes',
    path: '',
    layout: BgCommonLayout,
    guardOnLogged: true,
    children: [
      {
        name: 'LOGIN',
        path: 'signin',
        element: <Login />
      },
      {
        name: 'LOGIN',
        path: 'smz_signin',
        element: <SmzSignin />
      },
      {
        name: 'FORGOT_PASSWORD',
        path: 'forgot_password',
        element: <ForgotPassword />
      },
      {
        name: 'RESET_PASSWORD',
        path: 'reset_password/:token',
        element: <ResetPassword />
      },
      {
        name: 'CHECK_SSO',
        path: 'sso',
        element: <CheckSSO />
      }
    ]
  }
]

export default authRoutes
