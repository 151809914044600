import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Menu, Typography } from 'antd'
import useAuth from 'hooks/useAuth'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import DropdownMenu from 'pages/building/buildingDetail/components/MenuDropdown'
import ModalAddFloor from 'pages/building/buildingDetail/components/MenuLeft/ModalAddFloor'
import AreaMenu from 'pages/building/floor/area/MenuArea/MenuArea'
import { useRecoilValue } from 'recoil'
import { selectedAreaState } from 'store/buildingStore'
import { BuildingLeftMenu } from 'types/building'
import Utils from 'utils'

import Link from 'components/atoms/Link'
import AddIcon from 'components/icons/AddIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import FloorMenu from '../../../floor/components/MenuFloor'

import ExportImages from './ExportImages'
import MenuInformation from './MenuInformation'

type Props = {
  building?: Building
}

export default function MenuLeftDetailBuilding({ building }: Props) {
  const { t } = useTranslation()
  const { profile } = useAuth()
  const { id, floorId } = useParams()
  const [floors, setFloors] = useState(building?.floors)
  const [option, setOption] = useState<BuildingLeftMenu>()
  const [addFloorModal, setAddFloorModal] = useState<boolean>(false)
  const selectedArea = useRecoilValue(selectedAreaState)

  const handleCancelAddFloor = () => setAddFloorModal(false)
  const handleAddFloor = () => setAddFloorModal(true)

  const handleBack = (menu: BuildingLeftMenu) => () => {
    setOption(menu)
  }

  const handleSwitchMenu = (option: BuildingLeftMenu) => {
    setOption(option)
  }
  const { trigger } = useMutation(`v1/buildings/${id}/floor`, {
    onError: (error) => {
      Utils.handleError(error, t)
    }
  })

  const handleOnDragEnd = (result: any) => {
    if (floors) {
      if (!result.destination) return

      const items = Array.from(floors)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
      setFloors(items)
      trigger({
        method: 'patch',
        data: {
          ids: items?.map((item) => item.id)
        }
      })
    }
  }

  const itemsMainMenu = [
    {
      key: 'buildings',
      label: (
        <div className="transition-none flex justify-between">
          <div>
            <Typography.Text className="text-inherit text-white font-[700] text-[16px] my-auto">
              {t('translation.buildingInformation')}
            </Typography.Text>
          </div>
          <div>
            <DropdownMenu
              handleSelectOption={handleSwitchMenu}
              profile={profile}
            />
          </div>
        </div>
      )
    },
    {
      key: 'floor',
      label: (
        <div
          className="transition-none flex justify-between"
          onClick={handleAddFloor}
        >
          <Typography.Text className="text-inherit text-white font-[700] text-[16px] my-auto">
            {t('common.sidebar.floor')}
          </Typography.Text>
          <div className="my-auto pr-[10px]">
            <AddIcon />
          </div>
        </div>
      )
    }
  ]

  useEffect(() => {
    setFloors(building?.floors)
  }, [building])

  useEffect(() => {
    selectedArea
      ? setOption(BuildingLeftMenu.AREA)
      : setOption(floorId ? BuildingLeftMenu.FLOOR : BuildingLeftMenu.MENU)
  }, [floorId, selectedArea])

  return (
    <>
      {option === BuildingLeftMenu.MENU && (
        <>
          <Menu
            theme="dark"
            style={{ width: 240 }}
            inlineIndent={10}
            mode="inline"
            items={itemsMainMenu}
            rootClassName="custom-menu-detail-building"
          />
          {floors && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="floors">
                {(provided: any) => (
                  <div
                    className="cursor-pointer"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {floors.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={String(item.id)}
                        index={index}
                      >
                        {(provided: any) => (
                          <div
                            key={item.id}
                            className="theme-bg-secondary py-[1px] w-[240px] !cursor-pointer"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Link to={`/buildings/${id}/floors/${item.id}`}>
                              <div className="grid grid-cols-[1fr_auto] gap-3 px-[10px] py-[8px] theme-bg-third truncate">
                                <Typography.Text
                                  className="text-white text-[13px] font-[400] truncate"
                                  ellipsis={{
                                    tooltip: item.name
                                  }}
                                >
                                  {item.name}
                                </Typography.Text>
                                <div className="my-auto">
                                  <RightArrowIcon />
                                </div>
                              </div>
                            </Link>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </>
      )}

      {option === BuildingLeftMenu.INFORMATION && (
        <MenuInformation
          handleBack={handleBack(BuildingLeftMenu.MENU)}
          building={building}
          profile={profile}
          handleSelectOption={handleSwitchMenu}
        />
      )}

      {option === BuildingLeftMenu.EXPORT_IMAGES && (
        <ExportImages
          handleBack={handleBack(BuildingLeftMenu.MENU)}
          profile={profile}
          handleSelectOption={handleSwitchMenu}
        />
      )}

      {option === BuildingLeftMenu.FLOOR && (
        <FloorMenu handleBack={handleBack(BuildingLeftMenu.MENU)} />
      )}

      {option === BuildingLeftMenu.AREA && (
        <AreaMenu handleBack={handleBack(BuildingLeftMenu.FLOOR)} />
      )}

      {addFloorModal && <ModalAddFloor handleCancel={handleCancelAddFloor} />}
    </>
  )
}

MenuLeftDetailBuilding.defaultProps = {
  building: undefined
}
