import useDirectory from 'hooks/useDirectory'

type Props = {
  color?: string
  size?: number
  isActive?: boolean
}

export default function RightArrowIcon({ color, size, isActive }: Props) {
  const { getDirectoryThemeColor } = useDirectory()

  return (
    <svg
      width={size ? String(size) : '16'}
      height={size ? String(size) : '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? 'my-auto rotate-90' : 'my-auto'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64689 14.3531C4.60033 14.3067 4.56339 14.2515 4.53818 14.1907C4.51297 14.13 4.5 14.0649 4.5 13.9991C4.5 13.9333 4.51297 13.8682 4.53818 13.8075C4.56339 13.7467 4.60033 13.6916 4.64689 13.6451L10.2939 7.99911L4.64689 2.35311C4.60041 2.30662 4.56353 2.25143 4.53837 2.19069C4.51321 2.12995 4.50026 2.06485 4.50026 1.99911C4.50026 1.93336 4.51321 1.86826 4.53837 1.80752C4.56353 1.74678 4.6004 1.69159 4.64689 1.64511C4.69338 1.59862 4.74857 1.56174 4.80931 1.53658C4.87005 1.51142 4.93515 1.49848 5.00089 1.49848C5.06664 1.49848 5.13174 1.51142 5.19248 1.53658C5.25322 1.56174 5.30841 1.59862 5.35489 1.64511L11.3549 7.64511C11.4015 7.69155 11.4384 7.74673 11.4636 7.80747C11.4888 7.86822 11.5018 7.93334 11.5018 7.99911C11.5018 8.06487 11.4888 8.12999 11.4636 8.19074C11.4384 8.25148 11.4015 8.30666 11.3549 8.35311L5.35489 14.3531C5.30845 14.3997 5.25327 14.4366 5.19253 14.4618C5.13178 14.487 5.06666 14.5 5.00089 14.5C4.93513 14.5 4.87001 14.487 4.80926 14.4618C4.74851 14.4366 4.69334 14.3997 4.64689 14.3531Z"
        fill={color || getDirectoryThemeColor()}
      />
    </svg>
  )
}

RightArrowIcon.defaultProps = {
  color: undefined,
  size: undefined,
  isActive: false
}
