import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Typography } from 'antd'
import clsx from 'clsx'

import Button from 'components/atoms/Button'

const POLICIES = [
  {
    policy:
      '自分のID、パスワードは厳重に管理し、万が一紛失した場合は直ちに\ndt360-mlist@shimz.co.jpに報告を行います。'
  },
  {
    policy:
      '自分のID、パスワードが第三者に知られた場合、またはそのおそれがある場合は、直ちにdt360-mlist@shimz.co.jpに報告を行います。\n※自分のID、パスワードが紛失または第三者に知られたことにより発生した全ての損害について、清水建設株式会社は一切責任を負いません。'
  },
  {
    policy: '自分のID、パスワードを他人に教えません。'
  },
  {
    policy: '他人のID、パスワードによるデジトリ360の使用を行いません。'
  },
  {
    policy:
      '利用期間を終了する場合は速やかにdt360-mlist@shimz.co.jpに報告を行います。'
  },
  {
    policy: '以下に掲げる制限・禁止事項を行いません。\n（制限・禁止事項）',
    childrens: [
      'デジトリ360に関連するドキュメントやプログラムの修正、翻訳、変更、改造、解析',
      '清水建設株式会社の許諾なく、派生サービスを作成し配布する行為',
      '清水建設株式会社、デジトリ360の他のユーザー、または第三者の知的財産権等を侵害する行為',
      '清水建設株式会社、デジトリ360の他のユーザー、または第三者に不利益もしくは損害を与える行為（そのおそれがある行為を含む）。',
      '清水建設株式会社、デジトリ360の他のユーザー、または第三者の財産・信用・名誉等を毀損する行為および、プライバシーに関する権利、肖像権その他の権利を侵害する行為',
      '公序良俗に反する行為',
      '法令に違反する行為や犯罪行為、それらを幇助する行為、またはそのおそれのある行為',
      '清水建設株式会社、デジトリ360の他のユーザー、または第三者のサイトを装ったフィッシング行為',
      '有害プログラムを含んだ情報やデータを登録、提供する行為',
      '無差別に不特定の者に対してその意思に反し電子メール等を送信する行為､または事前に承認していない送信先に対して電子メールを配信する等の行為',
      'デジトリ360が提供する全てのサービスの運営を妨げる行為、またはそのおそれのある行為',
      'デジトリ360が提供する全てのサービスの信用・名誉等を毀損する行為、またはそのおそれのある行為',
      'その他、清水建設株式会社が不適切と判断する行為'
    ]
  },
  {
    policy:
      'デジトリ360で得た清水建設株式会社、およびデジトリ360の他のユーザーの情報（09で規定するユーザー情報、12で規定する入力データを含む）を第三者に開示、伝達、ならびに業務上必要な範囲を超えて複製することを行いません。ただし次にかかげる情報についてはその限りではありません。',
    childrens: [
      '開示された時点で既に公知となっていたもの',
      '開示された後で、自らの帰責事由によらずに公知となったもの',
      '正当な権限を有する第三者から秘密保持義務を負わずに適法に開示されたもの',
      '既に適法に保有していたもの'
    ]
  },
  {
    policy:
      'デジトリ360利用時において、自分の氏名、メールアドレス、会社名（以下「ユーザー情報」という。）がデジトリ360の管理者及びデジトリ360上で管理者に設定されたもの（以下「管理者」という。）に開示され10に掲げる利用目的で利用されること、及び利用に関わるログが取得されることを承諾します。'
  },
  {
    policy:
      '自分が管理者となる場合、開示されるユーザー情報は、善良な管理者の注意をもって管理のうえ、以下の利用目的にのみ利用します。またユーザー情報を保有する本人の書面による承諾を得ることなく、以下の利用目的以外の目的のために利用あるいは複製し、または第三者に利用させ、もしくは開示、漏洩しません。\n（利用目的）',
    childrens: ['デジトリ360のサービス提供及び管理、運用']
  },
  {
    policy: (
      <>
        自分が管理者となる場合、ユーザー情報については上記の他、清水建設株式会社または自社のプライバシーポリシーの定めに準じて取り扱います。
        <br />
        （参考）清水建設株式会社プライバシーポリシー
        <br />
        <a
          href="https://www.shimz.co.jp/privacy/"
          rel="noreferrer"
          target="_blank"
        >
          https://www.shimz.co.jp/privacy/
        </a>
      </>
    )
  },
  {
    policy:
      '自分がデジトリ360に入力または登録等したデータに係る以下の取り扱いにつき承諾します。\n（デジトリ360へ入力または登録等されたデータ（以下「入力データ」という。）の取り扱い）',
    childrens: [
      '清水建設株式会社は、各建物に対するデジトリ360のサービスが終了した場合、当該建物に関する入力データを電子記録媒体に保存し、当該建物の所有者及び管理者に提出します。',
      '清水建設株式会社は、入力データについて、その保管、削除、バックアップ等に関して入力者または第三者に生じた損害につき一切の責任を負いません。',
      '清水建設株式会社は、入力者の承諾を得ることなく、入力データを開示・公開することはありません。ただし、法令に従った要請（捜査関係事項照会書による要請を含む）や法令の手続上必要とされる場合については、入力者の承諾なく、全部または一部の入力データを開示・公開することがあります。',
      '清水建設株式会社は、入力データの漏えい、滅失または毀損の防止その他の入力データの安全管理のため、必要かつ適切な措置を講じます。'
    ]
  },
  {
    policy:
      '管理者が本遵守事項に違反の恐れがあると判断し、デジトリ360の使用中止を指示した場合は、直ちに指示に従います。また、違反が発覚した場合、直ちに書面で通知し、30日以内に対応策を報告します。'
  },
  {
    policy:
      '利用機器の紛失、盗難、ウィルス感染時には直ちにdt360-mlist@shimz.co.jpに報告を行います。'
  },
  {
    policy:
      '本遵守事項に違反した場合、デジトリ360の利用を中止されても異議は述べません。'
  }
]

export default function Policies() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const handleBack = () => {
    if (searchParams.get('source') === 'signup') {
      window.opener = 'self'
      window.close()
      return
    }
    window.history.back()
  }

  return (
    <div className="h-full">
      <div
        className={clsx(
          'absolute top-[103px] bottom-[109px] left-1/2 -translate-x-1/2 w-[817px] px-24 py-[73px] z-10',
          'bg-white rounded-[20px] shadow-[0_0_10px_0_#00000026] overflow-auto',
          'grid grid-rows-[auto_1fr_auto] overflow-auto'
        )}
      >
        <div className="flex justify-center flex-col mb-6">
          <p className="text-[1.25rem] leading-[28.96px] text-center font-bold text-[#3EB1AD] mb-0 h-[30px]">
            {t('user.policiesPageTitle')}
          </p>
          <hr className="w-20 border-[#3EB1AD] border-0 border-b border-solid" />
        </div>
        <div className="overflow-auto text-[#021120] px-3 custom-scrollbar">
          <Typography className="text-[16px] leading-[26px] text-[#021120] whitespace-pre-wrap">
            <ul
              style={{
                display: 'grid',
                gap: '10px',
                listStyleType: 'decimal-leading-zero'
              }}
            >
              {POLICIES.map((item, index) => (
                <li key={`parent no.${index + 1}`}>
                  {item.policy}
                  {item.childrens && (
                    <ul>
                      {item.childrens.map((subItem, sIndex) => (
                        <li key={`children no.${sIndex + 1}`}>{subItem}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Typography>
        </div>
        <Button
          sizing="w-[350px] h-[60px]"
          className="mx-auto mt-[30px] bg-[#3EB1AD]"
          onClick={handleBack}
        >
          {t('user.pledgeButton')}
        </Button>
      </div>
    </div>
  )
}
