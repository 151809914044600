import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight
} from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { Pagination, Typography } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import useDirectory from 'hooks/useDirectory'
import { ListBuilding } from 'models/Building'
import useSWR from 'swr'
import { OrderByType } from 'types'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import NoImageIcon from 'components/icons/NoImageIcon'
import ExtraTable from 'components/organisms/ExtraTable'

import HeaderListBuilding from './HeaderListBuilding'

type Props = {
  isFormSearch?: boolean
  idUser?: string
}

export default function TableBuilding({ isFormSearch, idUser }: Props) {
  const { t } = useTranslation()
  const { profile } = useAuth()
  const defaultField = 'updatedAt'

  const [queries, setQueries] = useSearchParams()
  const page = queries.get('page')
  const { getBuiildingType } = useDirectory()

  const params = useMemo(() => {
    sessionStorage.setItem('buildingParams', queries.toString())
    const convertedQueries = Object.fromEntries(queries.entries())
    return {
      ...convertedQueries,
      page: page || 1,
      pageSize: 10,
      id: idUser === 'me' ? `${profile?.id}` : idUser,
      sort: convertedQueries?.sort || defaultField,
      buildingType: getBuiildingType(),
      sortOrder: convertedQueries?.sortOrder
        ? convertedQueries.sortOrder
        : OrderByType.descend
    }
  }, [getBuiildingType, idUser, page, profile?.id, queries])

  const {
    data: buildings,
    isValidating,
    mutate
  } = useSWR<ListBuilding>({
    url: `/v1/buildings`,
    params
  })

  const hiddenPagination = useMemo(
    () =>
      Boolean(
        buildings?.pagination.total &&
          buildings.pagination.total /
            Const.DEFAULT_PAGINATION.defaultPageSize >
            1
      ),
    [buildings?.pagination.total]
  )

  const listBuilding = buildings?.edges?.map((item) => {
    const fullNameUser = `${item.latestEditedBy?.lastName || ''} ${
      item.latestEditedBy?.firstName || ''
    }`
    const buildingStatus = () => {
      if (item.status === 20 && getBuiildingType() === 10) {
        return 'building.tourStatusPrivate'
      }
      return `building.${item.status}`
    }
    return {
      ...item,
      buildingStatus: item.status ? t(buildingStatus()) : '',
      branchName: item.branch ? (
        <div>
          <Typography>
            {item.branch.name !== 'その他' ? item.branch.name : ''}
          </Typography>
          <Typography>
            {item.branch.serviceStoreName !== 'その他'
              ? item.branch.serviceStoreName
              : ''}
          </Typography>
        </div>
      ) : (
        ''
      ),
      orgName: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph
            className="!mb-0"
            ellipsis={{
              rows: 2,
              tooltip: item.organizationName
            }}
          >
            {item.organizationName}
          </Typography.Paragraph>
        </div>
      ),
      name: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph
            className="!mb-0"
            ellipsis={{
              rows: 2,
              tooltip: item.name
            }}
          >
            {item.name}
          </Typography.Paragraph>
        </div>
      ),
      updatedAt: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph className="!mb-0">
            {dayjs(item.latestUpdated).format('YYYY/MM/DD HH:mm')}
          </Typography.Paragraph>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: fullNameUser
            }}
            className="!mb-0 truncate"
          >
            {fullNameUser}
          </Typography.Paragraph>
        </div>
      ),
      image: item.s3Path ? (
        <LazyThumbnail
          name="thumbnail"
          url={item.thumbnail || item.s3Path}
          className="w-[120px] h-[67.5px] !bg-[#021120]"
          width={120}
          height={67.5}
        />
      ) : (
        <NoImageIcon width={120} height={67.5} />
      ),
      createdAt: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph className="!mb-0">
            {dayjs(item.createdAt).format('YYYY/MM/DD')}
          </Typography.Paragraph>
        </div>
      )
    }
  })

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      if (sorter) {
        queries.set('sort', field)
        queries.set(
          'sortOrder',
          OrderByType[sorter as keyof typeof OrderByType]
        )
      } else {
        queries.delete('sort')
        queries.delete('sortOrder')
      }
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const handleSearch = (value: any) => {
    Object.keys(value).forEach((key) => {
      if (queries.get(key) && !value[key]) {
        queries.delete(key)
      }
      value[key] && queries.set(key, value[key])
    })
    queries.set('page', '1')
    setQueries(queries)
  }

  const onPageChange = useCallback(
    (page: number) => {
      queries.set('page', `${page}`)
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="icon-prev flex items-center justify-center">
          <MdKeyboardDoubleArrowLeft className="theme-text-primary" />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="icon-next flex items-center justify-center">
          <MdKeyboardDoubleArrowRight className="theme-text-primary" />
        </div>
      )
    }
    return originalElement
  }

  return (
    <>
      {isFormSearch && (
        <HeaderListBuilding
          handleSearch={handleSearch}
          getNewBuilding={mutate}
        />
      )}

      <ExtraTable
        isLoading={isValidating && !buildings}
        columns={TableColumn.COLUMN_BUILDING}
        data={listBuilding}
        onChange={onTableChange}
        total={buildings?.pagination.total}
        role={profile?.role}
        refetch={mutate}
      />

      {hiddenPagination ? (
        <div className="flex justify-center">
          <Pagination
            showSizeChanger={false}
            className="my-4 custom-pagination"
            current={+(page || 1)}
            pageSize={10}
            total={buildings?.pagination.total || 0}
            onChange={onPageChange}
            itemRender={itemRender}
          />
        </div>
      ) : null}
    </>
  )
}

TableBuilding.defaultProps = {
  isFormSearch: true,
  idUser: undefined
}
