import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Select, Tooltip, Typography } from 'antd'
import { Floor } from 'models/Building'
import ViewerAreaDropdown from 'pages/building/buildingDetailViewMode/components/ViewerAreaDropdown'
import ViewerFloorMap from 'pages/building/buildingDetailViewMode/components/ViewerFloorMap'
import { useRecoilState } from 'recoil'
import { floorDetailState } from 'store/buildingStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import { FloorDetail, FloorMapElement } from 'types/building'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  floors: Floor[]
  refetch: () => void
}

export default function CommunityFloorDetail({ floors, refetch }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const [floorDetail, setFloorDetail] = useRecoilState(floorDetailState)

  const listFloor = useMemo(
    () =>
      floors?.map((floor) => {
        const isTitle = floor.name.length > 21 ? floor.name : undefined
        return {
          value: floor.id,
          label: (
            <Tooltip placement="right" title={isTitle}>
              <span className="text-[#021120] text-sm">
                {Utils.truncateString(floor.name, 21)}
              </span>
            </Tooltip>
          )
        }
      }) || [],
    [floors]
  )

  const { isValidating: isValidatingFloor, mutate: refetchFloor } =
    useSWR<FloorDetail>(
      {
        url: `v1/tour/floors/${currentViewer?.selectedFloor}`
      },
      {
        onSuccess: (data) => {
          setFloorDetail(data)
        },
        onError: () => {
          setCurrentViewer((prev) => ({ ...prev, selectedFloor: undefined }))
          setFloorDetail(null)
          refetch()
        }
      }
    )

  const { data: mapItems, isValidating: isValidatingPoints } =
    useSWR<FloorMapElement>({
      url: 'v1/tour/points',
      params: {
        buildingId: id,
        floorId: currentViewer?.selectedFloor
      }
    })

  const handleSelectFloor = (value: number) => {
    setCurrentViewer((prev) => ({
      ...prev,
      selectedArea: undefined,
      selectedPoint: undefined,
      selectedFloor: value
    }))
  }

  useEffect(() => {
    if (mapItems && !isValidatingPoints) {
      // prevent select default selected point when navigate
      if (currentViewer.selectedPoint) {
        setCurrentViewer((prev) => ({
          ...prev,
          selectedArea: `${mapItems.points.find(
            (item) => item.id === currentViewer.selectedPoint
          )?.areaId}`
        }))
        return
      }

      const image360Points = mapItems.points.filter((item) => item.image360)

      const defaultPoint =
        mapItems.defaultPoint ||
        (image360Points.length
          ? image360Points.reduce((acc, curr) =>
              curr.order < acc.order ? curr : acc
            )
          : null)

      defaultPoint &&
        setCurrentViewer((prev) => ({
          ...prev,
          selectedArea: `${defaultPoint.areaId}`,
          selectedPoint: defaultPoint.id
        }))
    }
  }, [
    currentViewer.selectedPoint,
    isValidatingPoints,
    mapItems,
    setCurrentViewer
  ])

  return (
    <>
      <div className="flex flex-col gap-2 p-[10px]">
        <Typography className="font-bold text-white">
          {t('common.sidebar.floor')}
        </Typography>
        <Select
          style={{
            width: 'full',
            overflow: 'hidden'
          }}
          options={listFloor}
          value={!isValidatingFloor ? currentViewer?.selectedFloor : undefined}
          onSelect={handleSelectFloor}
          disabled={!listFloor.length}
          className={!listFloor.length ? 'select-floot-disabled' : ''}
          suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
        />
      </div>

      {!isValidatingFloor && !isValidatingPoints ? (
        <>
          <ViewerFloorMap
            imagePath={floorDetail?.mapFile?.s3Path}
            mapItems={mapItems}
            refetchFloor={refetchFloor}
          />

          <div className="h-full overflow-auto">
            <ViewerAreaDropdown
              areas={floorDetail?.areas || []}
              points={mapItems?.points || []}
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}
