import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import useAnnotation from 'hooks/useAnnotation'
import { AnnotationType, BuildingFile } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

import AnnotationSetting from './AnnotationSetting'
import AnnotationTypeSelector from './AnnotationTypeSelector'
import ConfirmChangeAnnotation from './ConfirmChangeAnnotation'
import FileList from './FileList'
import MemoSetting from './MemoSetting'
import ModalDeleteAnnotation from './ModalDeleteAnnotation'

export default function AnnotationModal() {
  const { t } = useTranslation()
  const [isAddingFile, setIsAddingFile] = useState(false)
  const [isChangeType, setIsChangeType] = useState(false)
  const [openModal, setOpenModal] = useState<string>('')
  const [existsFile, setExistsFile] = useState<BuildingFile[]>([])
  const [selectedFiles, setSelectedFiles] = useState<BuildingFile[]>([
    ...existsFile
  ])
  const stopPropagation = useRef<boolean>(false)

  const {
    form,
    memo,
    isDataExistsAnnotation,
    dataSource,
    currentSelected,
    setCurrentSelected,
    childrenFolder,
    selectedFolder,
    setSelectedFolder,
    selectedChildrenFolder,
    setSelectedChildrenFolder,
    handleBack,
    handleCloseModal,
    handleSubmit
  } = useAnnotation()

  const changeSelectedFolder = (value: any) => {
    if (value !== selectedFolder) {
      setCurrentSelected((prev) => ({
        ...prev,
        type: value
      }))
      if (!isDataExistsAnnotation) {
        setIsChangeType(true)
      } else {
        setSelectedFolder(value)
      }
    }
  }

  const handleCloseFileSelectMenu = (clear?: boolean) => {
    if (clear) {
      setSelectedFiles([])
    } else {
      const filesPrev =
        currentSelected?.files?.filter(
          (file) => !existsFile.map((item) => item.id).includes(file.id)
        ) || []
      setCurrentSelected((prev) => ({
        ...prev,
        files: [...filesPrev, ...selectedFiles]
      }))
    }
    setIsAddingFile(false)
  }

  const handleSelectFile = (file: BuildingFile) => {
    const index = selectedFiles.findIndex((item) => item.id === file.id)
    if (index === -1) {
      setSelectedFiles((prev) => [
        ...prev,
        { ...file, category: selectedChildrenFolder?.id }
      ])
      return
    }
    setSelectedFiles((prev) => prev.filter((_, i) => index !== i))
  }

  const onFormFocus = () => {
    stopPropagation.current = true
  }

  const onFormBlur = () => {
    stopPropagation.current = false
  }

  const handleEnterSubmit = () => {
    if (openModal === 'deleted' || isChangeType || stopPropagation.current) {
      return
    }
    if (selectedFolder === AnnotationType.MEMO && !memo) {
      return
    }
    if (isAddingFile) {
      handleCloseFileSelectMenu()
      return
    }
    handleSubmit()
  }

  useEffect(() => {
    if (existsFile.length) {
      setSelectedFiles([...existsFile])
    }
  }, [existsFile])

  return (
    <>
      <Modal
        width="840px"
        rootClassName="shared-permission-modal"
        isEnterable
        onEnter={handleEnterSubmit}
      >
        {isAddingFile ? (
          <FileList
            folder={dataSource}
            selectedChildrenFolder={selectedChildrenFolder}
            selectedFiles={selectedFiles}
            handleSelectFile={handleSelectFile}
            handleCloseFileSelectMenu={handleCloseFileSelectMenu}
          />
        ) : (
          <>
            <div className="flex flex-col w-[45rem]">
              <Title
                className="mb-[10px]"
                height={28.96}
                title={t('building.titleAddAnnotation')}
              />

              <AnnotationTypeSelector
                changeSelectedFolder={changeSelectedFolder}
                selectedChildrenFolder={selectedChildrenFolder}
                selectedFolder={selectedFolder}
                t={t}
              />

              {selectedFolder === AnnotationType.MEMO ? (
                <MemoSetting
                  t={t}
                  form={form}
                  memo={currentSelected?.memo}
                  onFormFocus={onFormFocus}
                  onFormBlur={onFormBlur}
                />
              ) : (
                <AnnotationSetting
                  form={form}
                  childrenFolder={childrenFolder}
                  dataSource={dataSource}
                  handleBack={handleBack}
                  selectedChildrenFolder={selectedChildrenFolder}
                  setExistsFile={setExistsFile}
                  setIsAddingFile={setIsAddingFile}
                  setSelectedChildrenFolder={setSelectedChildrenFolder}
                />
              )}
            </div>

            <div className="flex w-full items-end pt-5 pl-5 pr-[30px] mt-[38px] justify-between border-0 border-t border-solid border-[#EEEFF2] mb-5">
              {currentSelected?.id && (
                <Button
                  onClick={() => setOpenModal('deleted')}
                  sizing="w-[140px] h-[50px]"
                  className="bg-[#B80808]"
                >
                  {t('common.delete')}
                </Button>
              )}

              <div className="flex gap-10 ml-auto">
                <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
                  {t('common.cancel')}
                </Button>
                <Button
                  sizing="w-[140px] h-[50px]"
                  disabled={selectedFolder === AnnotationType.MEMO && !memo}
                  className={clsx('theme-bg-primary', {
                    'hover:!opacity-100 border border-solid border-[#d9d9d9] hover:!border-[#d9d9d9] bg-[#0000000a] text-[#00000040] hover:!text-[#00000040]':
                      selectedFolder === AnnotationType.MEMO && !memo
                  })}
                  onClick={handleSubmit}
                >
                  {t('common.confirm')}
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal>
      {openModal === 'deleted' && (
        <ModalDeleteAnnotation
          setIsOpen={setOpenModal}
          annotationSelected={currentSelected}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isChangeType && (
        <ConfirmChangeAnnotation
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          setIsOpenConfirm={setIsChangeType}
        />
      )}
    </>
  )
}
