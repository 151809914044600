import clsx from 'clsx'

type Props = {
  items: SelectAnno[]
  selectedMenu: number
  handleSelect: (type: number) => () => void
}

type SelectAnno = {
  value: number
  labels: string[]
  length: number
}

export default function AnnotationMenu({
  items,
  selectedMenu,
  handleSelect
}: Props) {
  const optionMenu = (item: SelectAnno, index: number) => {
    return (
      <div
        key={item.value}
        className={clsx(
          'border-0 border-b-2 border-solid border-white flex flex-col items-center justify-center text-sm font-bold cursor-pointer h-10 leading-4',
          {
            'border-b-[#3163B7] theme-text-primary': selectedMenu === index
          }
        )}
        onClick={handleSelect(index)}
      >
        {item?.labels.map((label: string) => {
          return <span key={label}>{label}</span>
        })}
      </div>
    )
  }

  return (
    <div className="grid grid-cols-4 gap-[1px] bg-[#F9FBFF]">
      {items.map((item, index) => optionMenu(item, index))}
    </div>
  )
}
