import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function CopyLinkSvg() {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7553 0.128621C21.4266 0.25796 22.0828 0.486476 22.7032 0.811781C23.3229 1.13632 23.9038 1.56081 24.4211 2.07817C25.1106 2.76691 25.6335 3.57144 25.981 4.42756C26.5 5.71217 26.6301 7.11023 26.3698 8.45278C26.2397 9.12406 26.0143 9.78102 25.6882 10.4007C25.3621 11.0188 24.9392 11.6012 24.4211 12.1185L21.0568 15.4828C20.3792 16.162 19.2771 16.162 18.5995 15.4828C17.9218 14.8036 17.9218 13.7039 18.5995 13.0254L21.9653 9.66118C22.3208 9.30413 22.5834 8.89864 22.7611 8.46304C23.0254 7.81081 23.0928 7.09037 22.9595 6.40401C22.8928 6.06124 22.777 5.7272 22.6119 5.41221C22.4469 5.09797 22.2327 4.80283 21.9653 4.53466C21.6059 4.17761 21.2012 3.91578 20.7664 3.73805C20.1142 3.47384 19.3929 3.4064 18.7074 3.5397C18.3638 3.60637 18.0297 3.72221 17.7163 3.88643C17.4005 4.05305 17.1054 4.26648 16.8372 4.53466L13.4737 7.89891C12.7953 8.5773 11.694 8.5773 11.0164 7.89891C10.3387 7.22053 10.3387 6.12076 11.0164 5.44237L14.3814 2.07811C15.0701 1.38779 15.8746 0.865711 16.7308 0.518977C18.0155 -0.00153065 19.4136 -0.130871 20.7553 0.128621Z"
        fill="#787B82"
      />
      <path
        d="M8.40038 12.9725L5.03531 16.3376C4.67903 16.6955 4.41639 17.1009 4.23947 17.5357C3.97444 18.188 3.90701 18.9092 4.03949 19.5948C4.10693 19.9384 4.22358 20.2716 4.3878 20.5866C4.55365 20.9008 4.76866 21.1959 5.03526 21.4641C5.39393 21.8212 5.79856 22.0838 6.23416 22.2608C6.88558 22.525 7.60683 22.5924 8.294 22.4599C8.63677 22.3933 8.97081 22.2767 9.28504 22.1124C9.59928 21.9458 9.89442 21.7324 10.1634 21.4641L13.5277 18.0991C14.2053 17.4214 15.3058 17.4214 15.9834 18.0991C16.6618 18.7783 16.6618 19.878 15.9834 20.5564L12.62 23.9207C11.9296 24.611 11.1267 25.1331 10.2689 25.4806C8.98589 26.0003 7.58545 26.1297 6.24609 25.8702C5.57481 25.7409 4.91786 25.5132 4.29817 25.1871C3.67849 24.8617 3.09608 24.438 2.57953 23.9207C1.89003 23.2303 1.36632 22.4274 1.02116 21.5712C0.499838 20.2874 0.370499 18.8877 0.62999 17.5468C0.760142 16.8748 0.987845 16.2186 1.31315 15.5997C1.63769 14.98 2.06217 14.3976 2.57953 13.8802L5.9446 10.516C6.62223 9.83837 7.72276 9.83837 8.40033 10.516C9.07882 11.1952 9.07882 12.295 8.40038 12.9725Z"
        fill="#787B82"
      />
      <path
        d="M17.8902 8.6106C18.5678 9.28822 18.5678 10.3888 17.8902 11.0671L11.568 17.3902C10.8896 18.0678 9.7898 18.0678 9.11222 17.3902C8.43302 16.711 8.43302 15.6105 9.11222 14.9329L15.4345 8.6106C16.1129 7.9314 17.2118 7.9314 17.8902 8.6106Z"
        fill="#787B82"
      />
    </svg>
  )
}

export default function CopyLinkIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={CopyLinkSvg} {...props} />
}
