import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Card, Form, Input, notification } from 'antd'
import useMutation from 'hooks/useMutation'
import SmallNotificationList from 'pages/notifications/components/SmallNotificationList'
import { ErrorNetwork } from 'types'
import Validators from 'utils/validators'

import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

import SignInValidation from './SignInValidation'

export default function Login() {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [currentSignIn, setCurrentSignIn] = useState({
    email: '',
    password: ''
  })
  const [isSecondAuthenticating, setIsSecondAuthenticating] = useState(false)

  const { trigger: signIn, isMutating: isSigningIn } =
    useMutation('v1/auth/login')

  const handleLogin = (values: any) => {
    if (!isSigningIn) {
      signIn(
        {
          data: values
        },
        {
          onSuccess: () => {
            setCurrentSignIn({
              email: values.email,
              password: values.password
            })
            setIsSecondAuthenticating(true)
          },
          onError: (error) => {
            if (error?.code === ErrorNetwork) {
              notification.error({
                message: t('translation.networkError'),
                key: ErrorNetwork
              })
            } else {
              notification.error({ message: t('login.loginFailure') })
            }
          }
        }
      )
    }
  }

  const handleBackToLogin = () => {
    setIsSecondAuthenticating(false)
  }

  if (isSecondAuthenticating) {
    return (
      <SignInValidation
        currentSignIn={currentSignIn}
        handleBackToLogin={handleBackToLogin}
      />
    )
  }

  return (
    <div className="w-[90vw] h-full flex justify-center items-center gap-16 mx-auto">
      <div className="w-full max-w-[800px] flex flex-col items-center gap-44">
        <div className="w-full px-5">
          <div
            className="w-full aspect-[758/140]"
            style={{
              backgroundImage: "url('/logo/digitori360_logo_white.png')",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }}
          />
        </div>
        <SmallNotificationList />
      </div>
      <div className="w-[480px] flex-shrink-0 flex flex-col items-center gap-[52px]">
        <Card className="rounded-xl w-full shadow-lg">
          <Title title="login.title" />

          <Spinner spinning={isSigningIn}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleLogin}
              autoComplete="off"
              className="custom-label"
              initialValues={{ remember: true }}
              requiredMark={false}
            >
              <Form.Item
                label={t('login.email')}
                name="email"
                className="mb-[30px]"
                rules={[{ validator: Validators.emailValidator(t) }]}
              >
                <Input
                  autoComplete="new-email"
                  size="large"
                  placeholder={t('login.email')}
                  maxLength={256}
                />
              </Form.Item>

              <Form.Item
                label={t('login.password')}
                name="password"
                className="mb-[30px]"
                rules={[{ validator: Validators.passwordValidator(t) }]}
              >
                <Input.Password
                  autoComplete="new-password"
                  size="middle"
                  placeholder={t('login.passwordPlaceholder')}
                  maxLength={256}
                />
              </Form.Item>

              <Button
                htmlType="submit"
                type="primary"
                block
                size="large"
                className="mb-[30px]"
              >
                {t('login.buttonLogin')}
              </Button>

              <Link
                to="/forgot_password"
                className="text-primary font-semibold flex justify-center underline underline-offset-2"
              >
                {t('login.forgotPassword')}
              </Link>
            </Form>
          </Spinner>
        </Card>

        <Link
          to="/smz_signin"
          className="text-white font-medium underline underline-offset-4"
        >
          {t('login.shimizuSignIn')}
        </Link>
      </div>
    </div>
  )
}
