import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { List, notification } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import useSWR from 'swr'
import { ImageFloor, ListImagesFloor } from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import LazyThumbnail from 'components/atoms/LazyThumbnail'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

import CustomCheckBox from './CustomCheckBox'

type Props = {
  setIsOpen: Dispatch<SetStateAction<string>>
}

export default function ModalDefaultImage({ setIsOpen }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const { navigate } = useDirectory()

  const [selectImage, setSelectImage] = useState('')

  const { data: images, isValidating } = useSWR<ListImagesFloor>(
    {
      url: `v1/floors/${floorId}/images`
    },
    {
      onSuccess: (data) => {
        if (!data.edges.length) return
        const defaultImage = data.edges.find((item) => item.isDefault)
        if (defaultImage) {
          setSelectImage(defaultImage.id)
          return
        }
        setSelectImage(data.edges[0].id)
      }
    }
  )

  const { trigger: editFloorName, isMutating } = useMutation(
    `v1/floors/${floorId}/images`
  )

  const handleClose = () => {
    setIsOpen('')
  }

  const handleSubmit = () => {
    if (!isMutating && selectImage) {
      editFloorName(
        {
          method: 'patch',
          data: { defaultPoint: selectImage },
          successMessage: t('building.successDefaultImage')
        },
        {
          onSuccess: () => {
            handleClose()
          },
          onError: (error) => {
            navigate(`/buildings/${id}`)
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
    }
    if (!selectImage) {
      notification.success({
        key: 'set-default-image',
        message: t('building.successDefaultImage')
      })
      handleClose()
    }
  }

  const handleSelectImage = (item: ImageFloor) => {
    setSelectImage(item.id)
  }

  return (
    <Modal
      className="modal-custom model-custom-default-img"
      onCancel={handleClose}
      width={506}
      rootClassName=""
      isEnterable
      onEnter={handleSubmit}
    >
      <Title
        title={t('building.setDefaultImageFloor')}
        bottomMargin="mb-[31px]"
      />
      {isValidating ? (
        <Spinner>
          <div className="min-h-[470px]" />
        </Spinner>
      ) : (
        <List
          dataSource={images?.edges}
          style={{
            height: 470,
            overflow: 'auto',
            padding: '0 60px'
          }}
          renderItem={(item) => (
            <List.Item
              className="!py-0 mb-[10px] cursor-pointer"
              onClick={() => handleSelectImage(item)}
              style={{ borderBlockEnd: 'none' }}
            >
              <CustomCheckBox
                id={item.id}
                isDefault={item.isDefault}
                checked={selectImage}
              />
              <div className="relative">
                <div className="absolute top-1 left-1 z-10 rounded-lg flex bg-[#021120] p-[2px] min-w-[16px]">
                  <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
                    {item.order}
                  </div>
                </div>
                <LazyThumbnail url={item.thumbnail} name="" />
              </div>
            </List.Item>
          )}
        />
      )}
      <div className="flex gap-10 justify-center mt-10">
        <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
          {t('building.buttonCancel')}
        </Button>

        <Button
          onClick={handleSubmit}
          type="primary"
          sizing="w-[140px] h-[50px]"
          disabled={!selectImage}
        >
          {t('building.buttonSave')}
        </Button>
      </div>
    </Modal>
  )
}
