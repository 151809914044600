import useDirectory from 'hooks/useDirectory'

type Props = {
  isActive: boolean
}
export default function FileIcon({ isActive }: Props) {
  const { getDirectoryThemeColor } = useDirectory()

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8051 6.74541C21.6096 6.5335 21.308 6.45553 21.0341 6.54616L7.19786 11.1156C6.79739 11.2489 6.47286 11.5471 6.30825 11.9356L4.05786 17.24L3.95489 9.80643L17.0398 5.41995L17.0124 3.43444C17.0105 3.2935 16.9411 3.16221 16.8258 3.08061C16.7106 2.99897 16.564 2.97834 16.4304 3.0233L9.15473 5.47491C8.99649 5.52858 8.82157 5.48827 8.70294 5.36995L7.32586 4.00018C7.2079 3.88323 7.03364 3.8419 6.87602 3.89455L2.30192 5.4133C2.11965 5.47362 1.99739 5.64522 2.00004 5.83745L2.2093 20.9755C2.21133 21.1154 2.27993 21.2463 2.39457 21.328C2.50883 21.4093 2.65543 21.4316 2.78907 21.3873L18.0374 16.349C18.1573 16.3087 18.2546 16.2194 18.3043 16.1028L21.9417 7.53034C22.0543 7.26452 22.001 6.9576 21.8051 6.74541Z"
        fill={isActive ? getDirectoryThemeColor() : 'white'}
      />
    </svg>
  )
}
