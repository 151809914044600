import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Typography } from 'antd'
import { Folders } from 'types/building'

import RightArrowIcon from 'components/icons/RightArrowIcon'

import FolderIcon from './FolderIcon'

type Props = { listFolder: Folders[] }

export default function ListChildrenFolder({ listFolder }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const folder = searchParams.get('folder')
  const children = searchParams.get('children')

  const selectedFolder = useMemo(
    () => listFolder.find((item) => `${item.id}` === folder),
    [folder, listFolder]
  )

  const selectedChildrenFolder = useMemo(
    () =>
      selectedFolder?.children?.find(
        (item: any) => `${item.categoryId}` === children
      ),
    [children, selectedFolder?.children]
  )

  const isDisplay = useMemo(
    () =>
      selectedFolder &&
      !selectedFolder?.isFileHolder &&
      !selectedChildrenFolder,
    [selectedChildrenFolder, selectedFolder]
  )

  const hanldeSelectChildrenFolder = (children: Folders) => {
    searchParams.set('children', `${children.categoryId}`)
    setSearchParams(searchParams)
  }

  const handleBack = () => {
    searchParams.delete('folder')
    setSearchParams(searchParams)
  }

  if (!isDisplay) return null

  return (
    <>
      <div />
      <div>
        <div className="px-5 theme-bg-primary">
          <div
            className="flex gap-4 py-1 items-center mr-auto cursor-pointer"
            onClick={handleBack}
          >
            <FolderIcon type={selectedFolder?.id || 0} size="small" />
            <Typography className="text-white font-medium">
              {selectedFolder?.label}
            </Typography>
          </div>
        </div>
        {selectedFolder?.children?.map((item: any) => (
          <div
            key={item.id}
            className="bg-white h-[60px] px-5 flex items-center border-0 border-b border-solid border-[#EEEFF2] cursor-pointer"
            onClick={() => hanldeSelectChildrenFolder(item)}
          >
            <Typography className="font-medium text-[#021120]">
              {item.label}
            </Typography>
            <div className="ml-auto">
              <RightArrowIcon color="#3B3C46" size={24} />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
