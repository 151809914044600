import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { Point } from 'types/building'
import Krpano from 'utils/krpano'

import useCommunityKrpano from '../hooks/useCommunityKrpano'

export default function CommunityPanorama() {
  const { id } = useParams()
  const {
    mutate: refreshPointList,
    currentPov,
    currentViewer,
    setCurrentViewer,
    setCurrentHgap,
    embedpano
  } = useCommunityKrpano()

  // get point detail when select point
  useSWR<Point>(
    currentViewer.selectedFloor && currentViewer.selectedPoint
      ? {
          url: `v1/tour/points/${currentViewer.selectedPoint}`,
          params: {
            building_id: id,
            floor_id: currentViewer?.selectedFloor
          }
        }
      : null,
    {
      onSuccess: (data) => {
        setCurrentViewer((prev) => ({ ...prev, pointDetail: data }))
        if (data.image360) {
          const pov = currentPov || data.initView
          const currentChooseImage = sessionStorage.getItem(data.id)
          !currentChooseImage && sessionStorage.setItem(data.id, 'main')
          const image360 =
            !currentChooseImage || currentChooseImage === 'main'
              ? data.image360
              : data?.subImages?.find(
                  (item) => `${item.id}` === currentChooseImage
                )
          const initProps = {
            imageUrl: image360?.s3Path || '',
            previewUrl: image360?.thumbnail || '',
            hgap: data.initView.radarHGap,
            ...pov
          }
          setCurrentHgap(initProps.hgap)
          embedpano(
            initProps,
            data.annotations,
            data.directions,
            data.stairways
          )
        } else {
          Krpano.UnmountPanoViewer()
        }
      },
      onError: () => {
        refreshPointList()
      }
    }
  )

  return <div id="viewer-pano-container" className="w-full h-screen" />
}
