import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { notification } from 'antd'
import useDirectory from 'hooks/useDirectory'
import Api from 'services/api'
import { storeIdTokens } from 'services/authAxios'
import useSWR from 'swr'

import Spinner from 'components/atoms/Spinner'

export default function CheckSSO() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')
  const { rrdNavigate, navigate } = useDirectory()
  const { t } = useTranslation()

  const { mutate } = useSWR('v1/users/me', Api.get, {
    revalidateOnMount: false
  })
  // call api get auth token
  const { isValidating, error } = useSWR(
    {
      url: 'v1/auth/sso',
      params: {
        code
      }
    },
    {
      onSuccess: async (data) => {
        if (data) {
          storeIdTokens(data)
          await mutate()

          if (data.isFullProfile) {
            navigate('/')
          } else {
            navigate('/users/me')
          }
        }
      }
    }
  )

  useEffect(() => {
    if (error?.response?.data?.error?.key === 'not_registered_user') {
      notification.error({
        message: t('translation.notRegisteredUserTitle'),
        description: (
          <div>
            <p className="text-[#021120]">
              デジトリ360問合せ窓口(
              <a href="mailto:dt360-mlist@shimz.co.jp">
                dt360-mlist@shimz.co.jp
              </a>
              )まで連絡してください。
            </p>
          </div>
        ),
        duration: 10
      })
      rrdNavigate('/smz_signin')
    }
  }, [error, rrdNavigate, t])

  return <Spinner spinning={isValidating} />
}
