import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Input, notification, Radio, Typography } from 'antd'
import TableColumn from 'constants/tableColumn'
import useMutation from 'hooks/useMutation'
import { CurrentUser } from 'models/User'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedPoint,
  selectedPointDetail,
  setAnnotationModeState
} from 'store/buildingStore'
import { displayToggleState } from 'store/displayToggleStore'
import useSWR from 'swr'
import { AnnotationType, SetAnnotation } from 'types/building'
import Krpano from 'utils/krpano'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import ExtraTable from 'components/organisms/ExtraTable'

type Props = {
  annotationPosition: string[]
  setAnnotationPosition: Dispatch<SetStateAction<string[]>>
}

export default function ModalListSetAnnotation({
  annotationPosition,
  setAnnotationPosition
}: Props) {
  const { id, floorId } = useParams()
  const { t } = useTranslation()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchParams, setSearchParams] = useState({
    keyword: ''
  })

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const displayToggle = useRecoilValue(displayToggleState)
  const [{ mode, selectedSetAnnotation, annotationId }, setSelectedMode] =
    useRecoilState(setAnnotationModeState)
  const [, setPointDetail] = useRecoilState(selectedPointDetail)

  const { data: profile } = useSWR<CurrentUser>('v1/users/me', {
    revalidateOnMount: false
  })

  const { trigger: triggerDuplicate, isMutating: isDuplicating } = useMutation(
    `v1/buildings/${id}/set-annotations`
  )

  const { trigger: triggerAddAnnotation, isMutating: isAddingAnnotation } =
    useMutation(`v1/annotations`)

  const { trigger: triggerEditAnnotation, isMutating: isEditAnnotation } =
    useMutation(`v1/annotations`)

  const { data, isValidating, mutate } = useSWR<SetAnnotation[]>({
    url: `v1/buildings/${id}/set-annotations`,
    params: searchParams
  })

  const handleDuplicate = useCallback(
    (setAnnotationId?: number) => {
      if (!isDuplicating) {
        triggerDuplicate(
          {
            data: null,
            query: [setAnnotationId, 'duplicate']
          },
          {
            onSuccess: () => {
              mutate()
            }
          }
        )
      }
    },
    [isDuplicating, mutate, triggerDuplicate]
  )

  const listSetAnnotation = useMemo(
    () =>
      data?.map((item) => ({
        id: item?.id || '',
        name: item.name,
        checkbox: <Radio value={item.id} />,
        actions: (
          <div className="flex justify-center gap-[32px] px-5">
            <Button
              type="primary"
              sizing="w-[100px] h-[31px]"
              onClick={(e) => {
                e.stopPropagation()
                handleDuplicate(item.id)
              }}
            >
              {t('translation.copy')}
            </Button>
            <Button
              type="primary"
              sizing="w-[100px] h-[31px]"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedMode((prev) => ({
                  ...prev,
                  mode: 'addSetAnnotation',
                  selectedSetAnnotation: item.id
                }))
              }}
            >
              {t('translation.edit')}
            </Button>
          </div>
        )
      })) || [],
    [data, handleDuplicate, setSelectedMode, t]
  )

  const setAnnotationColumn = useMemo(
    () =>
      mode === 'placingSetAnnotation' || mode === 'editSetAnnotation'
        ? TableColumn.COLUMN_PLACING_SET_ANNOTATION
        : TableColumn.COLUMN_LIST_SET_ANNOTATION,
    [mode]
  )

  const handleSearch = () => {
    if (!isValidating) {
      setSearchParams((prev) => ({
        ...prev,
        keyword: searchKeyword
      }))
    }
  }

  const handleChangeCheckbox = (value: any) => {
    setSelectedMode((prev) => ({
      ...prev,
      selectedSetAnnotation: value.id
    }))
  }

  const handleCloseModal = () => {
    notification.destroy('placingSetAnnotation')
    notification.destroy('placingAnnotation')
    Krpano.RemoveTempAnnotation()
    setAnnotationPosition([])
    setSelectedMode((prev) => ({
      ...prev,
      annotationId: undefined,
      selectedSetAnnotation: undefined,
      mode: prev.mode !== 'placingSetAnnotation' ? undefined : prev.mode
    }))
  }

  const handleSubmit = () => {
    if (!isAddingAnnotation && selectedSetAnnotation) {
      const krpano = document.getElementById('embedpano-full') as any
      triggerAddAnnotation(
        {
          method: 'put',
          data: {
            annotation: {
              set_annotation_id: selectedSetAnnotation,
              head_h: annotationPosition[0],
              head_v: annotationPosition[1],
              mid_h: annotationPosition[2],
              mid_v: annotationPosition[3],
              h: annotationPosition[4],
              v: annotationPosition[5]
            },
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint
          }
        },
        {
          onSuccess: (data) => {
            setPointDetail(
              (prev) =>
                prev && {
                  ...prev,
                  annotations: [...(prev?.annotations || []), data.data]
                }
            )
            Krpano.AddAnnotation(krpano, [data.data])
            handleCloseModal()
          },
          onError: () => {
            handleCloseModal()
          }
        }
      )
    }
  }

  const hotspotLoadStyle = (krpano: any, data: any) => {
    krpano.call(`remove_annotation(${data.id})`)
    Krpano.AddAnnotation(krpano, [data])

    if (data.type === AnnotationType.MEMO) {
      krpano.call(
        `set(hotspot[id-${data.id}].text,${Krpano.ConvertMemo(
          data.memo || ''
        )});`
      )
    }
  }

  const handleEditAnnotation = () => {
    const krpano = document.getElementById('embedpano-full') as any
    const convertedValue = {
      id: annotationId,
      set_annotation_id: selectedSetAnnotation
    }
    !isEditAnnotation &&
      triggerEditAnnotation(
        {
          method: 'put',
          data: {
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint,
            annotation: convertedValue
          },
          successMessage: t('building.saved')
        },
        {
          onSuccess: ({ data }) => {
            setPointDetail(
              (prev) =>
                prev && {
                  ...prev,
                  annotations: prev?.annotations?.map((item) =>
                    item.id === annotationId && item.set_annotation_id
                      ? data
                      : item
                  )
                }
            )
            hotspotLoadStyle(krpano, data)
            handleCloseModal()
          },
          onError: () => {
            handleCloseModal()
          }
        }
      )
  }

  const modalTitle = () => {
    if (mode === 'placingSetAnnotation') return 'translation.setAnnoPlacing'
    if (mode === 'editSetAnnotation') return 'translation.editSetAnnotation'
    return 'translation.setAnnoSearch'
  }

  if (mode === 'placingSetAnnotation' && annotationPosition.length < 6)
    return null

  return (
    <Modal open width="840px" rootClassName="shared-permission-modal">
      <Title height={28.96} title={t(modalTitle())} />

      <div className="mx-1 flex items-center gap-5 mb-[30px]">
        <Typography className="text-sm font-medium flex-shrink-0">
          {t('user.keyword')}
        </Typography>
        <Input
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="h-[38px]"
        />
        <Button type="primary" className="flex-shrink-0" onClick={handleSearch}>
          {t('user.buttonSearch')}
        </Button>
      </div>

      <Radio.Group
        className="w-full table-list-org-invite"
        value={selectedSetAnnotation}
      >
        <ExtraTable
          isDetail={false}
          scroll={{ y: 350 }}
          bordered={false}
          isLoading={isValidating}
          columns={setAnnotationColumn}
          data={listSetAnnotation}
          actionSelect={handleChangeCheckbox}
        />
      </Radio.Group>

      <div className="flex justify-center gap-10 mt-[30px]">
        {mode === 'placingSetAnnotation' && (
          <>
            <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
              {t('common.cancel')}
            </Button>
            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              disabled={
                !selectedSetAnnotation ||
                !displayToggle.isLocked ||
                displayToggle.lockedBy !== profile?.id
              }
              loading={isAddingAnnotation}
              onClick={handleSubmit}
            >
              {t('building.reflectAnnotation')}
            </Button>
          </>
        )}
        {mode === 'listSetAnnotation' && (
          <Button
            type="primary"
            sizing="w-[140px] h-[50px]"
            onClick={handleCloseModal}
          >
            {t('user.close')}
          </Button>
        )}
        {mode === 'editSetAnnotation' && (
          <>
            <Button
              sizing="w-[140px] h-[50px]"
              className="bg-[#B80808]"
              onClick={() =>
                setSelectedMode((prev) => ({
                  ...prev,
                  mode: 'deleteSetAnnotation'
                }))
              }
            >
              {t('common.titleDeleteAnnotation')}
            </Button>

            <Button
              sizing="w-[140px] h-[50px]"
              className="ml-auto"
              onClick={handleCloseModal}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              loading={isEditAnnotation}
              onClick={handleEditAnnotation}
            >
              {t('common.confirm')}
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}
