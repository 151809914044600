import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function EditPencilSvg() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22585_351220)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.631 0.115959C10.6716 0.075216 10.7199 0.042891 10.7731 0.0208354C10.8262 -0.00122029 10.8832 -0.0125732 10.9407 -0.0125732C10.9983 -0.0125732 11.0553 -0.00122029 11.1084 0.0208354C11.1616 0.042891 11.2099 0.075216 11.2505 0.115959L13.8755 2.74096C13.9162 2.7816 13.9486 2.82988 13.9706 2.88303C13.9927 2.93618 14.004 2.99316 14.004 3.05071C14.004 3.10826 13.9927 3.16524 13.9706 3.21839C13.9486 3.27154 13.9162 3.31982 13.8755 3.36046L5.1255 12.1105C5.08351 12.1522 5.0335 12.1849 4.9785 12.2067L0.603498 13.9567C0.523992 13.9885 0.436895 13.9963 0.353003 13.9791C0.269111 13.9619 0.192115 13.9205 0.131559 13.8599C0.0710032 13.7993 0.0295514 13.7223 0.0123424 13.6385C-0.00486662 13.5546 0.00292395 13.4675 0.0347483 13.388L1.78475 9.01296C1.80656 8.95795 1.8393 8.90795 1.881 8.86596L10.631 0.115959ZM9.80937 2.17571L11.8157 4.18208L12.9471 3.05071L10.9407 1.04433L9.80937 2.17571ZM11.1971 4.80071L9.19075 2.79433L3.50325 8.48183V8.73821H3.94075C4.05678 8.73821 4.16806 8.7843 4.25011 8.86635C4.33215 8.9484 4.37825 9.05968 4.37825 9.17571V9.61321H4.81575C4.93178 9.61321 5.04306 9.6593 5.12511 9.74135C5.20715 9.8234 5.25325 9.93468 5.25325 10.0507V10.4882H5.50962L11.1971 4.80071ZM2.65625 9.32883L2.5635 9.42158L1.2265 12.765L4.56987 11.428L4.66262 11.3352C4.57917 11.304 4.50722 11.2481 4.4564 11.1749C4.40558 11.1018 4.37831 11.0148 4.37825 10.9257V10.4882H3.94075C3.82472 10.4882 3.71344 10.4421 3.63139 10.3601C3.54934 10.278 3.50325 10.1667 3.50325 10.0507V9.61321H3.06575C2.97666 9.61314 2.88971 9.58588 2.81653 9.53506C2.74335 9.48424 2.68743 9.41229 2.65625 9.32883Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22585_351220">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function EditPencilIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={EditPencilSvg} {...props} />
}
