import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import useSWR from 'swr'
import { SelectOrganization } from 'types/listUser'
import Utils from 'utils'

import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

import FormAddBuilding from './FormAddBuilding'

type Props = {
  handleCancel: () => void
  getNewBuilding: () => void
}

export default function ModalAddBuilding({
  handleCancel,
  getNewBuilding
}: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const stopPropagation = useRef<boolean>(false)
  const organizationId = useRef<SelectOrganization | undefined>(undefined)

  const { getBuiildingType } = useDirectory()

  const { mutate: getNewListOrganization } = useSWR(
    {
      url: `/v1/organizations-have-building`
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger: addBuilding, isMutating: isAddBuilding } = useMutation(
    'v1/buildings',
    {
      onError: (error) => {
        Utils.handleError(error, t, form)
      },
      onSuccess: () => {
        handleCancel()
        getNewBuilding()
        getNewListOrganization()
      }
    }
  )

  const handleOk = (value: any) => {
    !isAddBuilding &&
      addBuilding({
        data: value,
        successMessage: t('building.saved')
      })
  }

  const onFinish = (value: any) => {
    if (!organizationId.current?.id) {
      form.setFields([
        {
          name: 'organizationId',
          errors: [t(`building.validateOrganizationId`)]
        }
      ])
    } else {
      const data = {
        ...value,
        buildingType: getBuiildingType(),
        organizationId: organizationId.current?.id
      }
      handleOk(data)
    }
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    form
      .validateFields()
      .then(() => onFinish(form.getFieldsValue()))
      .catch(() => {})
  }

  return (
    <Modal
      onCancel={handleCancel}
      className="modal-add-building"
      bodyStyle={{ width: '100%' }}
      rootClassName=""
      isEnterable
      onEnter={handleEnterSubmit}
      zIndex={999}
    >
      <Title title="building.titleAdd" />
      <FormAddBuilding
        handleCancel={handleCancel}
        handleOk={onFinish}
        form={form}
        organizationId={organizationId}
        onFormFocus={() => {
          stopPropagation.current = true
        }}
        onFormBlur={() => {
          stopPropagation.current = false
        }}
      />
    </Modal>
  )
}
