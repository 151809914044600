import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function TraditionSvg() {
  return (
    <svg
      width="10"
      height="26"
      viewBox="0 0 10 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34_2)">
        <path
          d="M5.68238 0.134399C4.8167 0.429532 3.94183 1.36859 3.69318 2.27187C3.5366 2.84425 3.6287 3.81013 3.89576 4.37358C4.19967 4.99961 4.80747 5.58988 5.45212 5.88502C5.88496 6.08178 6.08757 6.11755 6.8335 6.11755C7.65312 6.11755 7.74521 6.09967 8.28857 5.81345C9.37525 5.24107 9.90018 4.41828 9.94621 3.21094C9.99228 2.1735 9.76202 1.56534 9.05292 0.8767C8.38067 0.223833 7.75444 -0.00869609 6.75983 0.000247303C6.37305 0.000247303 5.88496 0.0628512 5.68238 0.134399Z"
          fill="#4B4B4B"
        />
        <path
          d="M4.62303 9.23881C2.21942 10.0527 0 12.1902 0 13.6748C0 14.0504 0.248648 14.6853 0.543342 15.0073C0.552552 15.0341 0.91171 14.7301 1.33534 14.3366C2.53253 13.2097 3.35216 12.6552 3.45345 12.9056C3.59158 13.2544 3.14034 14.9268 1.9063 18.5936C1.09589 20.9994 0.948548 21.6344 0.874874 22.9401C0.79199 24.541 1.1051 25.328 1.9984 25.7483C3.43503 26.4191 5.72813 25.4353 8.77636 22.8417L9.69729 22.0547L8.8961 21.2677L8.0949 20.4807L7.44103 21.0709C6.67668 21.7506 5.79258 22.3856 5.33213 22.5734C5.00981 22.7076 5.00058 22.7076 4.94536 22.4482C4.78877 21.7417 5.34132 19.7205 6.78719 15.687C7.83703 12.7715 7.96597 12.2885 7.97516 11.26C7.98439 10.3478 7.83703 9.92746 7.36736 9.49818C6.79639 8.97947 5.67287 8.87214 4.62303 9.23881Z"
          fill="#4B4B4B"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_2">
          <rect width="10" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function TraditionIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={TraditionSvg} {...props} />
}
