import DetailBuilding from 'pages/building/buildingDetail'
import ViewerDetailBuilding from 'pages/building/buildingDetailViewMode'
import BuildingFileList from 'pages/building/buildingFileList'
import DetailFloor from 'pages/building/floor'
import Building from 'pages/building/listBuilding'
import ContactUs from 'pages/contact-us'
import NotificationPage from 'pages/notifications'
import OrganizationList from 'pages/organization/listOrganization'
import DetailOrganization from 'pages/organization/organizationDetail'
import RegisterOrganization from 'pages/organization/organizationRegister'
import EmailExpired from 'pages/user/invitation-expired'
import UserList from 'pages/user/listUser'
import UserRegister from 'pages/user/register'
import DetailUser from 'pages/user/userDetail'
import VersionNotFoundPage from 'pages/version-notfound'
import { RouteObject } from 'types'

import DefaultLayout from 'components/templates/DefaultLayout'
import DetailBuildingLayout from 'components/templates/DetailBuildingLayout'
import EmptyLayout from 'components/templates/EmptyLayout'
import MainLayout from 'components/templates/MainLayout'

const bcpRoutes: RouteObject[] = [
  {
    name: 'bcp-routes',
    path: '',
    layout: MainLayout,
    children: [
      {
        name: 'userList',
        path: 'bcp/users',
        element: <UserList />,
        role: ['SA', 'BA', 'UA']
      },
      {
        name: 'buildings',
        path: 'bcp/buildings',
        element: <Building />,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'buildings',
        path: 'bcp/buildings/:id',
        element: <DetailBuilding />,
        layout: DetailBuildingLayout,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'floors',
        path: 'bcp/buildings/:id/floors/:floorId',
        element: <DetailFloor />,
        layout: DetailBuildingLayout,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'organizations',
        path: 'bcp/organizations',
        element: <OrganizationList />,
        role: ['SA', 'BA']
      },
      {
        name: 'organizations',
        path: 'bcp/organizations/register',
        element: <RegisterOrganization />,
        role: ['SA']
      },
      {
        name: 'organizations',
        path: 'bcp/organizations/:id',
        element: <DetailOrganization />,
        role: ['SA', 'BA', 'UA']
      },
      {
        name: 'users',
        path: 'bcp/users/:id',
        element: <DetailUser />,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'contactUs',
        path: 'bcp/contact-us',
        layout: DefaultLayout,
        element: <ContactUs />,
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  },
  {
    name: 'viewer-routes',
    path: '',
    layout: MainLayout,
    children: [
      {
        name: 'buildings',
        path: 'bcp/viewer/buildings/:id',
        layout: EmptyLayout,
        element: <ViewerDetailBuilding />,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'buildings',
        path: 'bcp/viewer/buildings/:id/files',
        layout: EmptyLayout,
        element: <BuildingFileList />,
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  },
  {
    name: 'register-routes',
    path: '',
    layout: MainLayout,
    guardOnLogged: true,
    children: [
      {
        name: 'users',
        path: 'bcp/users/register/:id/:token',
        element: <UserRegister />
      }
    ]
  },
  {
    name: 'notifications',
    path: '',
    layout: MainLayout,
    children: [
      {
        name: 'notification-list',
        path: 'bcp/notifications',
        element: <NotificationPage />,
        role: ['SA']
      }
    ]
  },
  {
    name: 'invitation-expired-routes',
    path: '',
    guardOnLogged: true,
    children: [
      {
        name: 'users',
        path: 'bcp/invitation-expired',
        element: <EmailExpired />
      }
    ]
  },
  {
    name: 'version-notfound-page',
    path: 'bcp/version-notfound',
    children: [
      {
        name: 'notfound',
        path: '',
        layout: EmptyLayout,
        element: <VersionNotFoundPage />
      }
    ]
  }
]

export default bcpRoutes
