import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { BuildingFile } from 'types/building'

type Props = {
  file: BuildingFile
}

export default function ViewerImage({ file }: Props) {
  return (
    <TransformWrapper>
      <TransformComponent
        wrapperClass="!w-full !h-full"
        contentClass="!w-full !h-full"
      >
        <img
          src={file.s3Path}
          alt={file.s3Path}
          loading="lazy"
          className="w-full max-w-[90vw] sm:max-w-[80vw] aspect-video m-auto object-contain"
        />
      </TransformComponent>
    </TransformWrapper>
  )
}
