import { Typography } from 'antd'
import { AnnotationType } from 'types/building'

import EarthquakeIcon from 'components/icons/EarthquakeIcon'
import FireIcon from 'components/icons/FireIcon'
import FloodIcon from 'components/icons/FloodIcon'
import TraditionIcon from 'components/icons/TraditionIcon'
import VolcanoIcon from 'components/icons/VolcanoIcon'

type Props = {
  type: AnnotationType | undefined
  label: string
}

export default function BCPAnnotationHeader({ type, label }: Props) {
  const annotationIcon = () => {
    switch (type) {
      case AnnotationType.FIRE:
        return <FireIcon />
      case AnnotationType.FLOOD:
        return <FloodIcon />
      case AnnotationType.EARTHQUAKE:
        return <EarthquakeIcon />
      case AnnotationType.VOLCANO:
        return <VolcanoIcon />
      default:
        return <TraditionIcon />
    }
  }

  const annotationText = () => {
    switch (type) {
      case AnnotationType.FIRE:
        return '火災'
      case AnnotationType.FLOOD:
        return '水害'
      case AnnotationType.EARTHQUAKE:
        return '地震'
      case AnnotationType.VOLCANO:
        return '降灰'
      default:
        return label
    }
  }

  return (
    <div className="flex gap-[10px] ml-5 items-center">
      <div className="grid place-items-center w-9 h-9">{annotationIcon()}</div>
      <Typography className="text-xl font-bold">{annotationText()}</Typography>
    </div>
  )
}
