import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function EarthquakeSvg() {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1279 9.00552L25.7432 10.5396L27.0318 9.17965L17.3677 0L8.66461 9.17965L10.097 10.5396L11.551 9.00637L11.8744 15.1434H10.7706L8.97709 17.9624L10.7706 18.7313L7.18318 24.625L8.46434 19.756L6.49031 19.092L8.20824 15.1435H0V25.5965H28.8618V15.1434H24.4514L24.1279 9.00552ZM16.5988 15.1434L16.4572 12.4605H19.5855L19.7271 15.1434H16.5988Z"
        fill="#EDE054"
      />
      <path
        d="M8.01746 12.0174L8.56274 11.254C8.13571 10.9479 7.87449 10.6322 7.71369 10.3202C7.55374 10.0081 7.49097 9.69372 7.49052 9.37759C7.4859 8.67606 7.82818 7.96121 8.10221 7.55565L7.3214 7.03509V7.03463C6.98505 7.54374 6.55659 8.38642 6.55203 9.37753C6.55158 9.82063 6.64414 10.2931 6.87916 10.749C7.11292 11.2055 7.48641 11.6385 8.01746 12.0174Z"
        fill="#EDE054"
      />
      <path
        d="M27.973 8.13677C28.1545 8.45108 28.2246 8.79159 28.2251 9.13751C28.2269 9.65808 28.0556 10.1823 27.9016 10.5104L28.7529 10.9063C28.9472 10.4847 29.1617 9.85419 29.1635 9.13751C29.1635 8.66463 29.0646 8.14959 28.7855 7.66708C28.5078 7.18411 28.0528 6.74785 27.4012 6.42072L26.9787 7.2593C27.4933 7.51955 27.7902 7.82246 27.973 8.13677Z"
        fill="#EDE054"
      />
    </svg>
  )
}

export default function EarthquakeIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={EarthquakeSvg} {...props} />
}
