import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight
} from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Pagination, Table } from 'antd'
import Const from 'constants/constant'
import useDirectory from 'hooks/useDirectory'
import { DataSource, ExtraTableProps } from 'types'
import { BuildingStatus } from 'types/building'

import ModalRestoreOrDelete from 'components/atoms/ModalRestoreOrDelete'

type SelectedRecord = {
  id: number | string
  name: string
}

const defaultData: any[] = []

export default function ExtraTable<T extends { id: number | string }>(
  props: ExtraTableProps<T>
) {
  const { t } = useTranslation()
  const { rrdNavigate, navigate, getDirectoryBasename } = useDirectory()
  const location = useLocation()

  const [selectedRecord, setSelectedRecord] = useState<SelectedRecord>()

  const {
    bordered = true,
    className,
    total,
    data = defaultData,
    columns,
    isLoading = false,
    pagination,
    onChange,
    components,
    rowKey,
    rowClassName,
    scroll,
    isDetail = true,
    actionSelect,
    size = 'large',
    refetch
  } = props

  const dataSource: DataSource<T>[] = useMemo(
    () =>
      data.map((item, idx) => ({
        ...item,
        key: item.id,
        order: idx + 1
      })),
    [data]
  )

  const onPageChange = (page: number) => {
    if (pagination?.setQueries) {
      pagination.setQueries({ ...pagination.queries, page })
    }
  }

  const columnsList: any = columns.map(
    ({
      name,
      label = '',
      width,
      customLabel,
      onCell,
      align = 'left',
      hasSorter
    }) => {
      return {
        title: <div className="title-table">{customLabel || t(label)}</div>,
        dataIndex: name,
        sorter: hasSorter,
        align,
        width,
        onCell
      }
    }
  )

  const rowClassNameCalculator = (record: any, index: number) => {
    if (rowClassName) return rowClassName(record, index)
    if (record?.deletedAt)
      return 'cursor-pointer bg-[#a6aaaf] ant-table-row-deleted'
    if (record?.status === BuildingStatus.IMAGE) return 'cursor-not-allowed'
    return 'cursor-pointer ant-table-row-normal'
  }

  const onChangeTable = (_: any, __: any, sorter: any) => {
    if (onChange) {
      let sortOrder = ''
      if (sorter.order === 'ascend') sortOrder = 'descend'
      if (sorter.order === 'descend') sortOrder = 'ascend'
      onChange(sortOrder, sorter.field)
    }
  }

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="icon-prev flex items-center justify-center">
          <MdKeyboardDoubleArrowLeft className="theme-text-primary" />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="icon-next flex items-center justify-center">
          <MdKeyboardDoubleArrowRight className="theme-text-primary" />
        </div>
      )
    }
    return originalElement
  }

  const hiddenPagination =
    total && total / Const.DEFAULT_PAGINATION.defaultPageSize > 1

  const handleActionRouterClickRow = (record: any) => {
    if (isDetail) {
      // if is temporary deleted then SA can open modal restore or delete
      if (record.deletedAt) {
        setSelectedRecord({ id: record.id, name: record.name })
        return
      }
      // table building in tab users
      if (
        record?.name &&
        record?.organizationName &&
        location.pathname.startsWith(`${getDirectoryBasename()}/users/`)
      ) {
        record?.status !== BuildingStatus.IMAGE &&
          navigate(`/buildings/${record.id}`)
        return
      }
      // table building
      if (
        location.pathname.startsWith(`${getDirectoryBasename()}/buildings`) &&
        record?.status === BuildingStatus.IMAGE
      ) {
        return
      }
      rrdNavigate(`${record.id}`)
    }
  }

  const handleRefetch = () => {
    refetch && refetch()
  }

  return (
    <>
      <Table
        size={size}
        className={className || ''}
        scroll={scroll}
        bordered={bordered}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined className="!text-3xl" />
        }}
        pagination={false}
        columns={columnsList}
        dataSource={dataSource}
        showSorterTooltip={false}
        rowClassName={rowClassNameCalculator}
        components={components}
        onChange={onChangeTable}
        rowKey={rowKey}
        locale={{
          emptyText: isLoading ? <div className="h-[4.5rem] my-8" /> : undefined
        }}
        onRow={(record: any) => {
          if (!isDetail && actionSelect) {
            return {
              onClick: () => actionSelect(record)
            }
          }
          return {
            onClick: () => handleActionRouterClickRow(record)
          }
        }}
      />
      {pagination && hiddenPagination ? (
        <div className="flex justify-center">
          <Pagination
            showSizeChanger={false}
            className="my-4 custom-pagination"
            current={pagination.page}
            pageSize={pagination.pageSize}
            total={total}
            onChange={onPageChange}
            itemRender={itemRender}
          />
        </div>
      ) : null}

      {selectedRecord && (
        <ModalRestoreOrDelete
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          refetchList={handleRefetch}
        />
      )}
    </>
  )
}
