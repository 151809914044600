import React, { Dispatch, SetStateAction } from 'react'
import { FormInstance, Typography } from 'antd'
import clsx from 'clsx'
import { useRecoilState } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import { BuildingFile } from 'types/building'

import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import FolderList from './FolderList'
import LinkSetting from './LinkSetting'

type Props = {
  form: FormInstance
  selectedChildrenFolder: any
  handleBack: () => void
  dataSource: any
  childrenFolder: any
  setSelectedChildrenFolder: any
  setIsAddingFile: Dispatch<SetStateAction<boolean>>
  setExistsFile: Dispatch<SetStateAction<BuildingFile[]>>
}

export default function AnnotationSetting({
  form,
  selectedChildrenFolder,
  handleBack,
  dataSource,
  setSelectedChildrenFolder,
  childrenFolder,
  setIsAddingFile,
  setExistsFile
}: Props) {
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)

  return (
    <div className="grow">
      <div
        className={clsx('flex h-[50px] theme-bg-primary items-center pl-6', {
          'cursor-pointer gap-5': selectedChildrenFolder
        })}
        onClick={handleBack}
      >
        {selectedChildrenFolder && <LeftArrowIcon size={20} />}
        <Typography className="font-bold leading-[23.17px] text-white">
          {selectedChildrenFolder
            ? selectedChildrenFolder.label
            : dataSource?.label}
        </Typography>
      </div>
      {selectedChildrenFolder?.id === 40 ? (
        <LinkSetting
          form={form}
          setSelectedAnnotation={setCurrentSelected}
          selectedAnnotation={currentSelected}
        />
      ) : (
        <FolderList
          currentSelected={currentSelected}
          selectedChildrenFolder={selectedChildrenFolder}
          setSelectedChildrenFolder={setSelectedChildrenFolder}
          childrenFolder={childrenFolder}
          setIsAddingFile={setIsAddingFile}
          setExistsFile={setExistsFile}
        />
      )}
    </div>
  )
}
