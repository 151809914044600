import { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import { Card } from 'antd'

export default function AuthFormContainer({ children }: PropsWithChildren) {
  const { pathname } = useLocation()

  return (
    <div className="flex h-full">
      {pathname.startsWith('/reset_password') && (
        <div className="absolute top-3 left-1/2 -translate-x-[20%]">
          <img
            src="/assets/icon_background.png"
            alt="404 layer bg"
            className="w-[32.353rem] h-[32.292rem]"
          />
        </div>
      )}

      <div className="mx-auto flex justify-center items-center h-full ">
        <Card className="rounded-xl shadow-lg w-[480px]">{children}</Card>
      </div>
    </div>
  )
}
