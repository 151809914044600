import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import useDirectory from 'hooks/useDirectory'

function UploadSvg(color: string) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6828 4.62932C11.6884 4.55811 11.6921 4.48636 11.6921 4.41371C11.6921 2.90344 10.4677 1.67912 8.95747 1.67912C8.49205 1.67912 8.05398 1.79572 7.67034 2.00079C7.25647 0.835049 6.14429 0 4.83664 0C3.17608 0 1.82992 1.34616 1.82992 3.00671C1.82992 3.31887 1.87753 3.6199 1.96582 3.90298C0.814951 4.35602 0 5.47667 0 6.7884C0 8.50085 1.38821 9.88907 3.10067 9.88907H11.1026C11.1852 9.89683 11.2688 9.90123 11.3535 9.90123C12.8151 9.90123 14 8.71635 14 7.25469C14 5.90468 12.9889 4.79157 11.6828 4.62932ZM8.52328 6.05059H7.96404C7.79878 6.05059 7.66356 6.1858 7.66356 6.35109V7.93905C7.66356 8.10432 7.52832 8.23956 7.36308 8.23956H6.63689C6.47162 8.23956 6.33641 8.10432 6.33641 7.93905V6.35109C6.33641 6.18583 6.2012 6.05059 6.03593 6.05059H5.4767C5.31143 6.05059 5.25795 5.94285 5.35783 5.81119L6.81837 3.88606C6.91823 3.75437 7.08172 3.75437 7.1816 3.88606L8.64214 5.81119C8.74203 5.94288 8.68854 6.05059 8.52328 6.05059Z"
        fill={color}
      />
    </svg>
  )
}

export default function UploadIcon(props: Partial<CustomIconComponentProps>) {
  const { getDirectoryThemeColor } = useDirectory()
  return (
    <Icon component={() => UploadSvg(getDirectoryThemeColor())} {...props} />
  )
}
