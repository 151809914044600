import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification, Select, Tooltip, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import CustomCheckBox from 'pages/building/floor/components/CustomCheckBox'
import { useRecoilValue } from 'recoil'
import { buildingModeState, selectedPoint } from 'store/buildingStore'
import useSWR from 'swr'
import { FloorMapElement } from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import LazyThumbnail from 'components/atoms/LazyThumbnail'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

export default function ModalAddStairway() {
  const { id, floorId } = useParams()
  const { t } = useTranslation()
  const { getDirectoryBasename, getBuiildingType } = useDirectory()

  const [isAddingStairway, setIsAddingStairway] = useState(false)
  const [newStair, setNewStair] = useState<{
    id?: string
    h?: number
    v?: number
    nextFloorId?: number
    nextPointId?: string
    nextPointOrder?: number
  }>()

  const buildingMode = useRecoilValue(buildingModeState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)

  const { data: building, isValidating: isValidatingFloor } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${id}&building_type=${getBuiildingType()}`
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger: addEditStairway, isMutating } = useMutation(
    `v1/points/${currentSelectedPoint}/stairways`
  )

  const { data: mapItems, isValidating: isValidatingPoints } =
    useSWR<FloorMapElement>(
      newStair?.nextFloorId
        ? {
            url: 'v1/points',
            params: {
              buildingId: id,
              floorId: newStair?.nextFloorId
            }
          }
        : null
    )

  const listFloors = useMemo(
    () =>
      building?.floors
        .filter((item) => item.id !== Number(floorId))
        .map((floor) => ({
          value: floor.id,
          label: (
            <Tooltip
              placement="right"
              title={floor.name.length > 21 ? floor.name : undefined}
            >
              <span className="text-[#021120] text-sm">
                {Utils.truncateString(floor.name, 21)}
              </span>
            </Tooltip>
          )
        })) || [],
    [building?.floors, floorId]
  )

  const listPoints = useMemo(
    () =>
      mapItems?.points
        .filter((item) => item.image360?.thumbnail)
        .sort((a, b) => a.order - b.order) || [],
    [mapItems?.points]
  )

  const handleSelectFloor = (value: number) => {
    if (value !== newStair?.nextFloorId) {
      setNewStair((prev) => ({
        ...prev,
        nextFloorId: value,
        nextPointId: newStair?.id ? prev?.nextPointId : ''
      }))
    }
  }

  const handleCancel = () => {
    setNewStair(undefined)
    setIsAddingStairway(false)
  }

  // handle event of krpano
  const handleAddStairway = () => {
    ;(window as any).get_stair_position = () => {
      if (buildingMode.selectedMode === 'stairway') {
        const krpano = document.getElementById('embedpano-full') as any
        const h = krpano.get('mouseath')
        const v = krpano.get('mouseatv')
        setNewStair((prev) => ({
          ...prev,
          nextFloorId: listFloors.length ? listFloors[0].value : undefined,
          h: Number(h),
          v: Number(v)
        }))
        setIsAddingStairway(true)
      }
    }
  }

  // handle click stairway
  const handleSelectStair = () => {
    ;(window as any).handle_click_stair = (
      stair_id: string,
      h: string,
      v: string,
      targetFloor: string,
      targetPoint: string
    ) => {
      if (buildingMode.selectedMode === 'direction') return

      if (buildingMode.selectedMode === 'stairway') {
        setNewStair({
          id: stair_id,
          h: Number(h),
          v: Number(v),
          nextFloorId: Number(targetFloor),
          nextPointId: targetPoint
        })
        setIsAddingStairway(true)
        return
      }

      if (!listFloors.find((item) => item.value === Number(targetFloor))) {
        !isMutating &&
          addEditStairway({
            method: 'delete',
            query: stair_id ? [stair_id] : undefined,
            data: null,
            params: {
              buildingId: id,
              floorId
            }
          })
        notification.error({
          key: 'stair_error',
          message: t('building.destinationNotFound')
        })
        setNewStair(undefined)
        const embedpano = document.getElementById('embedpano-full') as any
        embedpano.call(`removehotspot('stair-${stair_id}')`)
        return
      }

      // hard reload when change floor due to swr key error
      localStorage.setItem('targetPoint', targetPoint)
      localStorage.setItem('previewMode', 'true')
      window.location.href = `${getDirectoryBasename()}/buildings/${id}/floors/${targetFloor}`
    }
  }

  const handleUpdateStairPosition = () => {
    ;(window as any).handle_update_stair_position = (
      stair_id: string,
      h: string,
      v: string,
      targetFloor: string,
      targetPoint: string
    ) => {
      const krpano = document.getElementById('embedpano-full') as any
      const inith = krpano.get('init_h')
      const initv = krpano.get('init_v')
      const droph = krpano.get('drop_h')
      const dropv = krpano.get('drop_v')

      !isMutating &&
        (droph !== inith || dropv !== initv) &&
        addEditStairway({
          method: 'patch',
          query: [stair_id],
          data: {
            buildingId: Number(id),
            floorId: Number(floorId),
            h: Number(h),
            v: Number(v),
            nextFloorId: Number(targetFloor),
            nextPointId: targetPoint
          }
        })
    }
  }

  const handleDelete = () => {
    !isMutating &&
      addEditStairway({
        method: 'delete',
        query: newStair?.id ? [newStair.id] : undefined,
        data: null,
        params: {
          buildingId: id,
          floorId
        },
        successMessage: t('organization.successDeletedNotification')
      })

    setNewStair(undefined)
    const embedpano = document.getElementById('embedpano-full') as any
    embedpano.call(`remove_stairway('${newStair?.id}')`)
    setIsAddingStairway(false)
  }

  const handleEnterSubmit = () => {
    !isMutating &&
      addEditStairway(
        {
          method: newStair?.id ? 'patch' : 'post',
          query: newStair?.id ? [newStair.id] : undefined,
          data: {
            buildingId: Number(id),
            floorId: Number(floorId),
            h: Number(newStair?.h),
            v: Number(newStair?.v),
            nextFloorId: Number(newStair?.nextFloorId),
            nextPointId: newStair?.nextPointId
          },
          successMessage: t('organization.successNotification')
        },
        {
          onSuccess: (data) => {
            const embedpano = document.getElementById('embedpano-full') as any
            if (embedpano) {
              const floorName = building?.floors.find(
                (item) => item.id === Number(newStair?.nextFloorId)
              )?.name
              const newTooltip = floorName
                ? `<p>フロア：${
                    floorName.length > 10
                      ? `${floorName.slice(0, 10)}...`
                      : floorName
                  }</p>`
                : ''
              if (newStair?.id) {
                embedpano.call(`remove_stairway('${newStair?.id}')`)
                embedpano.call(
                  `add_stairway(${newStair?.id}, ${newStair?.h}, ${newStair?.v}, ${newStair?.nextFloorId}, ${newStair?.nextPointId}, ${newTooltip})`
                )
              } else {
                embedpano.call(
                  `add_stairway(${data.data.stairwayId}, ${newStair?.h}, ${newStair?.v}, ${newStair?.nextFloorId}, ${newStair?.nextPointId}, ${newTooltip})`
                )
              }
              setNewStair(undefined)
            }
          }
        }
      )
    setIsAddingStairway(false)
  }

  handleAddStairway()
  handleSelectStair()
  handleUpdateStairPosition()

  if (!isAddingStairway) return null

  return (
    <Modal
      className="modal-custom"
      width={506}
      rootClassName=""
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <Title title="building.settingStairway" />

      <div className="flex items-center gap-2 p-[10px] relative">
        <Typography className="font-bold">
          {t('common.sidebar.floor')}
        </Typography>
        <Select
          loading={isValidatingFloor}
          style={{
            width: 220,
            overflow: 'hidden'
          }}
          options={listFloors}
          value={newStair?.nextFloorId}
          onSelect={handleSelectFloor}
          disabled={!listFloors.length}
          className={`absolute left-1/2 -translate-x-1/2 ${
            !listFloors.length ? 'select-floot-disabled' : ''
          }`}
          suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
        />
      </div>

      <div className="min-h-[360px] max-h-[360px] flex flex-col items-center gap-2 overflow-y-auto mt-2">
        {isValidatingPoints && <Spinner />}
        {!isValidatingPoints &&
          listPoints.map((item) => (
            <div
              key={item.id}
              className="flex items-center gap-2 cursor-pointer relative"
              onClick={() =>
                setNewStair((prev) => ({
                  ...prev,
                  nextPointId: item.id,
                  nextPointOrder: item.order
                }))
              }
            >
              <div className="absolute top-1/2 -translate-y-1/2 -left-10">
                <CustomCheckBox
                  id={item.id}
                  isDefault={false}
                  checked={newStair?.nextPointId}
                />
              </div>
              <div className="w-[220px] h-[110px] object-contain relative">
                <div className="absolute top-1 left-1 z-10 rounded-lg flex bg-[#021120] p-[2px] min-w-[16px]">
                  <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
                    {item.order}
                  </div>
                </div>
                <LazyThumbnail
                  url={item.image360?.thumbnail || ''}
                  name={item.image360?.thumbnail || ''}
                  width={220}
                  height={110}
                />
              </div>
            </div>
          ))}
      </div>

      <div className="flex gap-10 justify-center mt-10">
        {newStair?.id ? (
          <Button
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
            onClick={handleDelete}
          >
            {t('organization.buttonDelete')}
          </Button>
        ) : null}

        <Button sizing="w-[140px] h-[50px]" onClick={handleCancel}>
          {t('building.buttonCancel')}
        </Button>

        <Button
          onClick={handleEnterSubmit}
          type="primary"
          sizing="w-[140px] h-[50px]"
          disabled={!newStair?.nextPointId}
        >
          {t('building.buttonSave')}
        </Button>
      </div>
    </Modal>
  )
}
