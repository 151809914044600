import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Checkbox, Empty, Form, FormInstance, Input, Typography } from 'antd'
import clsx from 'clsx'
import { useRecoilState } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import useSWR from 'swr'
import { AnnotationType, BuildingFile, ListBuildingFiles } from 'types/building'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

import PreviewFile from '../PreviewFile'

type Props = {
  form: FormInstance
  selectedType: AnnotationType | undefined
  selectedTabs: number
  selectedTabIndex: number
  selectedCategory: string
  linkParse: any
  nameParse: any
  memoParse: any
}

export default function BCPAnnotationCategoryDetail({
  form,
  selectedType,
  selectedTabs,
  selectedTabIndex,
  selectedCategory,
  linkParse,
  nameParse,
  memoParse
}: Props) {
  const { id } = useParams()
  const { t } = useTranslation()

  const [isSelectingFiles, setIsSelectingFiles] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<BuildingFile[]>([])

  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)

  const { data, isValidating } = useSWR<ListBuildingFiles>(
    selectedType && selectedTabs
      ? {
          url: 'v1/buildings/files',
          params: {
            buildingId: id,
            category: selectedTabs
          }
        }
      : null
  )

  const filteredTabFileList = useMemo(
    () =>
      currentSelected?.files?.filter(
        (item) => data?.files.files.find((file) => file.id === item.id)
      ) || [],
    [currentSelected?.files, data?.files.files]
  )

  const filteredSavedFiles = useMemo(() => {
    if (selectedCategory === 'video') {
      return filteredTabFileList.filter(
        (item) => item.extension === 'mp4' || item.extension === 'mov'
      )
    }
    if (selectedCategory === 'image') {
      return filteredTabFileList.filter(
        (item) =>
          item.extension === 'jpg' ||
          item.extension === 'png' ||
          item.extension === 'jpeg'
      )
    }
    if (selectedCategory === 'pdf') {
      return filteredTabFileList.filter((item) => item.extension === 'pdf')
    }
    return []
  }, [filteredTabFileList, selectedCategory])

  const filteredSelectableFiles = useMemo(() => {
    if (selectedCategory === 'video') {
      return data?.files.files.filter(
        (item) => item.extension === 'mp4' || item.extension === 'mov'
      )
    }
    if (selectedCategory === 'image') {
      return data?.files.files.filter(
        (item) =>
          item.extension === 'jpg' ||
          item.extension === 'png' ||
          item.extension === 'jpeg'
      )
    }
    if (selectedCategory === 'pdf') {
      return data?.files.files.filter((item) => item.extension === 'pdf')
    }
    return []
  }, [data?.files.files, selectedCategory])

  const handleSelectFile = (file: BuildingFile) => {
    if (selectedFiles.find((item) => item.id === file.id)) {
      setSelectedFiles((prev) => prev.filter((item) => item.id !== file.id))
      return
    }
    setSelectedFiles((prev) => [...prev, file])
  }

  const handleClose = () => {
    setIsSelectingFiles(false)
    setSelectedFiles(currentSelected?.files || [])
  }

  const handleSubmit = () => {
    setIsSelectingFiles(false)
    setCurrentSelected((prev) => ({
      ...prev,
      files: selectedFiles
    }))
  }

  const link = useMemo(
    () =>
      linkParse?.find((item: any, index: any) => index === selectedTabIndex),
    [linkParse, selectedTabIndex]
  )

  const name = useMemo(
    () =>
      nameParse?.find((item: any, index: any) => index === selectedTabIndex),
    [nameParse, selectedTabIndex]
  )

  const memo = useMemo(
    () =>
      memoParse?.find((item: any, index: any) => index === selectedTabIndex),
    [memoParse, selectedTabIndex]
  )

  useEffect(() => {
    if (currentSelected?.files) {
      setSelectedFiles(currentSelected.files)
    }
  }, [currentSelected?.files])

  useEffect(() => {
    if (selectedCategory === 'link' && selectedTabs) {
      form.setFieldValue('name', name || '')
      form.setFieldValue('link', link || '')
    }
    if (selectedCategory === 'memo' && selectedTabs) {
      form.setFieldValue('memo', memo || '')
    }
    form.validateFields(['link', 'name', 'memo'])
  }, [form, link, memo, name, selectedCategory, selectedTabs])

  if (selectedCategory === 'link') {
    return (
      <Form
        form={form}
        colon={false}
        labelCol={{ span: 5 }}
        labelAlign="left"
        wrapperCol={{ span: 19 }}
      >
        <div className="h-[300px] pt-9 pl-[31px] pr-[50px]">
          <Form.Item
            name="link"
            label={t('building.inputURLAnnotation')}
            rules={[{ validator: Validators.urlValidator(t) }]}
          >
            <Input placeholder="https://" className="h-[38px]" />
          </Form.Item>
          <Form.Item
            name="name"
            label={t('building.inputNameAnnotation')}
            rules={[
              {
                validator: Validators.keywordsAndLengthCustomValidator(
                  t,
                  500,
                  undefined,
                  true
                )
              }
            ]}
          >
            <Input
              placeholder={t('building.placeholder')}
              className="h-[38px]"
            />
          </Form.Item>
        </div>
      </Form>
    )
  }

  if (selectedCategory === 'memo') {
    return (
      <div className="h-[300px] pt-9 px-[50px]">
        <Form form={form} preserve={false}>
          <Form.Item
            name="memo"
            className="thin-light-placeholder"
            rules={[
              {
                validator: Validators.memoValidator(t)
              }
            ]}
          >
            <Input.TextArea
              placeholder={t('common.floor.placeHolder')}
              autoSize={{ minRows: 6, maxRows: 11 }}
              className="text-sm text-[#021120]"
            />
          </Form.Item>
        </Form>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="h-[300px] overflow-auto">
          {filteredSavedFiles?.length ? (
            filteredSavedFiles
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <div
                  key={item.id}
                  className="grid grid-cols-[auto_1fr] gap-5 items-center px-[10px] h-[60px]"
                >
                  <PreviewFile file={item} size="default" />
                  <Typography.Text
                    className="text-base font-medium text-[#021120]"
                    ellipsis={{
                      tooltip: item.name
                    }}
                  >
                    {item.name}
                  </Typography.Text>
                </div>
              ))
          ) : (
            <Empty className="mt-5" />
          )}
        </div>

        <Button
          type="primary"
          sizing="w-[300px] h-[60px]"
          className="mx-auto"
          onClick={() => setIsSelectingFiles(true)}
        >
          {t('building.selectFile')}
        </Button>
      </div>

      <Modal open={isSelectingFiles} width={900} className="paddingless-modal">
        <div className="pt-10 pb-5">
          <Title title={t(`translation.${selectedTabIndex + 1}`)} />

          <Spinner spinning={isValidating}>
            <div className="mx-7 my-[30px] h-[360px] overflow-y-auto">
              {Boolean(!isValidating && filteredSelectableFiles?.length) &&
                filteredSelectableFiles
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((item, index) => (
                    <div
                      key={item.id}
                      className={clsx(
                        'h-[60px] grid grid-cols-[auto_auto_1fr] gap-5 items-center cursor-pointer',
                        'border-0 border-b border-solid border-[#EEEFF2]',
                        { 'border-t': index === 0 }
                      )}
                      onClick={() => handleSelectFile(item)}
                    >
                      <Checkbox
                        checked={Boolean(
                          selectedFiles.find((file) => file.id === item.id)
                        )}
                      />
                      <PreviewFile file={item} size="large" />
                      <Typography.Text
                        className="text-base font-medium text-[#021120]"
                        ellipsis={{
                          tooltip: item.name
                        }}
                      >
                        {item.name}
                      </Typography.Text>
                    </div>
                  ))}

              {Boolean(!isValidating && !filteredSelectableFiles?.length) && (
                <Empty className="mt-5" />
              )}
            </div>
          </Spinner>

          <div className="pt-5 flex gap-10 justify-center border-0 border-t border-solid border-[#EEEFF2]">
            <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
              {t('common.cancel')}
            </Button>
            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              onClick={handleSubmit}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
