import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useDirectory from 'hooks/useDirectory'
import { Building } from 'models/Building'
import useSWR from 'swr'
import { Folders } from 'types/building'
import Utils from 'utils'

import ViewerHeader from 'components/organisms/Header/ViewerHeader'

import ListChildrenFolder from './components/ListChildrenFolder'
import ListFile from './components/ListFile'
import ListFolder from './components/ListFolder'

export default function BuildingFileList() {
  const { id } = useParams()
  const { navigate, getBuiildingType } = useDirectory()
  const { t } = useTranslation()

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${id}&building_type=${getBuiildingType()}`
    },
    {
      onError: (err) => {
        Utils.handleErrorNavigate(err, navigate)
      }
    }
  )

  const { getDirectory } = useDirectory()

  const { data: bcpCustomFolders, isValidating } = useSWR(
    getDirectory() === 'bcp' ? { url: `v1/bcp/buildings/${id}/folders` } : null
  )

  const listFolder = useMemo((): Folders[] => {
    if (getDirectory() === 'bcp') {
      if (!isValidating) {
        return [
          ...Const.BCP_BUILDING_FOLDER,
          ...(bcpCustomFolders
            ?.sort((a: any, b: any) =>
              dayjs(a.createdAt).diff(dayjs(b.createdAt))
            )
            .map((item: any) => ({
              id: item.id,
              label: item.name,
              isFileHolder: false,
              isCustomFolder: true,
              children: item.childFolders.map((folder: any) => ({
                id: folder.id,
                categoryId: folder.categoryId,
                label: folder.name,
                isFileHolder: true,
                children: null
              }))
            })) || [])
        ]
      }
      return []
    }
    return Const.BUILDING_FOLDER
  }, [bcpCustomFolders, getDirectory, isValidating])

  return (
    <div className="h-screen grid grid-rows-[auto_1fr] overflow-x-hidden bg-[#F9FBFF]">
      <ViewerHeader building={building} />

      <div className="grid grid-rows-[auto_auto_1fr] gap-5 p-5 overflow-auto">
        <Typography className="text-xl font-bold text-[#021120]">
          {t('building.titleFileList')}
        </Typography>

        <ListFolder listFolder={listFolder} />

        <ListChildrenFolder listFolder={listFolder} />

        <ListFile listFolder={listFolder} />
      </div>
    </div>
  )
}
