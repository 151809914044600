import { MouseEvent, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useRecoilState } from 'recoil'
import currentViewerState from 'store/viewerStore'
import { Point } from 'types/building'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

type Props = {
  point: Point
}

export default function ViewerPointImages({ point }: Props) {
  const { id, image360, order } = point

  const [isExpanded, setIsExpanded] = useState(false)

  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)

  const listSubImages = useMemo(
    () =>
      currentViewer.pointDetail?.id === point.id
        ? [...(currentViewer.pointDetail?.subImages || [])].sort(
            (a, b) => Number(a.order) - Number(b.order)
          )
        : [],
    [
      point.id,
      currentViewer.pointDetail?.id,
      currentViewer.pointDetail?.subImages
    ]
  )

  const handleSelectPoint = () => {
    const panoViewer = document.getElementById('embedpano-viewer') as any
    const isLoadComplete = panoViewer?.get('loadcomplete')
    const sessionImage = sessionStorage.getItem(id)
    if (
      currentViewer.selectedPoint === id &&
      sessionImage &&
      sessionImage !== 'main'
    ) {
      sessionStorage.setItem(id, 'main')
      panoViewer.call(
        `
        set(loadcomplete,false);
        image.reset();
        set(image.sphere.url, ${currentViewer.pointDetail?.image360?.s3Path});
        set(preview.url, ${currentViewer.pointDetail?.image360?.thumbnail});
        loadpanoimage(MERGE | KEEPLOOKAT | KEEPHOTSPOTS , BLEND(0.9, easeoutquad));
        `
      )
      return
    }

    if (isLoadComplete === undefined || isLoadComplete === 'true') {
      setCurrentViewer((prev) => ({
        ...prev,
        selectedPoint: id
      }))
    }
  }

  const handleSelectSubimage = (
    subImageId: number,
    s3Path: string,
    thumbnail: string
  ) => {
    const panoViewer = document.getElementById('embedpano-viewer') as any
    if (currentViewer.selectedPoint !== id) return

    if (panoViewer && panoViewer?.get('loadcomplete') === 'true') {
      const sessionImage = sessionStorage.getItem(id)
      if (sessionImage && sessionImage !== `${subImageId}`) {
        sessionStorage.setItem(id, `${subImageId}`)

        panoViewer.call(
          `
          set(loadcomplete,false);
          image.reset();
          set(image.sphere.url, ${s3Path});
          set(preview.url, ${thumbnail});
          loadpanoimage(MERGE | KEEPLOOKAT | KEEPHOTSPOTS , BLEND(0.9, easeoutquad));
          `
        )
      }
    }
  }

  const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (
      !currentViewer.selectedPoint ||
      currentViewer.selectedPoint !== point.id
    ) {
      setCurrentViewer((prev) => ({ ...prev, selectedPoint: point.id }))
    }
    setIsExpanded((prev) => !prev)
  }

  return (
    <>
      <div
        className={clsx('p-[10px] min-h-[36px]', {
          'theme-bg-sub-primary': currentViewer.selectedPoint === id
        })}
        onClick={handleSelectPoint}
      >
        <div
          className={`absolute ${
            image360 ? 'top-[15px] left-[16px]' : 'top-[10px] left-[10px]'
          } z-10 rounded-lg flex bg-[#021120] p-[2px] min-w-[16px]`}
        >
          <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
            {order}
          </div>
        </div>

        {point.isSubImages && (
          <div
            className="absolute top-[15px] left-[35px] z-10 rounded-full flex bg-[#787B82CC] w-[18px] h-[18px]"
            onClick={handleToggle}
          >
            <RotatedArrowIcon className="m-auto" />
          </div>
        )}

        {image360 && (
          <LazyThumbnail
            url={image360.thumbnail || image360.s3Path}
            name={id}
          />
        )}
      </div>
      {isExpanded &&
        listSubImages.map((item) => (
          <div
            key={item.id}
            className="p-[10px] min-h-[36px]"
            onClick={() =>
              handleSelectSubimage(item.id, item.s3Path, item.thumbnail)
            }
          >
            <LazyThumbnail url={item.thumbnail} name={id} />
          </div>
        ))}
    </>
  )
}
