import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import SmallNotificationList from 'pages/notifications/components/SmallNotificationList'
import { iijApiDomain } from 'services/authAxios'

import Link from 'components/atoms/Link'
import Title from 'components/atoms/Title'

export default function SmzSignin() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = 'shimz_デジトリ360'
  }, [])

  return (
    <div className="w-[90vw] h-full flex justify-center items-center gap-16 mx-auto">
      <div className="w-full max-w-[800px] flex flex-col items-center gap-44">
        <div className="w-full px-5">
          <div
            className="w-full aspect-[758/140]"
            style={{
              backgroundImage: "url('/logo/digitori360_logo_white.png')",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }}
          />
        </div>
        <SmallNotificationList />
      </div>

      <div
        className="rounded-[20px] !w-[470px] !h-[438px] p-[60px] bg-white"
        style={{
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)'
        }}
      >
        <div className="w-[350px] h-[318px] gap-[38px] flex flex-col">
          <Title
            className="!mb-0"
            bottomMargin="mb-0"
            title="common.smzSignin.title"
          />

          <p className="mb-0 leading-[26px]  ">
            {t('common.smzSignin.subTitle')}
          </p>

          <Link
            to={iijApiDomain || ''}
            className="text-primary font-semibold flex justify-center underline"
          >
            <Button type="primary" className="w-full" size="large">
              {t('login.buttonLogin')}
            </Button>
          </Link>

          <p className="mb-0 leading-[26px] text-[13px] #021120">
            {t('common.smzSignin.description')}
          </p>
        </div>
      </div>
    </div>
  )
}
