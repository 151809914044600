import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function EmailSvg() {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0645504 3.48208C0.177246 2.9144 0.428955 2.39492 0.78047 1.96577C0.854589 1.87227 0.932032 1.78853 1.01597 1.70479C1.63877 1.07891 2.51289 0.688477 3.46782 0.688477H21.5322C22.4871 0.688477 23.3581 1.07891 23.9838 1.70479C24.0676 1.78853 24.145 1.87227 24.2193 1.96577C24.571 2.39487 24.8228 2.9144 24.9323 3.48208C24.9773 3.70117 25 3.92759 25 4.15649V16.8437C25 17.3304 24.8968 17.7982 24.7127 18.2208C24.5419 18.6241 24.2902 18.989 23.9839 19.295C23.9062 19.3725 23.8292 19.4437 23.745 19.5145C23.1451 20.0113 22.3708 20.3113 21.5322 20.3113H3.46782C2.62895 20.3113 1.85122 20.0113 1.25479 19.5113C1.1709 19.4437 1.09341 19.3725 1.01597 19.295C0.709814 18.989 0.458105 18.6241 0.283836 18.2208V18.2176C0.0998039 17.7951 4.95911e-05 17.3304 4.95911e-05 16.8437V4.15649C0 3.92759 0.0223141 3.70117 0.0645504 3.48208ZM22.7291 2.95942C22.4192 2.64995 22.0032 2.4626 21.5322 2.4626H3.46782C2.99683 2.4626 2.5771 2.64995 2.27095 2.95942C2.21616 3.01455 2.16455 3.07598 2.11899 3.13701L11.5871 11.3887C11.8482 11.6176 12.1708 11.7306 12.5 11.7306C12.8259 11.7306 13.1482 11.6176 13.4128 11.3887L22.8773 3.1335C22.8354 3.07246 22.7838 3.01455 22.7291 2.95942ZM23.2259 16.8437V4.96939L16.3741 10.9467L23.2224 16.9176C23.2259 16.895 23.2259 16.8695 23.2259 16.8437ZM3.46782 18.5369H21.5322C21.8389 18.5369 22.1258 18.4562 22.3708 18.3146L15.1453 12.0178L14.4707 12.6046C13.9062 13.0951 13.1998 13.3436 12.5 13.3436C11.7966 13.3436 11.0902 13.0951 10.5258 12.6046L9.85156 12.0178L2.62891 18.3146C2.87417 18.4562 3.16113 18.5369 3.46782 18.5369ZM1.77412 16.8437C1.77412 16.8695 1.77412 16.895 1.77744 16.9176L8.62568 10.9501L1.77412 4.97256V16.8437Z"
        fill="#4B4B4B"
      />
    </svg>
  )
}

export default function EmailIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={EmailSvg} {...props} />
}
