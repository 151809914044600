import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import CustomCheckBox from 'pages/building/floor/components/CustomCheckBox'
import useSWR from 'swr'
import { BuildingStatus } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import CopyLinkIcon from 'components/icons/CopyLinkIcon'

type Props = {
  handleCancel: () => void
}

export default function ModalChangeStatus({ handleCancel }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const { navigate, getBuiildingType } = useDirectory()

  const [buildingStatus, setBuildingStatus] = useState<BuildingStatus>()

  const {
    data: building,
    mutate,
    isValidating
  } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${id}&building_type=${getBuiildingType()}`
    },
    {
      revalidateOnMount: false
    }
  )

  useEffect(() => {
    if (building) {
      setBuildingStatus(building.status)
    }
  }, [building, building?.status])

  const { trigger: editFloorName, isMutating } =
    useMutation(`/v1/buildings-status`)

  const handleClose = () => {
    handleCancel()
  }

  const handleSubmit = () => {
    if (buildingStatus === building?.status) {
      notification.success({
        message: t('building.successChangeStatus'),
        key: t('building.successChangeStatus'),
        duration: 3
      })
      handleClose()
      return
    }
    !isMutating &&
      editFloorName(
        {
          method: 'post',
          data: {
            buildingId: Number(id),
            toStatus: buildingStatus
          },
          successMessage: t('building.successChangeStatus')
        },
        {
          onSuccess: () => {
            mutate()
            handleClose()
          },
          onError: () => {
            handleClose()
            navigate(`/buildings/${id}`)
          }
        }
      )
  }

  const statusItems: {
    id: BuildingStatus
    label: string
    isDefault: boolean
    isDisplay: boolean
  }[] = [
    {
      id: BuildingStatus.COMMUNITY,
      label: t('building.50'),
      isDefault: BuildingStatus.COMMUNITY === building?.status,
      isDisplay: getBuiildingType() === 10
    },
    {
      id: BuildingStatus.PUBLIC,
      label: t(
        getBuiildingType() === 10
          ? 'building.tourStatusPrivate'
          : 'building.statusPrivate'
      ),
      isDefault: BuildingStatus.PUBLIC === building?.status,
      isDisplay: true
    },
    {
      id: BuildingStatus.PRIVATE,
      label: t('building.30'),
      isDefault: BuildingStatus.PRIVATE === building?.status,
      isDisplay: true
    }
  ]

  const handleSelectStatus = (id: number) => {
    setBuildingStatus(id)
  }

  return (
    <Modal
      open={!isValidating}
      onCancel={handleClose}
      width={560}
      isEnterable
      onEnter={handleSubmit}
      className="paddingless-modal"
    >
      <div className="px-[60px] py-[50px] flex flex-col">
        <Title
          title={t('building.editStatusBuilding')}
          bottomMargin="mb-[31px]"
        />
        <div className="flex justify-center gap-[62px] cursor-pointer">
          {statusItems
            .filter((item) => item.isDisplay)
            .map((item) => (
              <div
                className="flex gap-[10px]"
                key={item.id}
                onClick={() => handleSelectStatus(item.id)}
              >
                <CustomCheckBox
                  id={item.id}
                  isDefault={item.isDefault}
                  checked={buildingStatus}
                />
                <Typography className="font-medium text-[#021120]">
                  {item.label}
                </Typography>
              </div>
            ))}
        </div>

        {getBuiildingType() === 10 && buildingStatus === 50 ? (
          <div className="mt-10 flex justify-between items-center gap-[10px]">
            <Link
              to={`${window.location.origin}/community/buildings/${id}`}
              target="_blank"
              className="whitespace-nowrap max-w-[407px] overflow-hidden text-ellipsis text-[#3163B7] text-[16px] font-medium underline hover:underline"
            >
              {`${window.location.origin}/community/buildings/${id}`}
            </Link>
            <div
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(
                  `${window.location.origin}/community/buildings/${id}`
                )
                notification.success({
                  key: 'copy-community-building',
                  message: 'リンクがコピーされました'
                })
              }}
            >
              <CopyLinkIcon />
            </div>
          </div>
        ) : null}

        <div className="flex gap-10 justify-center mt-10">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            sizing="w-[140px] h-[50px]"
          >
            {t('building.buttonSave')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
