import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function FireSvg() {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8658 8.9236C21.8658 8.9236 21.0899 12.0279 19.7599 12.0279C17.8998 12.0279 17.8754 9.58877 19.8706 7.81507C22.2579 5.69284 21.6439 0.499884 17.7086 0C19.538 2.49418 17.7522 6.1812 15.4364 4.49033C14.1975 3.58448 15.6022 2.05081 16.4342 1.71817C13.7695 0.0886195 11.8428 2.87083 12.6988 5.58107C13.3084 7.51066 12.4141 8.00809 11.7 8.15C10.3729 8.41553 9.56147 7.14542 9.39466 6.31394C7.052 7.67026 7.17709 10.4563 7.8054 11.8059L0 19.6056L1.58784 21.1919L10.7223 12.0651L19.8485 21.1919L21.4368 19.6056L19.5293 17.6995C25.595 14.8387 21.8658 8.9236 21.8658 8.9236Z"
        fill="#FF4242"
      />
      <path
        d="M3.29761 20.7725V27.9568H8.9578V23.1233H12.4757V27.9568H18.1358V20.7725L10.7206 13.3568L3.29761 20.7725Z"
        fill="#FF4242"
      />
    </svg>
  )
}

export default function FireIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={FireSvg} {...props} />
}
