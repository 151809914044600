import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useDirectory from 'hooks/useDirectory'
import { Building } from 'models/Building'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'

import CommunityAnnotationModal from './components/CommunityAnnotationModal'
import CommunityLeftMenu from './components/CommunityLeftMenu'
import CommunityMiniMap from './components/CommunityMiniMap'
import CommunityPanorama from './components/CommunityPanorama'

export default function CommunityBuildingDetail() {
  const { id } = useParams()
  const { navigate } = useDirectory()

  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const currentSelected = useRecoilValue(currentAnnotation)

  const {
    data: building,
    isValidating,
    mutate: refetchBuilding
  } = useSWR<Building>(
    {
      url: `/v1/tour/buildings/${id}`
    },
    {
      onSuccess: (data) => {
        setCurrentViewer((prev) => {
          if (prev.selectedFloor !== null) {
            const isFloorCurrent = data.floors.findIndex(
              (item) => item.id === prev.selectedFloor
            )
            if (isFloorCurrent !== -1) {
              return prev
            }
          }
          return {
            ...prev,
            selectedFloor: data.floors[0].id
          }
        })
      },
      onError: (err) => {
        Utils.handleErrorNavigate(err, navigate)
      }
    }
  )

  const floors = useMemo(() => building?.floors || [], [building?.floors])

  return (
    <Spinner spinning={isValidating}>
      <div className="h-[100dvh] overflow-x-hidden">
        <CommunityLeftMenu floors={floors} refetch={refetchBuilding} />
        {currentViewer.selectedPoint ? <CommunityPanorama /> : null}
        {currentViewer.isOpenMiniMap ? <CommunityMiniMap /> : null}

        {currentSelected ? <CommunityAnnotationModal /> : null}
      </div>
    </Spinner>
  )
}
