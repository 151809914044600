import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Input, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilValue } from 'recoil'
import { selectedPointDetail } from 'store/buildingStore'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  selectedCustomFolder:
    | { id: number; categoryId: number; label: string }
    | undefined
  refetchFolders: () => void
  handleCloseModal: () => void
}

export default function BCPAddEditFolderModal({
  selectedCustomFolder,
  refetchFolders,
  handleCloseModal
}: Props) {
  const { id } = useParams()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] =
    useState(false)

  const pointDetail = useRecoilValue(selectedPointDetail)

  const { trigger, isMutating } = useMutation(`v1/bcp/buildings/${id}/folders`)

  const handleAddEdit = (value: any) => {
    if (!isMutating) {
      if (selectedCustomFolder) {
        trigger(
          {
            method: 'patch',
            data: value,
            query: [selectedCustomFolder.id]
          },
          {
            onSuccess: () => {
              refetchFolders()
              handleCloseModal()
            },
            onError: () => {
              handleCloseModal()
            }
          }
        )
        return
      }
      trigger(
        {
          data: value
        },
        {
          onSuccess: () => {
            refetchFolders()
            handleCloseModal()
          },
          onError: () => {
            handleCloseModal()
          }
        }
      )
    }
  }

  const removeRelatedAnnotations = (type: number) => {
    const krpano = document.getElementById('embedpano-full') as any

    pointDetail?.annotations.forEach((item) => {
      if (item.type === type) {
        krpano?.call(`remove_annotation(${item.id})`)
      }
    })
  }

  const handleDelete = () => {
    if (!isMutating && selectedCustomFolder) {
      trigger(
        {
          method: 'delete',
          data: null,
          query: [selectedCustomFolder.id]
        },
        {
          onSuccess: () => {
            removeRelatedAnnotations(selectedCustomFolder.categoryId)
            refetchFolders()
            handleCloseModal()
          },
          onError: () => {
            handleCloseModal()
          }
        }
      )
    }
  }

  const openDeleteConfirmation = () => {
    setIsOpenDeleteConfirmation(true)
  }

  useEffect(() => {
    if (selectedCustomFolder?.label) {
      form.setFieldValue('name', selectedCustomFolder.label)
    }
  }, [form, selectedCustomFolder?.label])

  return (
    <Modal open className="paddingless-modal">
      <div className="px-[60px] py-[50px]">
        {!isOpenDeleteConfirmation ? (
          <>
            <Title title="注釈タイプ追加" />

            <Form
              form={form}
              colon={false}
              className="mt-2"
              onFinish={handleAddEdit}
            >
              <div className="flex gap-10 items-center">
                <Typography className="text-[14px] leading-[21px] font-medium">
                  注釈タイプ名
                </Typography>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      validator: Validators.keywordsAndLengthCustomValidator(
                        t,
                        100,
                        true,
                        true
                      )
                    }
                  ]}
                  className="mb-0 flex-grow thin-placeholder thin-error-message"
                >
                  <Input
                    placeholder={t('building.placeholder')}
                    className="h-[38px]"
                  />
                </Form.Item>
              </div>

              <div className="flex gap-10 justify-center mt-10">
                {selectedCustomFolder ? (
                  <Button
                    sizing="w-[140px] h-[50px]"
                    className="bg-[#B80808]"
                    onClick={openDeleteConfirmation}
                  >
                    {t('translation.delete')}
                  </Button>
                ) : null}
                <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
                  {t('translation.cancel')}
                </Button>
                <Button
                  sizing="w-[140px] h-[50px]"
                  type="primary"
                  htmlType="submit"
                >
                  {t('building.buttonSave')}
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <>
            <Title title="注釈タイプ削除" />

            <div className="text-sm mt-2 text-center">
              配置済みの注釈も削除されます。
              <br />
              本当に削除しますか？
            </div>

            <div className="mt-10 flex gap-10 justify-center">
              <Button
                sizing="w-[140px] h-[50px]"
                onClick={() => setIsOpenDeleteConfirmation(false)}
              >
                {t('translation.cancel')}
              </Button>
              <Button
                sizing="w-[140px] h-[50px]"
                className="bg-[#B80808]"
                onClick={handleDelete}
              >
                {t('translation.delete')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
