import clsx from 'clsx'
import { Floor } from 'models/Building'
import { useRecoilState } from 'recoil'
import currentViewerState from 'store/viewerStore'

import ShowBarIcon from 'components/icons/ShowBarIcon'

import CommunityFloorDetail from './CommunityFloorDetail'

type Props = {
  floors: Floor[]
  refetch: () => void
}

export default function CommunityLeftMenu({ floors, refetch }: Props) {
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)

  return (
    <>
      <div
        className={clsx(
          'absolute top-0 w-44 sm:w-[240px] min-h-[75vh] max-h-screen sm:min-h-screen theme-bg-third overflow-auto z-[500] grid grid-rows-[auto_auto_1fr] transition-all',
          {
            'left-0': currentViewer.isOpenLeftMenu
          },
          {
            '-left-64': !currentViewer.isOpenLeftMenu
          }
        )}
      >
        <CommunityFloorDetail floors={floors} refetch={refetch} />
      </div>

      <div
        className={clsx(
          'absolute top-3 cursor-pointer z-[500] transition-all',
          {
            'left-48 sm:left-64': currentViewer.isOpenLeftMenu
          },
          {
            'left-4': !currentViewer.isOpenLeftMenu
          }
        )}
        onClick={() =>
          setCurrentViewer((prev) => ({
            ...prev,
            isOpenLeftMenu: !prev.isOpenLeftMenu
          }))
        }
      >
        <ShowBarIcon
          className={!currentViewer.isOpenLeftMenu ? 'rotate-180' : ''}
        />
      </div>
    </>
  )
}
