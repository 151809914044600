import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Form, Input, Select, Typography } from 'antd'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'

import ModalAddEditNotify from './components/ModalAddEditNotify'
import NotificationList from './components/NotificationList'

export default function NotificationPage() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const openMode = searchParams.get('open_mode')

  const notiftStatus = [
    {
      value: null,
      label: null
    },
    {
      value: 'PUBLIC',
      label: t('translation.public')
    },
    {
      value: 'PRIVATE',
      label: t('translation.private')
    }
  ]

  const handleSearch = (value: any) => {
    Object.keys(value).forEach((key) => {
      if (searchParams.get(key) && !value[key]) {
        searchParams.delete(key)
      }
      value[key] && searchParams.set(key, value[key])
    })
    page && searchParams.delete('page')
    setSearchParams(searchParams)
  }

  const handleSelectNotify = (notifyId?: number) => {
    notifyId && searchParams.set('selected_notify', `${notifyId}`)
    searchParams.set('open_mode', 'edit')
    setSearchParams(searchParams)
  }

  const handleCloseModal = () => {
    searchParams.delete('selected_notify')
    searchParams.delete('open_mode')
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (searchParams) {
      const keyword = searchParams.get('keyword')
      const publicationStatus = searchParams.get('publicationStatus')
      form.setFieldsValue({
        keyword,
        publicationStatus
      })
    }
  }, [form, searchParams])

  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between">
          <div className="text-[#021120] font-bold text-xl">
            {t('translation.notificationList')}
          </div>

          <Button
            sizing="w-40 h-10"
            type="primary"
            onClick={() => handleSelectNotify()}
          >
            {t('translation.createNotify')}
          </Button>
        </div>

        <Form
          form={form}
          colon={false}
          className="mx-auto"
          onFinish={handleSearch}
        >
          <div className="bg-white w-[80vw] min-w-[950px] h-20 rounded-[10px] py-5 px-10 flex items-center gap-[30px]">
            <Form.Item
              label={
                <Typography className="text-[14px] leading-[21px] text-[#021120] font-bold">
                  キーワード
                </Typography>
              }
              name="keyword"
              className="mb-0 thin-error-message"
              rules={[{ validator: Validators.keywordValidator(t) }]}
            >
              <Input placeholder="キーワード" className="w-[180px] h-[38px]" />
            </Form.Item>

            <Form.Item
              label={
                <Typography className="text-[14px] leading-[21px] text-[#021120] font-bold">
                  {t('translation.notifyStatus')}
                </Typography>
              }
              name="publicationStatus"
              className="mb-0"
            >
              <Select
                className="!w-[180px] h-[38px]"
                placeholder={t('translation.notifyStatus')}
                options={notiftStatus}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              sizing="w-40 h-10"
              className="ml-auto"
            >
              検索
            </Button>
          </div>
        </Form>

        <NotificationList handleSelectNotify={handleSelectNotify} />
      </div>

      {openMode === 'edit' && (
        <ModalAddEditNotify handleCloseModal={handleCloseModal} />
      )}
    </>
  )
}
