import { useCallback, useEffect, useRef, useState } from 'react'
import Const from 'constants/constant'
import { CurrentUser } from 'models/User'
import Api from 'services/api'
import {
  clearIdTokens,
  emit,
  getToken,
  storeIdTokens
} from 'services/authAxios'
import useSWR, { useSWRConfig } from 'swr'
import { PublicConfiguration } from 'swr/_internal'
import Utils from 'utils'

import useDirectory from './useDirectory'

export default function useAuth(
  options?: Partial<PublicConfiguration<CurrentUser | null, any, any>>
) {
  const isSubmittingRef = useRef<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { rrdNavigate, navigate } = useDirectory()

  const {
    data: profile,
    mutate,
    error,
    isValidating
  } = useSWR<CurrentUser | null>('v1/users/me', Api.get, {
    dedupingInterval: 60 * 60 * 1000, // 1hr
    revalidateOnMount: false,
    ...options
  })

  const { mutate: cacheReset, cache } = useSWRConfig()

  const firstLoading = profile === undefined && error === undefined

  const register = useCallback(
    async (values: any, orgId: any) => {
      try {
        if (!isSubmittingRef.current && !isSubmitting) {
          isSubmittingRef.current = true
          setIsSubmitting(true)
          const response = await Api.post(
            'v1/users',
            Utils.trimObjectValues(values)
          ).catch(() => {
            navigate('/invitation-expired')
          })
          if (response) {
            if (orgId === 1) {
              storeIdTokens(response.data)
            }
            await mutate()
            setIsSubmitting(false)
            isSubmittingRef.current = false
            orgId === 1 ? rrdNavigate('/') : rrdNavigate('/signin')
          }
        }
      } catch (error) {
        setIsSubmitting(false)
      }
    },
    [isSubmitting, navigate, mutate, rrdNavigate]
  )

  const logout = useCallback(async () => {
    try {
      const { refreshToken } = getToken()
      Api.post(`v1/auth/logout`, {
        refreshToken
      })
    } catch (error) {
      // Handle error if necessary
    } finally {
      mutate(null, false).then(() => cacheReset(() => true, undefined, false))
      if (profile?.employeeId) {
        rrdNavigate('/smz_signin')
      } else {
        rrdNavigate('/signin')
      }
      clearIdTokens()
    }
  }, [mutate, profile?.employeeId, cacheReset, rrdNavigate])

  const forceLogout = () =>
    mutate(null, false).then(() => {
      const isAuthenticated = cache.get('v1/users/me')?.data
      if (isAuthenticated) {
        cacheReset(() => true, undefined, false)
      }
    })

  useEffect(() => {
    emit.addListener(Const.UNAUTHORIZED_ERROR, forceLogout)

    return () => {
      emit.removeListener(Const.UNAUTHORIZED_ERROR, forceLogout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    profile,
    mutate,
    signup: register,
    logout,
    firstLoading,
    isValidating,
    isSubmitting
  }
}
