import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import { useRecoilState } from 'recoil'
import { currentAnnotation, currentPovState } from 'store/krpanoStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import { Annotation, FloorMapElement } from 'types/building'
import { Direction, KrpanoInitProps, Stairway } from 'types/krpano'
import Utils from 'utils'
import Krpano from 'utils/krpano'

export default function useViewerKrpano() {
  const { id } = useParams()
  const { t } = useTranslation()
  const { navigate, getBuiildingType } = useDirectory()
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const [, setCurrentSelected] = useRecoilState(currentAnnotation)
  const [currentPov, setCurrentPov] = useRecoilState(currentPovState)

  const [currentHgap, setCurrentHgap] = useState(0)

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${id}&building_type=${getBuiildingType()}`
    },
    {
      revalidateOnMount: false
    }
  )
  const { data } = useSWR<FloorMapElement>(
    currentViewer.selectedFloor
      ? {
          url: 'v1/points',
          params: {
            buildingId: id,
            floorId: currentViewer.selectedFloor
          }
        }
      : null,
    {
      revalidateOnMount: false
    }
  )
  const { trigger, isMutating } = useMutation('v1/annotations')

  // handle redirect to next point
  const handleRedirect = () => {
    ;(window as any).handle_redirect = (
      item: any,
      clickh: any,
      clickv: any
    ) => {
      const ponitSelected = data?.points?.find((point) => point.id === item)
      if (ponitSelected?.image360) {
        const krpano = document.getElementById('embedpano-viewer') as any
        const h = krpano?.get('view.hlookat')
        const v = krpano?.get('view.vlookat')
        const z = krpano?.get('view.fov')

        krpano.call(`
          if(${clickv} GT -50 AND ${clickv} LT +50 AND 1000 GT 120, set(${clickv},0));
          set3dtransition("sphere", ${clickh}, ${clickv}, (min(1000,200)), 0, ${
            currentHgap - Number(ponitSelected.initView.radarHGap)
          }, 0.0, 0.9, easeoutquad);
        `)

        setCurrentPov({
          h: Number(h) + currentHgap - Number(ponitSelected.initView.radarHGap),
          v: Number(v),
          z
        })
        setCurrentViewer((prev) => ({
          ...prev,
          selectedPoint: item,
          selectedArea: `${ponitSelected.areaId}`
        }))
      } else {
        notification.error({
          key: 'redirect',
          message: '画像が設定されていません'
        })
      }
    }
  }

  // handle click annotaion
  const handleClickAnnotation = () => {
    ;(window as any).handle_click_annotation = (annotationId: string) => {
      setCurrentSelected({ id: annotationId, isMutating: true })
      !isMutating &&
        trigger(
          {
            method: 'get',
            data: {
              building_id: id,
              floor_id: currentViewer.selectedFloor,
              point_id: currentViewer.selectedPoint
            },
            query: [annotationId]
          },
          {
            onSuccess: (data) => {
              setCurrentSelected(data)
            },
            onError: (error) => {
              setCurrentSelected((prev) => ({
                ...prev,
                isMutating: false
              }))
              if (error.response.data.error.key === 'annotation_not_exists') {
                const krpano = document.getElementById(
                  'embedpano-viewer'
                ) as any
                krpano.call(`remove_annotation(${annotationId})`)
                notification.error({
                  message: t('translation.deletedAnnotationNotice')
                })
              }
              Utils.handleErrorNavigate(error, navigate)
            }
          }
        )
    }
  }

  const handleChangeFloor = () => {
    ;(window as any).handle_change_view_floor = (
      stair_id: string,
      targetFloor: string,
      targetPoint: string,
      clickh: any,
      clickv: any
    ) => {
      const embedpano = document.getElementById('embedpano-viewer') as any
      if (!building?.floors.find((item) => item.id === Number(targetFloor))) {
        notification.error({
          key: 'stair_error',
          message: t('building.destinationNotFound')
        })
        embedpano.call(`removehotspot('stair-${stair_id}')`)
        return
      }
      const h = embedpano?.get('view.hlookat')
      const v = embedpano?.get('view.vlookat')
      const z = embedpano?.get('view.fov')

      embedpano.call(`
        if(${clickv} GT -50 AND ${clickv} LT +50 AND 1000 GT 120, set(${clickv},0));
        set3dtransition("sphere", ${clickh}, ${clickv}, (min(1000,200)), 0, 0, 0.0, 0.9, easeoutquad);
      `)

      setCurrentPov({
        h: Number(h),
        v: Number(v),
        z
      })

      setCurrentViewer((prev) => ({
        ...prev,
        selectedFloor: Number(targetFloor),
        selectedPoint: targetPoint
      }))
    }
  }

  const krpanoOnreadyCallback = (
    krpano: any,
    krpanoInitProps: KrpanoInitProps,
    annotations?: Annotation[],
    directions?: Direction[],
    stairways?: Stairway[]
  ) => {
    const { h, v, z, hgap } = krpanoInitProps
    const varString = `view.hlookat=${h}&view.vlookat=${v}&view.fov=${z}`
    krpano.call(
      `loadpano('/krpano/xml-assets/pano_view.xml','${varString}',IGNOREKEEP,BLEND(1));`
    )
    // add hotspot to pano
    Krpano.AddDirectionArrow(krpano, directions || [], hgap)
    Krpano.AddAnnotation(krpano, annotations || [])
    Krpano.AddStairway(krpano, stairways || [])
  }

  const embedpano = (
    krpanoInitProps: KrpanoInitProps,
    annotations?: Annotation[],
    directions?: Direction[],
    stairways?: Stairway[]
  ) => {
    const embedpano = document.getElementById('embedpano-viewer') as any
    const target = document.getElementById('viewer-pano-container')
    if (target) {
      if (!embedpano) {
        ;(window as any).embedpano({
          id: 'embedpano-viewer',
          xml: null,
          target: 'viewer-pano-container',
          html5: 'auto+webgl',
          initvars: {
            url: krpanoInitProps?.imageUrl,
            previewurl: krpanoInitProps.previewUrl
          },
          vars: {
            'contextmenu.versioninfo': false,
            'contextmenu.fullscreen': false
          },
          bgcolor: '#FFFFFF',
          onready: (krpano: any) => {
            krpanoOnreadyCallback(
              krpano,
              krpanoInitProps,
              annotations,
              directions,
              stairways
            )
          }
        })
      } else {
        embedpano.call(
          `load_pano_image(${krpanoInitProps.imageUrl},
            ${krpanoInitProps.h},
            ${krpanoInitProps.v},
            ${krpanoInitProps.z},
            ${krpanoInitProps.previewUrl})`
        )
        currentPov && setCurrentPov(null)
        Krpano.AddDirectionArrow(
          embedpano,
          directions || [],
          krpanoInitProps.hgap
        )
        Krpano.AddAnnotation(embedpano, annotations || [])
        Krpano.AddStairway(embedpano, stairways || [])
      }
    }
  }

  // handle event view around
  handleRedirect()
  handleClickAnnotation()
  handleChangeFloor()

  useEffect(() => {
    return () => {
      Krpano.UnmountPanoViewer()
    }
  }, [])

  return {
    currentViewer,
    setCurrentViewer,
    currentPov,
    setCurrentHgap,
    embedpano
  }
}
