import { useTranslation } from 'react-i18next'
import { BiMenu } from 'react-icons/bi'
import { Dropdown, Space, Typography } from 'antd'
import Const from 'constants/constant'
import useAuth from 'hooks/useAuth'
import useDirectory from 'hooks/useDirectory'

import Link from 'components/atoms/Link'

export default function Sidebar() {
  const { t } = useTranslation()

  const { profile } = useAuth()

  const { getDirectory } = useDirectory()

  const items = Const.SIDEBAR_MENU.filter(
    (item) => profile?.role && item.role.includes(profile?.role)
  ).map(({ label, key, icon }) => {
    if (key === '/organizations' && profile?.role === 'UA') {
      key = `/organizations/${profile.organizationId}`
    }
    return {
      key,
      label: (
        <Link to={key} className="transition-none">
          <Typography.Text className="text-inherit w-40">
            {t(label)}
          </Typography.Text>
        </Link>
      ),
      icon: icon({})
    }
  })

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      className="h-30"
      overlayClassName={`${getDirectory()}-theme`}
      rootClassName="custom-menu-item"
    >
      <div
        className="absolute pl-[20px] inset-0 flex justify-start items-center z-10 w-min"
        onClick={(e) => e.preventDefault()}
      >
        <Space>
          <BiMenu className="cursor-pointer" color="white" size={25} />
        </Space>
      </div>
    </Dropdown>
  )
}
