import { ColumnTable } from 'types'

class TableColumn {
  static get COLUMN_USER(): ColumnTable[] {
    return [
      {
        name: 'avatar',
        label: '',
        width: '5%'
      },
      {
        name: 'name',
        label: 'user.name',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'organizationName',
        label: 'user.organizationName',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'email',
        label: 'user.email',
        width: '25%',
        hasSorter: true
      },
      {
        name: 'role',
        label: 'user.role',
        width: '10%',
        hasSorter: true
      },
      {
        name: 'latestLoginAt',
        label: 'user.latestLoginAt',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'invitedBy',
        label: 'user.invitedBy',
        width: '15%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_ORGANIZATION(): ColumnTable[] {
    return [
      {
        name: 'id',
        label: 'organization.id',
        width: '30%',
        hasSorter: true
      },
      {
        name: 'name',
        label: 'organization.name',
        width: '40%',
        hasSorter: true
      },
      {
        name: 'buildingCount',
        label: 'organization.buildingCount',
        width: '30%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_USER_SELECT_ORGANIZATION() {
    return [
      {
        name: 'checkbox',
        label: '',
        width: '5%'
      },
      {
        name: 'id',
        label: 'organization.id',
        width: '25%',
        hasSorter: true
      },
      {
        name: 'name',
        label: 'organization.name',
        width: '80%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_BUILDING(): ColumnTable[] {
    return [
      {
        name: 'image',
        label: '',
        width: '10%',
        hasSorter: false
      },
      {
        name: 'name',
        label: 'building.buildingName',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'orgName',
        label: 'building.organizationName',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'buildingStatus',
        label: 'building.status',
        width: '10%',
        hasSorter: true
      },
      {
        name: 'branchName',
        label: 'building.branch',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'createdAt',
        label: 'building.createdAt',
        width: '12.5%',
        hasSorter: true
      },
      {
        name: 'updatedAt',
        label: 'building.updatedAt',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'id',
        label: 'building.id',
        width: '7.5%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_USER_MANAGE_ORGANIZATION() {
    return [
      {
        name: 'name',
        label: 'user.organizationName',
        width: '100%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_USER_SELECT_BRANCH() {
    return [
      {
        name: 'checkbox',
        label: '',
        width: '5%'
      },
      {
        name: 'name',
        label: 'organization.branch',
        width: '95%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_USER_SHARED(): ColumnTable[] {
    return [
      {
        name: 'padding',
        label: '',
        width: '5%'
      },
      {
        name: 'name',
        label: '名前',
        width: '15%',
        hasSorter: true
      },
      {
        name: 'organizationName',
        label: '組織名',
        width: '30%',
        hasSorter: true
      },
      {
        name: 'email',
        label: 'メールアドレス',
        width: '30%',
        hasSorter: true
      },
      {
        name: 'action',
        label: '権限',
        width: '20%',
        hasSorter: true
      }
    ]
  }

  static get COLUMN_LIST_SET_ANNOTATION(): ColumnTable[] {
    return [
      {
        name: 'name',
        label: 'building.seAnnotationtName',
        width: '65%'
      },
      {
        name: 'actions',
        label: '',
        width: '35%'
      }
    ]
  }

  static get COLUMN_PLACING_SET_ANNOTATION(): ColumnTable[] {
    return [
      {
        name: 'checkbox',
        label: '',
        width: '10%'
      },
      {
        name: 'name',
        label: 'building.seAnnotationtName',
        width: '90%'
      }
    ]
  }

  static get COLUMN_NOTIFICATIONS() {
    return [
      {
        name: 'title',
        label: 'translation.title',
        width: '35%',
        hasSorter: true
      },
      {
        name: 'publicationStatus',
        label: 'translation.notifyStatus',
        width: '15%',
        align: 'center',
        hasSorter: true
      },
      {
        name: 'createdAt',
        label: 'building.createdDate',
        width: '15%',
        align: 'center',
        hasSorter: true
      },
      {
        name: 'publicationPeriod',
        label: 'translation.publicationPeriod',
        width: '25%',
        align: 'center',
        hasSorter: false
      },
      {
        name: 'actions',
        label: '',
        width: '10%'
      }
    ]
  }
}

export default TableColumn
