import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import useDirectory from 'hooks/useDirectory'

function LoadingSvg(color: string) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12C2.69133 12 0 9.30816 0 6C0 2.69184 2.69133 0 6 0C6.54147 0 6.98073 0.439264 6.98073 0.98073C6.98073 1.5222 6.54147 1.96146 6 1.96146C3.77323 1.96146 1.96146 3.77323 1.96146 6C1.96146 8.22677 3.77323 10.0385 6 10.0385C8.22677 10.0385 10.0385 8.22677 10.0385 6C10.0385 5.45853 10.4778 5.01927 11.0193 5.01927C11.5607 5.01927 12 5.45853 12 6C12 9.30816 9.30867 12 6 12Z"
        fill={color}
      />
    </svg>
  )
}

export default function LoadingIcon(props: Partial<CustomIconComponentProps>) {
  const { getDirectoryThemeColor } = useDirectory()

  return (
    <Icon component={() => LoadingSvg(getDirectoryThemeColor())} {...props} />
  )
}
