import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight
} from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { Pagination } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import dayjs from 'dayjs'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ListNotification } from 'types/notification'

import Button from 'components/atoms/Button'
import ExtraTable from 'components/organisms/ExtraTable'

type Props = {
  handleSelectNotify: (notifyId?: number) => void
}

export default function NotificationList({ handleSelectNotify }: Props) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')

  const params = useMemo(() => {
    const convertedQueries = Object.fromEntries(searchParams.entries())
    return {
      ...convertedQueries,
      page: page || 1,
      pageSize: 10,
      sort: convertedQueries?.sort,
      sortOrder: convertedQueries?.sortOrder
        ? convertedQueries.sortOrder
        : OrderByType.descend
    }
  }, [page, searchParams])

  const { data: notifications, isValidating } = useSWR<ListNotification>({
    url: '/v1/notifications',
    params
  })

  const listNotification = useMemo(
    () =>
      notifications?.edges.map((item) => ({
        id: item.id,
        title: <div className="grid grid-cols-1">{item.title}</div>,
        publicationStatus: (
          <div className="text-center">
            {t(`translation.${item.publicationStatus.toLowerCase()}`)}
          </div>
        ),
        createdAt: (
          <div className="text-center">
            {item.createdAt && dayjs(item.createdAt).format('YYYY/MM/DD')}
          </div>
        ),
        publicationPeriod: (
          <div className="grid grid-cols-[1fr_auto_1fr]">
            <div>
              {item.publicationStartAt &&
                dayjs(item.publicationStartAt).format('YYYY-MM-DD HH:mm')}
            </div>
            {item.publicationStartAt || item.publicationEndAt ? ' 〜 ' : null}
            <div>
              {item.publicationEndAt &&
                dayjs(item.publicationEndAt).format('YYYY-MM-DD HH:mm')}
            </div>
          </div>
        ),
        actions: (
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation()
              handleSelectNotify(item.id)
            }}
          >
            {t('translation.edit')}
          </Button>
        )
      })) || [],
    [handleSelectNotify, notifications?.edges, t]
  )

  const hiddenPagination = useMemo(
    () =>
      Boolean(
        notifications?.pagination.total &&
          notifications.pagination.total /
            Const.DEFAULT_PAGINATION.defaultPageSize >
            1
      ),
    [notifications?.pagination.total]
  )

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      if (sorter) {
        if (field === 'publicationStatus') {
          searchParams.set('sort', 'publication_status')
        } else if (field === 'createdAt') {
          searchParams.set('sort', 'created_at')
        } else {
          searchParams.set('sort', field)
        }
        searchParams.set(
          'sortOrder',
          OrderByType[sorter as keyof typeof OrderByType]
        )
      } else {
        searchParams.delete('sort')
        searchParams.delete('sortOrder')
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const onPageChange = useCallback(
    (page: number) => {
      searchParams.set('page', `${page}`)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="icon-prev flex items-center justify-center">
          <MdKeyboardDoubleArrowLeft className="text-[#3163b7]" />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="icon-next flex items-center justify-center">
          <MdKeyboardDoubleArrowRight className="text-[#3163b7]" />
        </div>
      )
    }
    return originalElement
  }

  return (
    <>
      <ExtraTable
        isDetail={false}
        isLoading={isValidating && !notifications}
        columns={TableColumn.COLUMN_NOTIFICATIONS}
        data={listNotification}
        onChange={onTableChange}
        total={notifications?.pagination.total}
        actionSelect={(item: any) => {
          window.open(`/notifications/${item.id}`, '_blank')
        }}
      />

      {hiddenPagination ? (
        <div className="flex justify-center">
          <Pagination
            showSizeChanger={false}
            className="my-4 custom-pagination"
            current={+(page || 1)}
            pageSize={10}
            total={notifications?.pagination.total || 0}
            onChange={onPageChange}
            itemRender={itemRender}
          />
        </div>
      ) : null}
    </>
  )
}
