import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import clsx from 'clsx'
import useMutation from 'hooks/useMutation'
import { CurrentUser } from 'models/User'
import { useRecoilState } from 'recoil'
import {
  buildingModeState,
  listPointState,
  selectedPoint,
  selectedPointDetail
} from 'store/buildingStore'
import { currentPovState } from 'store/krpanoStore'
import useSWR from 'swr'
import { BuildingFile, OpenModalDelete, Point } from 'types/building'
import Krpano from 'utils/krpano'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import DeleteIcon from 'components/icons/DeleteIcon'
import LockIcon from 'components/icons/LockIcon'
import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

import MenuDropDownPoint from './MenuDropDownPoint'
import PointSubImage from './PointSubImage'

type Props = {
  point: Point
  pointIndex: number
  isOpenConfirm: string
  setIsOpenConfirm: Dispatch<SetStateAction<string>>
  setOpenMenu: Dispatch<SetStateAction<OpenModalDelete>>
}

export default function PointGroupImages({
  point,
  pointIndex,
  isOpenConfirm,
  setIsOpenConfirm,
  setOpenMenu
}: Props) {
  const { id, image360, order, isLocked, lockedByUser, lockedBy } = point
  const { id: buildingId, floorId } = useParams()

  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedSubimage, setSelectedSubimage] = useState<number>()

  const [points, setPoints] = useRecoilState(listPointState)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)
  const [currentSelectedPoint, setCurrentSelectedPoint] =
    useRecoilState(selectedPoint)
  const [buildingMode, setBuildingMode] = useRecoilState(buildingModeState)
  const [currentPov, setCurrentPov] = useRecoilState(currentPovState)

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', {
    revalidateOnMount: false
  })
  const { trigger: handleSubImages } = useMutation(
    `v1/points/${currentSelectedPoint}/sub-images`
  )
  const { trigger: handleMainImage } = useMutation(
    `v1/points/${currentSelectedPoint}/main-image`
  )

  const listSubImages = useMemo(
    () =>
      currentSelectedPoint === pointDetail?.id
        ? [...(pointDetail?.subImages || [])].sort(
            (a, b) => Number(a.order) - Number(b.order)
          )
        : [],
    [currentSelectedPoint, pointDetail?.id, pointDetail?.subImages]
  )

  const toggleSubimagesList = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!currentSelectedPoint || currentSelectedPoint !== point.id) {
      setCurrentSelectedPoint(point.id)
    }
    setIsExpanded((prev) => !prev)
  }

  const handleDisplayImage = (s3Path: string, displayAnnotation: boolean) => {
    const krpano = document.getElementById('embedpano-full') as any
    krpano.call(
      `
      show_all_hotspot(${displayAnnotation});
      image.reset();
      set(image.sphere.url, ${s3Path});
      loadpanoimage(MERGE | KEEPLOOKAT | KEEPHOTSPOTS , BLEND(0.9, easeoutquad));
      `
    )
  }

  const handleSelectSubImage = (item: BuildingFile) => {
    Krpano.LoadChecking(() => {
      setSelectedSubimage(item.id)
      handleDisplayImage(item.s3Path, false)
    })
  }

  const handleSelectPoint = (item: Point) => () => {
    if (
      !points.find((point) => point.id === item.id)?.image360 &&
      buildingMode?.previewMode
    ) {
      return
    }
    if (
      !isOpenConfirm &&
      currentSelectedPoint !== item.id &&
      buildingMode.selectedMode === 'direction'
    ) {
      setIsOpenConfirm('point')
      return
    }
    if (currentSelectedPoint === item.id) {
      Krpano.LoadChecking(() => {
        !buildingMode.previewMode && setCurrentSelectedPoint(undefined)
        if (selectedSubimage) {
          setSelectedSubimage(undefined)
          handleDisplayImage(pointDetail?.image360?.s3Path || '', true)
        }
      })
      return
    }
    Krpano.LoadChecking(() => {
      currentPov && setCurrentPov(null)
      setSelectedSubimage(undefined)
      setBuildingMode((prev) => ({ ...prev, selectedMode: '' }))
      setCurrentSelectedPoint(item.id)
      currentPov && setCurrentPov(null)
      pointDetail?.id !== item.id && setPointDetail(undefined)
    })
  }

  const handleDragEnd = (res: DropResult) => {
    if (!res.destination || !pointDetail?.image360) return

    const newSubImages = [...(listSubImages || [])]
    const [reorderedItem] = newSubImages.splice(res.source.index, 1)
    if (res.destination?.droppableId === 'mainImages_droppable') {
      newSubImages.splice(res.destination.index, 0, {
        ...pointDetail.image360,
        order: reorderedItem.order
      })
      setPoints((prev) =>
        prev.map((item) =>
          item.id === currentSelectedPoint
            ? { ...item, image360: reorderedItem }
            : item
        )
      )
      setPointDetail(
        (prev) =>
          prev && {
            ...prev,
            image360: reorderedItem,
            subImages: newSubImages
          }
      )
      handleMainImage({
        data: {
          buildingId: Number(buildingId),
          floorId: Number(floorId),
          imageId: reorderedItem.id
        }
      })
      return
    }
    newSubImages.splice(res.destination.index, 0, reorderedItem)
    setPointDetail(
      (prev) =>
        prev && {
          ...prev,
          subImages: newSubImages.map((item, index) => ({
            ...item,
            order: index + 1
          }))
        }
    )
    handleSubImages({
      method: 'patch',
      data: {
        buildingId: Number(buildingId),
        floorId: Number(floorId),
        subImages: newSubImages.map((item) => item.id)
      }
    })
  }

  useEffect(() => {
    if (currentSelectedPoint !== point.id) {
      setIsExpanded(false)
    }
  }, [currentSelectedPoint, point.id])

  return (
    <Draggable draggableId={`drag ${id}`} index={pointIndex}>
      {(pointProvided: any) => (
        <div
          className="relative"
          ref={pointProvided.innerRef}
          {...pointProvided.draggableProps}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="mainImages_droppable">
              {(mainDroppableProvided) => (
                <>
                  <div
                    className="absolute top-0 left-0 w-full h-20"
                    {...mainDroppableProvided.droppableProps}
                    ref={mainDroppableProvided.innerRef}
                  >
                    {mainDroppableProvided.placeholder}
                  </div>
                  <div
                    {...pointProvided.dragHandleProps}
                    className={clsx(
                      'absolute top-0 left-0',
                      'p-[10px] min-h-[36px] border-0 border-b border-solid theme-border-secondary !cursor-pointer relative',
                      {
                        'theme-bg-sub-primary':
                          currentSelectedPoint === id && !selectedSubimage
                      },
                      {
                        'theme-bg-third':
                          currentSelectedPoint !== id || selectedSubimage
                      }
                    )}
                    onClick={handleSelectPoint(point)}
                  >
                    <div className="flex justify-between">
                      <div
                        className={`absolute ${
                          image360
                            ? 'top-[15px] left-[16px]'
                            : 'top-[10px] left-[10px]'
                        } z-10 rounded-lg flex bg-[#021120] p-[2px] min-w-[18px] h-[18px]`}
                      >
                        <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
                          {order}
                        </div>
                      </div>

                      {point.isSubImages && (
                        <div
                          className="absolute top-[15px] left-10 z-10 rounded-full flex bg-[#787B82CC] w-[18px] h-[18px]"
                          onClick={toggleSubimagesList}
                        >
                          <RotatedArrowIcon
                            className={`m-auto ${
                              isExpanded ? 'rotate-180' : ''
                            }`}
                          />
                        </div>
                      )}

                      {!buildingMode.previewMode && image360 && !isLocked && (
                        <div className="absolute top-[10px] right-[10px] z-20">
                          <MenuDropDownPoint
                            pointId={id}
                            pointOrder={order}
                            profile={profile}
                            setIsExpanded={setIsExpanded}
                          />
                        </div>
                      )}

                      {!buildingMode.previewMode && !image360 && (
                        <div
                          className="cursor-pointer w-5 h-5 absolute top-[10px] right-[10px] z-20 flex justify-center items-center"
                          onClick={(e: any) => {
                            e.stopPropagation()
                            setOpenMenu({
                              isOpen: 'handleDeletePoint',
                              pointId: id,
                              pointOrder: order
                            })
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      )}

                      {image360 && isLocked && (
                        <div
                          className={`grid grid-cols-[1fr_auto] items-center gap-2 absolute ${
                            id === currentSelectedPoint
                              ? 'top-[10px] right-[10px]'
                              : 'top-3 right-3'
                          } z-20 max-w-[180px]`}
                        >
                          <Typography.Text
                            className="text-[12px] leading-[18px] text-white px-1 truncate bg-[#02112070]"
                            ellipsis={{
                              tooltip: lockedByUser
                                ? `${lockedByUser.lastName} ${lockedByUser.firstName}`
                                : ''
                            }}
                          >
                            {lockedByUser
                              ? `${lockedByUser.lastName} ${lockedByUser.firstName}`
                              : ''}
                          </Typography.Text>
                          <div
                            className={clsx(
                              {
                                'w-[33px] h-[30px] pt-[2px] pl-[9px] theme-bg-sub-primary rounded-bl-md shadow-[0_4px_4px_0_#00000040]':
                                  id === currentSelectedPoint
                              },
                              {
                                'w-[27px] h-[27px] rounded-full flex justify-center items-center bg-[#787B82]':
                                  id !== currentSelectedPoint
                              }
                            )}
                          >
                            <LockIcon isSelfLocked={lockedBy === profile?.id} />
                          </div>
                        </div>
                      )}
                    </div>

                    {image360?.id && (
                      <LazyThumbnail
                        url={image360.thumbnail}
                        name={image360.name}
                      />
                    )}
                  </div>
                </>
              )}
            </Droppable>
            {isExpanded && (
              <Droppable droppableId="subImages_droppable">
                {(listSubImageProvided: any) => (
                  <div
                    {...listSubImageProvided.droppableProps}
                    ref={listSubImageProvided.innerRef}
                  >
                    {listSubImages.map((subImage, index) => (
                      <Draggable
                        key={subImage.id}
                        draggableId={`drag-${subImage.id}`}
                        index={index}
                      >
                        {(subImageProvided: any) => (
                          <div
                            ref={subImageProvided.innerRef}
                            {...subImageProvided.draggableProps}
                            {...subImageProvided.dragHandleProps}
                          >
                            <PointSubImage
                              isSelected={selectedSubimage === subImage.id}
                              subImage={subImage}
                              handleSelectSubImage={handleSelectSubImage}
                            />
                            {subImageProvided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {listSubImageProvided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </DragDropContext>
          {pointProvided.placeholder}
        </div>
      )}
    </Draggable>
  )
}
