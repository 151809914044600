import { DragEvent } from 'react'
import { Typography } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useRecoilValue } from 'recoil'
import { buildingModeState, selectedAreaState } from 'store/buildingStore'
import { BuildingFile } from 'types/building'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import TrashBinIcon from 'components/icons/TrashBinIcon'

dayjs.extend(utc)

type Props = {
  item: BuildingFile
  handleSelectImage: (id: number) => void
  selectImage: number | undefined
  isInFloor: boolean
  pointOrder: number
  confirmDelete: (key: { id: number; name: string }) => void
}

export default function Image360Detail({
  item,
  handleSelectImage,
  selectImage,
  isInFloor,
  pointOrder,
  confirmDelete
}: Props) {
  const buildingMode = useRecoilValue(buildingModeState)
  const selectedArea = useRecoilValue(selectedAreaState)

  const isDraggable =
    buildingMode.selectedMode === 'point' && isInFloor && !item.pointId

  const handleDragStart = (e: DragEvent<HTMLImageElement>) => {
    e.dataTransfer.setData('text', `imagedrag-${item.id}`)
  }

  const handleDragEnd = (e: DragEvent<HTMLImageElement>) => {
    e.preventDefault()
    e.dataTransfer.clearData('text')
  }

  return (
    <div className="px-3 py-2 flex justify-center items-center w-full theme-bg-third">
      <div
        className={clsx(
          'p-1 w-full',
          {
            'theme-bg-sub-primary': item.id === selectImage
          },
          { 'cursor-pointer': isInFloor && !item.pointId },
          {
            'cursor-not-allowed': !isInFloor || (item.pointId && selectedArea)
          }
        )}
        onClick={() => handleSelectImage(item.id)}
      >
        <div
          id={String(item.id)}
          className={clsx(
            'flex relative w-full aspect-[2/1] bg-[#021120] overflow-hidden',
            {
              'opacity-60': !isInFloor
            }
          )}
          draggable={isDraggable}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {/* display point number tied to image */}
          <div className="absolute top-[5px] left-[5px] z-10 flex gap-1 truncate">
            {isInFloor && Boolean(pointOrder) && (
              <div className="rounded-full flex bg-[#021120] p-[2px] min-w-[18px] h-[18px]">
                <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
                  {pointOrder}
                </div>
              </div>
            )}
            <Typography.Text
              className="px-1 text-white text-[12px] bg-[#02112070] w-[180px] truncate"
              ellipsis={{ tooltip: item.name }}
            >
              {item.name}
            </Typography.Text>
          </div>

          {/* display delete icon when select image */}
          {isInFloor && !pointOrder && item.id === selectImage ? (
            <div
              className="absolute top-[5px] right-[5px] z-10"
              onClick={(e) => {
                e.stopPropagation()
                confirmDelete({ id: item.id, name: item.name })
              }}
            >
              <div className="grid place-items-center rounded-full border-2 border-solid border-[#3B3C46] bg-[#D9D9D9] w-5 h-5">
                <TrashBinIcon />
              </div>
            </div>
          ) : null}

          <LazyThumbnail
            url={item.thumbnail || item.s3Path}
            className="w-full h-full"
            name={item.name}
            width={220}
            height={100}
          />
          <Typography className="px-1 text-white text-[12px] bg-[#02112070] absolute right-0 bottom-0">
            {dayjs(item.takenAt || item.createdAt)
              .utc()
              .format('YYYY-MM-DD HH:mm')}
          </Typography>
        </div>
      </div>
    </div>
  )
}
