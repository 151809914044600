import { useMemo } from 'react'
import useDirectory from 'hooks/useDirectory'

import Button from 'components/atoms/Button'
import Title from 'components/atoms/Title'
import EmailIcon from 'components/icons/EmailIcon'

export default function VersionNotFoundPage() {
  const { getDirectory, rrdNavigate } = useDirectory()

  const logoHeader = useMemo(() => {
    switch (getDirectory()) {
      case 'bcp':
        return '/logo/filled_header_bcp.png'
      case 'tour':
        return '/logo/filled_header_tour.png'
      default:
        return '/logo/filled_header.png'
    }
  }, [getDirectory])

  return (
    <div className="h-screen grid grid-rows-[auto_1fr] bg-[#F9FBFF]">
      <div className="flex items-center gap-7 h-14 px-7 pt-5">
        <img src={logoHeader} alt="logo" className="w-[207px] h-[38px]" />
      </div>

      <div className="grow h-full flex">
        <div className="rounded-[20px] shadow-[0px_0px_10px_0px_#00000026] px-[100px] w-[817px] h-[622px] m-auto flex flex-col justify-center">
          {getDirectory() === 'shimizu' ? (
            <>
              <Title title="『デジトリ360』についてのご紹介" />
              <div className="text-base space-y-[10px]">
                <div>
                  <span className="text-[#3163B7]">
                    建物のデジタル取扱説明書建物
                  </span>
                  です
                </div>
                <div>
                  「建築、電気、設備の
                  <span className="text-[#3163B7]">取扱説明書、図面</span>
                  等」の資料を
                  <span className="text-[#3163B7]">
                    360度写真に注釈アイコン
                  </span>
                  で
                </div>
                <div>わかりやすく紐付けします</div>
                <div>施設管理を、よりスマートに。</div>
                <br />
                <br />
                <div>詳細な内容は、下記よりお問い合わせください</div>
              </div>
            </>
          ) : null}
          {getDirectory() === 'bcp' ? (
            <>
              <Title title="デジトリ360 シリーズ『デジトリBCP』についてのご紹介" />
              <div className="text-base space-y-[10px]">
                <div>
                  <span className="text-[#247F52]">災害時</span>の
                  <span className="text-[#247F52]">建物利用方法</span>
                  （止水版、防火扉、震災対策本部のレイアウト等）の資料を
                </div>
                <div>
                  <span className="text-[#247F52]">360度写真</span>
                  に、
                  <span className="text-[#247F52]">
                    災害別（火災、水害、地震）
                  </span>
                  の<span className="text-[#247F52]">注釈アイコン</span>を使って
                </div>
                <div>分かりやすく紐付けすることが可能です</div>
                <br />
                <br />
                <div>詳細な内容は、下記よりお問い合わせください</div>
              </div>
            </>
          ) : null}
          {getDirectory() === 'tour' ? (
            <>
              <Title title="デジトリ360 シリーズ『デジトリツアー』についてのご紹介" />
              <div className="text-base space-y-[10px]">
                <div>
                  <span className="text-[#3EB1AD]">施設</span>の
                  <span className="text-[#3EB1AD]">見学</span>
                  資料、
                  <span className="text-[#3EB1AD]">紹介</span>
                  資料を
                  <span className="text-[#3EB1AD]">360度写真</span>
                  に、
                  <span className="text-[#3EB1AD]">注釈アイコン</span>
                  を使って分かりやすく紐付
                </div>
                <div>
                  け、現地での
                  <span className="text-[#3EB1AD]">見学者案内</span>
                  や、アクセス権限（誰でもアクセス、制限付き）を設定した
                </div>
                <div>
                  URLを発行、ホームページ等に掲載する
                  <span className="text-[#3EB1AD]">
                    デジタルツアー、デジタル見学会
                  </span>
                  が可能です
                </div>
                <br />
                <br />
                <div>詳細な内容は、下記よりお問い合わせください</div>
              </div>
            </>
          ) : null}
          <div className="mt-[30px] space-y-[10px]">
            <div className="text-[#021120] text-base font-bold text-center">
              お問い合わせ
            </div>
            <div className="text-center text-base">
              <EmailIcon />
              &nbsp;
              <span className="text-[#609DF9] underline">
                dt360-mlist@shimz.co.jp
              </span>
            </div>
          </div>
          <div className="mt-[30px] mx-auto">
            <Button
              type="primary"
              sizing="w-[350px] h-[60px]"
              onClick={() => {
                rrdNavigate('/')
              }}
            >
              閉じる
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
