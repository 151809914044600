import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification } from 'antd'
import useDirectory from 'hooks/useDirectory'
import { CurrentUser } from 'models/User'
import useKrpano from 'pages/building/panoramas/hooks/useKrpano'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingModeState,
  listPointState,
  revalidateFileListState,
  revalidatePointDetailState,
  selectedAreaState,
  selectedPointDetail
} from 'store/buildingStore'
import { displayToggleState } from 'store/displayToggleStore'
import { currentPovState } from 'store/krpanoStore'
import useSWR from 'swr'
import { Point } from 'types/building'
import Krpano from 'utils/krpano'

import BCPAnnotationModal from './components/BCPAnnotationModal/BCPAnnotationModal'
import DirectionSetting from './components/DirectionSetting'
import AnnotationModal from './components/ModalAddAnnotation'
import ModalAddStairway from './components/ModalAddStairway'
import usePreviewKrpano from './hooks/usePreviewKrpano'

export default function AreaPanorama() {
  const { id, floorId } = useParams()
  const { t } = useTranslation()
  const { getDirectory } = useDirectory()

  const [selectedPoint, setSelectedPoint] = useState('')

  const [buildingMode, setBuildingMode] = useRecoilState(buildingModeState)
  const [revalidatePointDetail, setRevalidatePointDetail] = useRecoilState(
    revalidatePointDetailState
  )
  const [, setDisplayToggle] = useRecoilState(displayToggleState)
  const [selectedArea, setSelectedArea] = useRecoilState(selectedAreaState)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)
  const points = useRecoilValue(listPointState)
  const currentPov = useRecoilValue(currentPovState)
  const [, setRevalidateFileList] = useRecoilState(revalidateFileListState)

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', {
    revalidateOnMount: false
  })

  const { mutate: refetchPoints } = useSWR(
    {
      url: 'v1/points',
      params: {
        buildingId: id,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const {
    embedpano: embedFull,
    currentSelectedPoint,
    currentSelectedAnnotation
  } = useKrpano({
    buildingMode: buildingMode.selectedMode
  })

  const isVisibleFull =
    buildingMode.previewMode && Boolean(currentSelectedPoint)

  const { embedpano: embedPreview } = usePreviewKrpano()

  // get point detail when select point
  const { mutate } = useSWR<Point>(
    selectedPoint && points
      ? {
          url: `v1/points/${selectedPoint}`,
          params: {
            building_id: id,
            floor_id: floorId
          }
        }
      : null,
    {
      onSuccess: (data) => {
        setPointDetail(data)
        if (data.areaId !== selectedArea) {
          setSelectedArea(data.areaId)
        }
        setRevalidatePointDetail(undefined)
        if (data.image360) {
          const isLocked = data.isLocked && data.lockedBy === profile?.id
          const isRefetchList = points?.find(
            (item) =>
              item.id === data.id &&
              (item.lockedBy !== data.lockedBy ||
                item.isLocked !== data.isLocked)
          )
          const initView = currentPov || data.initView
          const initProps = {
            imageUrl: data.image360.s3Path,
            previewUrl: data.image360.thumbnail,
            isLocked,
            hgap: data.initView.radarHGap,
            ...initView
          }
          embedFull(
            initProps,
            data.annotations,
            data.directions,
            data.stairways
          )
          embedPreview(initProps)
          isRefetchList && refetchPoints()
          setDisplayToggle({
            isLocked: data.isLocked,
            lockedBy: data.lockedBy
          })
        } else {
          Krpano.UnmountFull()
        }
      },
      onError: (err) => {
        if (err.response.data.error.key === 'point_not_exists_error') {
          refetchPoints()
          setRevalidateFileList(1)
          notification.error({
            message: t('translation.pointAlreadyDeleted')
          })
        }
      }
    }
  )

  useEffect(() => {
    if (
      revalidatePointDetail &&
      currentSelectedPoint === revalidatePointDetail
    ) {
      mutate()
      return
    }
    if (revalidatePointDetail && selectedPoint && !currentSelectedPoint) {
      setSelectedPoint('')
      setPointDetail(undefined)
      setRevalidatePointDetail(undefined)
      Krpano.UnmountFull()
      return
    }
    setRevalidatePointDetail(undefined)
  }, [
    currentSelectedPoint,
    mutate,
    pointDetail?.id,
    revalidatePointDetail,
    selectedPoint,
    setPointDetail,
    setRevalidatePointDetail
  ])

  useEffect(() => {
    if (currentSelectedPoint) {
      setSelectedPoint(currentSelectedPoint)
    }
  }, [currentSelectedPoint])

  const krpanoContentLayout =
    buildingMode.selectedMode === 'direction'
      ? 'absolute top-0 w-full bottom-[135px] bg-[#F9FBFF] border border-4 border-solid theme-border-sub-primary'
      : `absolute h-full w-full ${!isVisibleFull ? 'invisible' : ''}`

  return (
    <>
      <div id="krpano-container" className={krpanoContentLayout} />

      {currentSelectedAnnotation && getDirectory() !== 'bcp' && (
        <AnnotationModal />
      )}
      {currentSelectedAnnotation && getDirectory() === 'bcp' && (
        <BCPAnnotationModal />
      )}

      <ModalAddStairway />

      {buildingMode.selectedMode === 'direction' && (
        <DirectionSetting
          currentSelectedPoint={currentSelectedPoint}
          setBuildingMode={setBuildingMode}
        />
      )}
    </>
  )
}
