import { Typography } from 'antd'
import { useRecoilValue } from 'recoil'
import {
  buildingModeState,
  listPointState,
  selectedAreaState,
  selectedPoint,
  selectedPointDetail
} from 'store/buildingStore'

export default function Preview360() {
  const { previewMode } = useRecoilValue(buildingModeState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const selectedArea = useRecoilValue(selectedAreaState)
  const pointDetail = useRecoilValue(selectedPointDetail)
  const points = useRecoilValue(listPointState)

  // only visible when viewing map & selected area & floor have point
  const isVisible = !previewMode && selectedArea && points.length

  // prevent display other point image when select other point after unselect past point
  const isImageVisible =
    currentSelectedPoint || currentSelectedPoint === pointDetail?.id

  return (
    <div
      className={`
        flex flex-col theme-bg-secondary absolute -left-[240px] z-[600] w-60 h-48 select-none ${
          isVisible ? 'bottom-0' : '-bottom-60'
        }
      `}
    >
      <div className="flex h-10 px-5">
        <Typography className="text-[14px] leading-[21px] font-bold text-white my-auto">
          360プレビュー
        </Typography>
      </div>
      <div
        className={`w-full h-[152px] absolute ${
          isImageVisible ? 'bottom-0' : '-bottom-60'
        }`}
      >
        <div id="preview-krpano-container" className="h-full" />
      </div>
    </div>
  )
}
