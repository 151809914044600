import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Checkbox, Form, Input, notification } from 'antd'
import useAuth from 'hooks/useAuth'
import useDirectory from 'hooks/useDirectory'
import { CurrentUser, Role } from 'models/User'
import useSWR from 'swr'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import FormItem from 'components/atoms/FormItem'
import Link from 'components/atoms/Link'
import Spinner from 'components/atoms/Spinner'

export default function UserRegister() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { id, token } = useParams()
  const { signup } = useAuth()
  const { navigate } = useDirectory()

  const [isChecked, setIsChecked] = useState(false)

  const { data: user, isValidating } = useSWR<CurrentUser>(
    {
      url: `/v1/users/register/${id}/${token}`
    },
    {
      onSuccess: (data) => form.setFieldsValue(data),
      onError: () => navigate('/invitation-expired')
    }
  )

  const handleSignUp = (values: any) => {
    if (!isChecked) {
      notification.error({
        message: t('user.policyAgreeRequired')
      })
      return
    }
    if (!isValidating) {
      signup({ id: Number(id), token, ...values }, user?.organization?.id)
    }
  }

  return (
    <Spinner spinning={isValidating}>
      <div className="h-full grid grid-rows-[auto_1fr]">
        <div className="font-bold text-xl mb-5">{t('user.titleRegister')}</div>
        <Form
          layout="horizontal"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          className="bg-white relative"
          colon={false}
          requiredMark={false}
          onFinish={handleSignUp}
        >
          <Form.Item
            labelAlign="left"
            className="bg-primary text-[16px] py-2 pl-5 mb-2 text-white"
            label={
              <div className="text-white font-bold leading-6">
                {t('organization.titleForm')}
              </div>
            }
          >
            <div className="font-bold leading-6">
              {t('organization.content')}
            </div>
          </Form.Item>
          <FormItem
            label={t('user.name')}
            labelAlign="left"
            className="pl-5 label-custom-select"
            required
          >
            <div className="flex gap-8">
              <Form.Item
                name="lastName"
                className="mb-0"
                rules={[{ validator: Validators.nameValidator(t) }]}
              >
                <Input
                  className="w-[300px] h-[46px] py-[9px] px-4 text-base font-normal"
                  placeholder={t('user.lastName')}
                />
              </Form.Item>
              <Form.Item
                name="firstName"
                className="mb-0"
                rules={[{ validator: Validators.nameValidator(t) }]}
              >
                <Input
                  className="w-[300px] h-[46px] py-[9px] px-4 text-base font-normal"
                  placeholder={t('user.firstName')}
                />
              </Form.Item>
            </div>
          </FormItem>

          <FormItem
            label={t('user.nameKatakana')}
            labelAlign="left"
            className="pl-5 label-custom-select"
            required
          >
            <div className="flex gap-8">
              <Form.Item
                name="lastNameRuby"
                className="mb-0"
                rules={[
                  { validator: Validators.registerNameKatakanaValidator(t) }
                ]}
              >
                <Input
                  className="w-[300px] h-[46px] py-[9px] px-4 text-base font-normal"
                  placeholder={t('user.lastNameKatakana')}
                />
              </Form.Item>
              <Form.Item
                name="firstNameRuby"
                className="mb-0"
                rules={[
                  { validator: Validators.registerNameKatakanaValidator(t) }
                ]}
              >
                <Input
                  className="w-[300px] h-[46px] py-[9px] px-4 text-base font-normal"
                  placeholder={t('user.firstNameKatakana')}
                />
              </Form.Item>
            </div>
          </FormItem>

          <FormItem
            label={t('user.email')}
            className="pl-5 label-custom-select"
            name="email"
          >
            <div className="w-full h-[46px] py-[9px] text-base font-normal">
              {user?.email}
            </div>
          </FormItem>

          <FormItem
            label={t('user.phoneNumber')}
            className="pl-5 label-custom-select"
          >
            <Form.Item
              name="phone"
              className="mb-0"
              rules={[{ validator: Validators.phoneValidator(t) }]}
            >
              <Input
                autoComplete="new-phoneNumber"
                className="w-[300px] h-[46px] py-[9px] px-4 text-base font-normal"
                placeholder="03000000000"
              />
            </Form.Item>
          </FormItem>

          {user?.organization !== null ? (
            <FormItem
              label={t('user.companyName')}
              name="companyName"
              className="pl-5 label-custom-select"
            >
              <div className="h-[46px] py-[9px] text-base font-normal">
                {user?.organization?.name}
              </div>
            </FormItem>
          ) : (
            <FormItem
              label={t('user.companyName')}
              className="pl-5 label-custom-select"
              required
            >
              <Form.Item
                name="companyName"
                className="mb-0"
                rules={[{ validator: Validators.companyNameValidator(t) }]}
              >
                {user?.role === Role.USER && (
                  <Input
                    placeholder={t(`organization.placeholderName`)}
                    className="w-[300px] h-[46px] py-[9px] px-4 text-base font-normal"
                  />
                )}
              </Form.Item>
            </FormItem>
          )}

          <FormItem label={t('user.role')} className="pl-5 label-custom-select">
            <div className="h-[46px] py-[9px] text-base font-normal">
              {user?.role ? t(`user.${user.role}`) : ''}
            </div>
          </FormItem>

          <FormItem
            label={t('user.password')}
            className="pl-5 label-custom-select"
            required
          >
            <Form.Item
              name="password"
              className="mb-0"
              rules={[
                {
                  validator: Validators.passwordValidator(t)
                }
              ]}
            >
              <Input.Password
                maxLength={14}
                minLength={8}
                autoComplete="new-password"
                placeholder={t(`user.passwordPlaceholder`)}
                className="w-[300px] h-[46px] py-[9px] px-4 !text-base font-normal"
              />
            </Form.Item>
          </FormItem>

          <FormItem
            label={t('user.confirmPassword')}
            required
            className="pl-5 label-custom-select"
          >
            <Form.Item
              name="confirmPassword"
              rules={[Validators.confirmPasswordValidator(t)]}
              className="mb-0"
              dependencies={['password']}
            >
              <Input.Password
                maxLength={14}
                minLength={8}
                autoComplete="new-confirmPassword"
                placeholder={t(`user.passwordPlaceholder`)}
                className="w-[300px] h-[46px] py-[9px] px-4 !text-base font-normal"
              />
            </Form.Item>
          </FormItem>

          <div className="text-base text-center text-[#021120] underline-offset-2 mt-6">
            <Checkbox
              className="mr-3"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <Link to="/policies?source=signup" target="_blank">
              <span className="underline theme-text-primary cursor-pointer">
                デジトリ360利用上の遵守・誓約事項
              </span>
            </Link>
            に同意する
          </div>

          <Button
            type="primary"
            className="mx-auto my-6"
            sizing="w-[400px] h-[60px]"
            htmlType="submit"
          >
            {t('user.buttonRegister')}
          </Button>
        </Form>
      </div>
    </Spinner>
  )
}
