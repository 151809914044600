import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Empty, Typography } from 'antd'
import dayjs from 'dayjs'
import Api from 'services/api'
import { Notification } from 'types/notification'

import Spinner from 'components/atoms/Spinner'

export default function SmallNotificationList() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Notification[]>([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const { edges }: any = await Api.get('/v1/notifications-public')
        if (edges) setData(edges)
      } catch (error) {}
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <div className="bg-white rounded-[10px] w-full py-4">
      <Spinner spinning={loading}>
        <div
          className={`ml-5 mr-2 h-32 py-3 ${
            data.length ? ' overflow-y-auto custom-scrollbar' : ''
          }`}
        >
          {!loading &&
            !!data.length &&
            data.map((item, index) => (
              <Link
                key={item.id}
                to={`/notifications/${item.id}`}
                target="_blank"
              >
                <div className="grid grid-cols-[auto_auto_1fr] items-center cursor-pointer py-2 pr-2">
                  <div className="w-10 text-[#B80808] text-xs">
                    {index === 0 && 'NEW'}
                  </div>
                  <div className="mr-12 text-black text-sm">
                    {item.createdAt &&
                      dayjs(item.createdAt).format('YYYY/MM/DD')}
                  </div>
                  <Typography.Text
                    ellipsis={{
                      tooltip: item.title
                    }}
                    className="text-black text-sm"
                  >
                    {item.title}
                  </Typography.Text>
                </div>
              </Link>
            ))}
          {!loading && !data.length && (
            <div className="">
              <Empty description="" />
              <div className="text-xs text-center">データがありません</div>
            </div>
          )}
        </div>
      </Spinner>
    </div>
  )
}
