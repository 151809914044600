import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function useDirectory() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const directory = pathname.split('/')[1]

  const logoHeader = useMemo(() => {
    switch (directory) {
      case 'bcp':
        return '/logo/transparent_header_bcp.png'
      default:
        return '/logo/transparent_header.png'
    }
  }, [directory])

  const getDirectory = () => {
    switch (directory) {
      case 'tour':
        return 'tour'
      case 'community':
        return 'tour'
      case 'bcp':
        return 'bcp'
      default:
        return 'shimizu'
    }
  }

  const getDirectoryThemeColor = () => {
    switch (directory) {
      case 'tour':
        return '#91DCE5'
      case 'community':
        return '#91DCE5'
      case 'bcp':
        return '#A1C6B4'
      default:
        return '#609DF9'
    }
  }

  const getDirectoryBasename = () => {
    switch (directory) {
      case 'tour':
        return '/tour'
      case 'bcp':
        return '/bcp'
      default:
        return ''
    }
  }

  const getBuiildingType = () => {
    switch (directory) {
      case 'tour':
        return 10
      case 'bcp':
        return 20
      default:
        return 0
    }
  }

  const handleDirectoryNavigate = (url: string) => {
    navigate(`${getDirectoryBasename()}${url}`)
  }

  return {
    pathname,
    logoHeader,
    rrdNavigate: navigate,
    navigate: handleDirectoryNavigate,
    directory,
    getDirectory,
    getDirectoryThemeColor,
    getDirectoryBasename,
    getBuiildingType
  }
}
