import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { selectedPointDetail } from 'store/buildingStore'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import InitDirectionIcon from 'components/icons/InitDirectionIcon'

type Props = {
  currentSelectedPoint: string | undefined
  setBuildingMode: any
}

export default function DirectionSetting({
  currentSelectedPoint,
  setBuildingMode
}: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const { navigate } = useDirectory()

  const [initView, setInitView] = useState<{ v: number; h: number; z: number }>(
    { v: 0, h: 0, z: 80 }
  )

  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)

  const { trigger, isMutating } = useMutation(
    `v1/points/${currentSelectedPoint}`
  )

  const handleResetDirection = () => {
    if (initView) {
      const krpano = document.getElementById('embedpano-full') as any
      krpano.call(
        `set('view.hlookat', ${initView.h}); set('view.vlookat', ${initView.v});`
      )
    }
  }

  const handleSetDirection = () => {
    const krpano = document.getElementById('embedpano-full') as any
    const previewKrpano = document.getElementById('embedpano-preview') as any
    const hlookat = krpano.get('view.hlookat')
    const vlookat = krpano.get('view.vlookat')
    const fov = krpano.get('view.fov')
    if (!isMutating) {
      trigger(
        {
          method: 'patch',
          data: {
            floor_id: +(floorId || 0),
            building_id: +(id || 0),
            init_view: {
              h: hlookat,
              v: vlookat,
              z: fov,
              radar_h_gap: pointDetail?.initView.radarHGap
            }
          },
          successMessage: t('building.finishedSetting')
        },
        {
          onSuccess: () => {
            previewKrpano?.call(`set(initzoom,${fov});`)
            setBuildingMode((prev: any) => ({ ...prev, selectedMode: '' }))
            setPointDetail(
              (prev) =>
                prev && {
                  ...prev,
                  initView: {
                    ...prev.initView,
                    h: hlookat,
                    v: vlookat,
                    z: fov
                  }
                }
            )
          },
          onError: (error) => {
            setBuildingMode((prev: any) => ({ ...prev, selectedMode: '' }))
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
    } else {
      notification.success({
        message: t('building.finishedSetting')
      })
      setBuildingMode((prev: any) => ({ ...prev, selectedMode: '' }))
    }
  }

  const directionSettingLayout =
    'absolute w-full bottom-0 h-[135px] bg-[#021120] flex gap-[52px] justify-center items-center'

  useEffect(() => {
    const krpano = document.getElementById('embedpano-full') as any
    krpano.call('set(islocked, false)')
    const h = krpano.get('view.hlookat')
    const v = krpano.get('view.vlookat')
    const z = krpano.get('view.fov')
    setInitView({ v, h, z })
    return () => {
      krpano.call('set(islocked, true)')
    }
  }, [])

  return (
    <div className={directionSettingLayout}>
      <div className="space-y-[18px]">
        <div className="flex gap-2">
          <InitDirectionIcon />
          <Typography className="text-[14px] leading-[14px] font-bold text-white">
            設定したい初期方向にして「設定する」ボタンをクリックしてください。
          </Typography>
        </div>

        <Typography className="text-[11px] leading-[11px] text-white">
          ※画像をドラッグすることで方向を変えることができます。
        </Typography>
      </div>

      <div className="flex gap-[10px]">
        <Button sizing="w-[160px] h-[40px]" onClick={handleResetDirection}>
          元の方向に戻す
        </Button>
        <Button
          sizing="w-[160px] h-[40px]"
          type="primary"
          onClick={handleSetDirection}
        >
          設定する
        </Button>
      </div>
    </div>
  )
}
