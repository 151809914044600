import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Divider, Form, Input, notification, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import useSWR from 'swr'
import { InvitedBranchAdmin } from 'types/listOrganization'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import UserGroupIcon from 'components/icons/UserGroupIcon'

import ListBranchAdmin from './ListBranchAdmin'
import InviteBrandAdmin from './SelectInvite'

type Props = {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>
}

export default function ModalManageBranchAdmin({ setIsOpenModal }: Props) {
  const { id } = useParams()
  const { t } = useTranslation()

  const [filterString, setFilterString] = useState<string>('')
  const [unInviteUser, setUnInviteUser] = useState<number[]>([])
  const [sharedUser, setSharedUser] = useState<InvitedBranchAdmin[]>([])
  const [isRefetchList, setIsRefetchList] = useState(false)

  const { trigger: triggerSave, isMutating: isSaving } = useMutation(
    `v1/organizations/${id}/managers`
  )

  const { isValidating } = useSWR<InvitedBranchAdmin[]>(
    {
      url: `v1/organizations/${id}/managers`,
      params: { keyword: filterString, isRefetchList }
    },
    {
      onSuccess: (data) => {
        setSharedUser(data)
      }
    }
  )

  const handleUnInvite = (value: number) => {
    if (value < 0) {
      setUnInviteUser((prev) => prev.filter((item) => item !== -value))
    } else {
      setUnInviteUser((prev) => [...prev, value])
    }
  }

  const handleClose = () => {
    setIsOpenModal(false)
  }

  const handleUpdate = async () => {
    if (!isSaving && unInviteUser.length) {
      await triggerSave(
        {
          data: {
            userIds: unInviteUser
          }
        },
        {
          onSuccess() {
            setUnInviteUser([])
            setIsRefetchList((prev) => !prev)
          }
        }
      )
    }
    notification.success({
      key: 'update-success',
      message: t('organization.successNotification')
    })
  }

  return (
    <Modal
      open
      width={840}
      rootClassName="shared-permission-modal"
      isEnterable={false}
    >
      <Title title={t('organization.branchAdminManagerSetting')} />

      <InviteBrandAdmin
        isRefetchList={isRefetchList}
        setIsRefetchList={setIsRefetchList}
      />

      <Divider className="border-[#CED0D4] mt-[17px] mb-[14px]" />

      <div className="flex gap-[6px]">
        <UserGroupIcon />
        <Typography className="leading-7 text-[#021120]">
          {t('translation.searchWithEmail')}
        </Typography>
      </div>

      <Form onFinish={(values) => setFilterString(values.filterString)}>
        <div className="flex w-full mt-[6px]">
          <Form.Item name="filterString" className="w-[590px] mr-[30px]">
            <Input
              placeholder="メールアドレスをカンマで区切って入力"
              className="text-sm h-[40px]"
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            sizing="w-[100px] h-[40px]"
            className="flex-shrink-0"
          >
            {t('user.buttonSearch')}
          </Button>
        </div>
      </Form>

      <ListBranchAdmin
        isLoading={isValidating}
        sharedUser={sharedUser || []}
        handleUnInvite={handleUnInvite}
      />

      <div className="flex gap-10 justify-center w-full items-end mt-5">
        <Button onClick={handleClose} sizing="w-[140px] h-[50px]">
          {t('common.cancel')}
        </Button>
        <Button
          loading={isSaving}
          onClick={handleUpdate}
          sizing="w-[140px] h-[50px]"
          className="theme-bg-primary"
        >
          {t('common.confirm')}
        </Button>
      </div>
    </Modal>
  )
}
