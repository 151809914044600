import { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

import FooterTransparent from 'components/organisms/Footer/FooterTransparent'
import HeaderTransparent from 'components/organisms/Header/HeaderTransparent'

export default function DefaultLayout({ children }: PropsWithChildren) {
  const { pathname } = useLocation()

  const isDisplayHeader =
    pathname === '/signin' || pathname === '/smz_signin' || pathname === '/'

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] bg-[#B3CFCB]">
      {!isDisplayHeader ? <HeaderTransparent /> : <div />}

      <div className="grow h-full">{children}</div>
      <FooterTransparent />
    </div>
  )
}
