import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { CurrentUser, Role } from 'models/User'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { BuildingCSV } from 'types/building'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Link from 'components/atoms/Link'

import FormSearch from './FormSearch'
import ModalAddBuilding from './ModalAddBuilding'

type Props = {
  handleSearch: (value: SearchUser) => void
  getNewBuilding: () => void
}
export default function HeaderListBuilding({
  handleSearch,
  getNewBuilding
}: Props) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const sort = searchParams.get('sort') || 'updatedAt'
  const sortOrder = searchParams.get('sortOrder') || OrderByType.descend
  const { getBuiildingType } = useDirectory()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', {
    revalidateOnMount: false
  })

  const { trigger, isMutating } = useMutation('v1/buildings/csv')

  const showModalAddBuilding = () => {
    setIsOpenModal(true)
  }

  const handleCancelModal = () => {
    setIsOpenModal(false)
  }

  const handleExportCsv = () => {
    if (!isMutating) {
      trigger(
        {
          method: 'get',
          data: {
            sort,
            sortOrder,
            building_type: getBuiildingType()
          }
        },
        {
          onSuccess: (data: BuildingCSV[]) => {
            Utils.handleExportCSV(
              data.map((item) => ({
                ...item,
                status: t(`building.${item.status}`),
                prefName: Const.PREFECTURES.find(
                  (pref) => pref.prefCode === item.prefCode
                )?.prefName,
                createdAt: dayjs(item.createdAt).format('YYYY/MM/DD HH:mm'),
                latestUpdated: dayjs(item.latestUpdated).format(
                  'YYYY/MM/DD HH:mm'
                ),
                constructionPeriod: `${
                  item?.constructionPeriodStart
                    ? dayjs(item.constructionPeriodStart).format(
                        'YYYY/MM/DD HH:mm'
                      )
                    : ''
                }${
                  item?.constructionPeriodEnd
                    ? ` ~ ${dayjs(item.constructionPeriodEnd).format(
                        'YYYY/MM/DD HH:mm'
                      )}`
                    : ''
                }`,
                isDeleted: item.isDeleted ? '削除' : '',
                fileCount: item.fileCount
              })),
              Const.BUILDING_CSV_FILEHEADERS,
              Const.BUILDING_CSV_COLUMNHEADERS,
              `digitori_${dayjs().format('YYYYMMDD')}.csv`
            )
          }
        }
      )
    }
  }

  return (
    <>
      {isOpenModal && (
        <ModalAddBuilding
          handleCancel={handleCancelModal}
          getNewBuilding={getNewBuilding}
        />
      )}
      <div className="flex justify-between pb-5 items-center">
        <div className="font-bold text-xl">{t('building.title')}</div>
        <div className="flex gap-[46px]">
          {profile?.role === Role.SA && (
            <Link to="/notifications" className="no-underline">
              <Button type="primary" sizing="w-40 h-10">
                {t('translation.notification')}
              </Button>
            </Link>
          )}
          {profile?.role === Role.SA && (
            <Button type="primary" sizing="w-40 h-10" onClick={handleExportCsv}>
              {t('building.buttonExportCSV')}
            </Button>
          )}
          {(profile?.role === Role.BA || profile?.role === Role.SA) && (
            <Button
              type="primary"
              sizing="w-40 h-10"
              onClick={showModalAddBuilding}
            >
              {t('building.buttonAdd')}
            </Button>
          )}
        </div>
      </div>

      <div className="px-10 py-5 flex items-center bg-white rounded-lg shadow-sm mb-5 lg:px-[30px]">
        <FormSearch handleSearch={handleSearch} />
      </div>
    </>
  )
}
