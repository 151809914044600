import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Tooltip, Typography } from 'antd'
import useAuth from 'hooks/useAuth'
import useMutation from 'hooks/useMutation'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Link from 'components/atoms/Link'
import Spinner from 'components/atoms/Spinner'

export default function ContactUs() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [sendSuccessful, setSendSuccessful] = useState(false)
  const { profile } = useAuth()
  const fullName = `${profile?.lastName || ''} ${profile?.firstName || ''}`
  const email = profile?.email || ''
  const [userInformation, setUserInformation] = useState<{
    name: string
    email: string
    phone: string
    contentQuestions: string
  }>()
  const [isSending, setIsSending] = useState(false)
  const formLabel = (label: string) => (
    <div className="flex gap-2 items-center mb-1">
      <Typography className="text-[16px] leading-5 my-auto !text-[#021120]">
        {label}
      </Typography>
      {!userInformation ? (
        <div className="flex items-center justify-center w-[26px] h-[14px] rounded-sm bg-[#B80808]">
          <Typography className="text-[9px] leading-5 text-white">
            {t('common.form.required')}
          </Typography>
        </div>
      ) : null}
    </div>
  )

  const submitedContent = (content: string, clsName?: string) => (
    <div
      className={`px-4 mt-[14px] pb-[14px] border-0 border-b border-solid border-[#CED0D4] !overflow-y-auto !overflow-x-hidden !whitespace-pre-wrap !break-words min-h-[36px] max-h-[36px] mb-5 ${clsName}`}
    >
      <Typography className=" text-[#021120] text-sm">
        {content.trim()}
      </Typography>
    </div>
  )

  const styleBorderInput =
    'border-[#d9d9d9] border-solid border-[1px] rounded-[8px] bg-[#0000000a] cursor-not-allowed'

  const styleSubmitContent =
    'border-0 border-b border-solid border-[#CED0D4] mb-5'
  const typographyTooltip = (title: string, className: string) => {
    const isTitle = title.length > 40 ? title : undefined
    return (
      <Tooltip placement="top" title={isTitle}>
        <Typography
          className={`px-4 py-[14px] !text-[#021120] w-full whitespace-nowrap h-[48px] flex items-center text-[14px] ${className}`}
        >
          {Utils.truncateString(title, 40)}
        </Typography>
      </Tooltip>
    )
  }

  const { trigger: sendContactUs, isMutating } =
    useMutation('/v1/users/contact')

  const handleSubmit = (values: any) => {
    if (!userInformation?.contentQuestions) {
      setUserInformation(values)
    } else {
      !isMutating &&
        sendContactUs(
          {
            method: 'post',
            data: {
              ...userInformation,
              name: fullName,
              email
            }
          },
          {
            onSuccess: () => {
              setIsSending(false)
              setSendSuccessful(true)
            },
            onError: () => {
              setIsSending(false)
              setSendSuccessful(false)
            }
          }
        )
    }
  }

  const handleCancel = () => {
    if (!userInformation?.contentQuestions) {
      navigate(-1)
    } else {
      setUserInformation(undefined)
    }
  }
  return (
    <div className="h-full flex justify-center items-center">
      <div
        className={`flex flex-col gap-[30px] bg-white rounded-[20px] shadow-[0_0_10px_0_#00000026] ${
          sendSuccessful
            ? 'w-[470px] py-[60px] px-[60px]'
            : `${
                userInformation?.contentQuestions ? 'w-[504px]' : 'w-[520px]'
              } py-10 px-[60px]`
        }`}
      >
        {isSending && <Spinner />}
        {!sendSuccessful ? (
          <>
            <div className="m-auto h-10 border-0 border-b border-solid theme-border-primary">
              <Typography className="text-[20px] leading-[29px] font-bold theme-text-primary">
                {!userInformation?.contentQuestions
                  ? t('translation.contactUs')
                  : t('translation.confirmRequestContact')}
              </Typography>
            </div>
            <div className="flex flex-col gap-[4px] text-center">
              {!userInformation?.contentQuestions ? (
                <>
                  <Typography className="text-[16px] leading-[26px] !text-[#021120]">
                    {t('translation.headerContact1')}
                  </Typography>
                  <Typography className="text-[16px] leading-[26px] !text-[#021120]">
                    {t('translation.headerContact2')}
                  </Typography>
                </>
              ) : (
                <Typography className="text-[16px] leading-[26px] !text-[#021120]">
                  {t('translation.headerContactReview')}
                </Typography>
              )}
            </div>
            <Form form={form} colon={false} onFinish={handleSubmit}>
              <div
                className={`flex flex-col m-auto ${
                  userInformation?.contentQuestions ? 'w-[384px]' : 'w-[360px]'
                }`}
              >
                {formLabel(t('translation.nameContactLabel'))}
                {!userInformation?.contentQuestions ? (
                  <Form.Item name="name" className="mb-5 thin-error-message">
                    {typographyTooltip(fullName, styleBorderInput)}
                  </Form.Item>
                ) : (
                  typographyTooltip(fullName, styleSubmitContent)
                )}
                {formLabel(t('user.email'))}
                {!userInformation?.contentQuestions ? (
                  <Form.Item name="email" className="mb-5 thin-error-message">
                    {typographyTooltip(email, styleBorderInput)}
                  </Form.Item>
                ) : (
                  typographyTooltip(email, styleSubmitContent)
                )}
                {formLabel(t('user.phoneNumber'))}
                {!userInformation?.contentQuestions ? (
                  <Form.Item
                    name="phone"
                    className="mb-5 thin-error-message"
                    initialValue={profile?.phone || ''}
                    rules={[
                      {
                        validator: Validators.phoneNumberValidator(t)
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      disabled={!!profile?.phone}
                      placeholder={t('user.phoneNumber')}
                      className="px-4 py-[14px] !text-[#021120] thin-light-placeholder"
                    />
                  </Form.Item>
                ) : (
                  submitedContent(userInformation.phone)
                )}
                {formLabel(t('translation.contentContactLabel'))}
                {!userInformation?.contentQuestions ? (
                  <Form.Item
                    name="contentQuestions"
                    className="mb-5 thin-error-message"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          2000,
                          true,
                          true
                        )
                      }
                    ]}
                  >
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2
                      }}
                      className="px-4 py-[14px] !min-h-[88px] !max-h-[88px] !text-[#021120] thin-light-placeholder"
                      placeholder="お問合せ内容を入力してください"
                    />
                  </Form.Item>
                ) : (
                  submitedContent(
                    userInformation.contentQuestions,
                    '!min-h-[71px] !max-h-[71px]'
                  )
                )}
              </div>
              <div className="flex gap-5 w-[380px] m-auto pt-[10px]">
                <Button sizing="w-[180px] h-[60px]" onClick={handleCancel}>
                  {t('translation.buttonBack')}
                </Button>
                <Button
                  type="primary"
                  sizing="w-[180px] h-[60px]"
                  htmlType="submit"
                >
                  {!userInformation?.contentQuestions
                    ? t('translation.buttonConfirm')
                    : t('translation.buttonSend')}
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-5 items-center">
              <div className="m-auto h-10 border-0 border-b border-solid theme-border-primary">
                <Typography className="text-[20px] leading-[29px] font-bold theme-text-primary">
                  {!userInformation?.contentQuestions
                    ? t('translation.contactUs')
                    : t('translation.confirmRequestContact')}
                </Typography>
              </div>
              <div className="w-[224px] h-[156px]">
                <img
                  src="/assets/mail_sent.png"
                  alt=""
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="flex flex-col gap-[10px] text-center">
                <Typography className="text-[16px] leading-[23px] !text-[#021120]">
                  {t('translation.successConfirmContact1')}
                </Typography>
                <Typography className="text-[16px] leading-[23px] !text-[#021120]">
                  {t('translation.successConfirmContact2')}
                </Typography>
              </div>
            </div>
            <Link to="/buildings" className="no-underline">
              <Button type="primary" sizing="w-[350px] h-[60px]">
                {t('common.TOP')}
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  )
}
