import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useDetectVersion from 'hooks/useDetectVersion'

export default function ShimizuLayout() {
  const { pathname } = useLocation()
  const { isVersionAccessable } = useDetectVersion()

  if (
    isVersionAccessable &&
    !isVersionAccessable.includes('torisetsu') &&
    !pathname.includes('version-notfound')
  ) {
    return <Navigate to="/version-notfound" replace />
  }

  return (
    <div className="shimizu-theme">
      <Outlet />
    </div>
  )
}
