import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { useRecoilState } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import { AnnotationType } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

type Props = {
  selectedType: AnnotationType
  setIsOpenConfirm: Dispatch<SetStateAction<boolean>>
  setSelectedType: Dispatch<SetStateAction<AnnotationType>>
}

export default function BCPConfirmChangeAnnotation({
  selectedType,
  setIsOpenConfirm,
  setSelectedType
}: Props) {
  const { t } = useTranslation()
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)

  const handleClose = () => {
    setCurrentSelected((prev) => ({
      ...prev,
      type: selectedType
    }))
    setIsOpenConfirm(false)
  }

  const handleConfirm = () => {
    const type = currentSelected?.type
    setCurrentSelected((prev) => ({
      ...prev,
      files: [],
      link: '',
      name: '',
      memo: '',
      type
    }))
    setIsOpenConfirm(false)
    type && setSelectedType(type)
  }

  return (
    <Modal
      width={580}
      onCancel={handleClose}
      rootClassName="shared-permission-modal"
      isEnterable
      onEnter={handleConfirm}
    >
      <div className="flex flex-col gap-10 text-center p-5">
        <Typography className="text-[14px] leading-[21px]">
          {t('building.confirmChangeAnnotation')}
        </Typography>

        <div className="flex gap-5 mt-5">
          <Button sizing="w-[140px] h-[50px] mx-auto" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>
          <Button
            sizing="w-[140px] h-[50px] mx-auto"
            onClick={handleConfirm}
            type="primary"
          >
            {t('building.yes')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
