import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import {
  buildingFilesState,
  listLinkState,
  listPointState,
  revalidateFileListState,
  selectedPoint
} from 'store/buildingStore'
import useSWR from 'swr'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

import { OpenModalDelete } from '../../../../types/building'

type Props = {
  open: OpenModalDelete | undefined
  setOpen: Dispatch<SetStateAction<OpenModalDelete>>
}

export default function ModalDeletePoint({ open, setOpen }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const { navigate } = useDirectory()
  const [points, setPoints] = useRecoilState(listPointState)
  const [, setLinks] = useRecoilState(listLinkState)
  const [currentSelectedPoint, setCurrentSelectedPoint] =
    useRecoilState(selectedPoint)
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [, setRevalidateFileList] = useRecoilState(revalidateFileListState)

  const orderedPoints = useMemo(
    () => [...points].sort((a, b) => a.order - b.order),
    [points]
  )

  const { mutate: refetchPoints } = useSWR(
    {
      url: 'v1/points',
      params: {
        buildingId: id,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger: handleSort, isMutating: isSorting } =
    useMutation('v1/points/reorder')

  const { trigger: deletePoint, isMutating } = useMutation(`v1/points`)

  const handleClose = () => {
    setOpen({ isOpen: '', pointId: '', pointOrder: 0 })
  }

  const handleSortPoint = (orderList: { id: string; order: number }[]) => {
    !isSorting &&
      orderList.length &&
      handleSort({
        data: {
          building_id: Number(id),
          floor_id: Number(floorId),
          orders: orderList
        }
      })
  }

  const handleDelete = () => {
    !isMutating &&
      open &&
      deletePoint(
        {
          method: 'delete',
          data: null,
          params: {
            building_id: id,
            floor_id: floorId,
            point_id: open.pointId
          },
          successMessage: t('building.deleted')
        },
        {
          onSuccess: () => {
            if (open.pointId === currentSelectedPoint) {
              setCurrentSelectedPoint(undefined)
            }
            const newSortedList = orderedPoints
              .filter((item) => item.id !== open.pointId)
              .map((point, index) => ({
                ...point,
                order: index + 1
              }))
            setPoints(newSortedList)
            setLinks((prev) =>
              prev.filter((item) => !item.id.includes(open.pointId))
            )
            setBuildingFiles((prev) => ({
              ...prev,
              files: prev.files.map((item) =>
                item.pointId === open.pointId
                  ? { ...item, pointId: null }
                  : item
              )
            }))
            handleSortPoint(
              newSortedList.map((item, index) => ({
                id: item.id,
                order: index + 1
              }))
            )
            handleClose()
          },
          onError: (err) => {
            if (err.response.data.error.key === 'delete_point_by_other') {
              refetchPoints()
              setRevalidateFileList(1)
              handleClose()
              notification.error({
                message: t('translation.pointAlreadyDeleted')
              })
            }
            Utils.handleErrorNavigate(err, navigate)
          }
        }
      )
  }

  return (
    <Modal
      open={open?.isOpen === 'handleDeletePoint'}
      onCancel={handleClose}
      width={480}
      isEnterable
      onEnter={handleDelete}
    >
      <Title title={t('building.titleDeletePoint')} bottomMargin="mb-8" />

      <div className="flex flex-col gap-10 text-center">
        <Typography className="text-[14px] leading-[21px]">
          {t('building.deleteConfirmation')}
        </Typography>

        <div className="bg-[#F9FBFF] rounded-[10px] py-[18px] px-5 flex justify-center">
          <Typography className="text-[16px] leading-[24px] text-center w-fit">
            ポイント : <span className="pl-2">{open?.pointOrder}</span>
          </Typography>
        </div>

        <div className="flex gap-10 justify-center">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
            onClick={handleDelete}
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
