import { PropsWithChildren } from 'react'

import Header from 'components/organisms/Header/Header'

export default function MainLayout({ children }: PropsWithChildren) {
  return (
    <div className="h-screen min-h-screen grid grid-rows-[auto_1fr]">
      <div className="h-[60px] relative">
        <Header />
      </div>

      <div className="flex py-5 theme-bg-main-layout overflow-auto">
        <div className="grow px-4 transition-all ease-out duration-200 ml-0">
          {children}
        </div>
      </div>
    </div>
  )
}
