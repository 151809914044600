import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { revalidateBuildingDetailState } from 'store/buildingStore'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  floorName: string
  setIsOpen: Dispatch<SetStateAction<string>>
}

export default function DeleteFloorModal({ floorName, setIsOpen }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const { navigate } = useDirectory()

  const [, setRevalidateBuildingDetail] = useRecoilState(
    revalidateBuildingDetailState
  )

  const { trigger: deleteFloor, isMutating } = useMutation(
    `v1/floors/${floorId}`
  )

  const handleClose = () => {
    setIsOpen('')
  }

  const handleSubmit = () => {
    !isMutating &&
      deleteFloor(
        {
          method: 'delete',
          data: null,
          successMessage: t('building.deleted')
        },
        {
          onSuccess: () => {
            setRevalidateBuildingDetail(true)
            navigate(`/buildings/${id}`)
          },
          onError: (error) => {
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
    handleClose()
  }

  return (
    <Modal
      onCancel={handleClose}
      width={580}
      isEnterable
      onEnter={handleSubmit}
    >
      <Title title={t('common.deleteFloorTitle')} bottomMargin="mb-8" />

      <div className="flex flex-col gap-10 text-center">
        <Typography className="text-[14px] leading-[21px]">
          {t('building.deleteConfirmation')}
        </Typography>

        <div className="bg-[#F9FBFF] rounded-[10px] py-[18px] px-5">
          {floorName}
        </div>

        <div className="flex gap-10 justify-center">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
            onClick={handleSubmit}
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
