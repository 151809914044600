import { PropsWithChildren } from 'react'

export default function BgCommonLayout({ children }: PropsWithChildren) {
  return (
    <div
      className="h-screen"
      style={{
        backgroundImage: "url('/assets/common-bg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '101% 100%'
      }}
    >
      {children}
    </div>
  )
}
