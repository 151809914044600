import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import useDirectory from 'hooks/useDirectory'

function CheckedSvg(color: string) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3271 0.326026C10.537 0.117933 10.8204 0.000819805 11.116 4.28766e-06C11.4115 -0.00081123 11.6956 0.114737 11.9066 0.321668C12.1177 0.528599 12.2389 0.810286 12.2439 1.10583C12.2489 1.40137 12.1375 1.68702 11.9336 1.90103L5.94556 9.38603C5.84265 9.49687 5.71844 9.58583 5.58036 9.64758C5.44228 9.70932 5.29317 9.74259 5.14194 9.74539C4.99071 9.74819 4.84047 9.72047 4.7002 9.66388C4.55993 9.60728 4.43251 9.52299 4.32556 9.41603L0.358063 5.44703C0.247532 5.34403 0.158879 5.21983 0.0973912 5.08183C0.0359033 4.94383 0.00284026 4.79486 0.00017508 4.64381C-0.0024901 4.49276 0.0252972 4.34271 0.0818788 4.20263C0.13846 4.06255 0.222677 3.9353 0.329505 3.82847C0.436333 3.72164 0.563584 3.63742 0.703666 3.58084C0.843749 3.52426 0.993792 3.49647 1.14485 3.49914C1.2959 3.5018 1.44487 3.53487 1.58287 3.59635C1.72087 3.65784 1.84507 3.7465 1.94806 3.85703L5.08906 6.99653L10.2986 0.359026C10.3079 0.347407 10.3179 0.336384 10.3286 0.326026H10.3271Z"
        fill={color}
      />
    </svg>
  )
}

export default function CheckedIcon(props: Partial<CustomIconComponentProps>) {
  const { getDirectoryThemeColor } = useDirectory()
  return (
    <Icon component={() => CheckedSvg(getDirectoryThemeColor())} {...props} />
  )
}
