import { useState } from 'react'
import { Form, notification } from 'antd'
import Const from 'constants/constant'
import useAuth from 'hooks/useAuth'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { t } from 'i18next'
import { CurrentUser, Role } from 'models/User'
import useSWR from 'swr'
import { ManageModal, UpdateUserError } from 'types/userDetail'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'

import UserDetailForm from './FormUserDetail'
import DeleteUserModal from './ModalDeleteUser'
import ManageOrganizationModal from './ModalManageOrganization'

type Props = {
  idUser?: string
}

export default function UserInformation({ idUser }: Props) {
  const [form] = Form.useForm()
  const { rrdNavigate, navigate } = useDirectory()
  const [deleteModal, setDeleteModal] = useState(false)
  const [organizationModal, setOrganizationModal] = useState<ManageModal>({
    isOpen: false,
    organizations: []
  })

  const { mutate } = useAuth()

  const {
    data: user,
    isValidating,
    mutate: refetchUserDetail
  } = useSWR<CurrentUser>(
    {
      url: `/v1/users/${idUser}`
    },
    {
      onSuccess: (user) => {
        form.setFieldsValue(user)
      },
      onError: (err) => {
        if (err.response.data.error.key === 'record_not_found') {
          rrdNavigate('/404')
        }
      }
    }
  )

  const { trigger: addOrganization } = useMutation(`/v1/user-organizations`)

  const { trigger: editUser, isMutating: isEditUser } = useMutation(
    `/v1/users/${idUser === 'me' ? user?.id : idUser}`
  )

  const handleSubmit = (values: any) => {
    !isEditUser &&
      editUser(
        {
          data: values,
          successMessage: t('user.createUserSuccess')
        },
        {
          onSuccess: async () => {
            idUser === 'me' && mutate()

            if (
              form.getFieldValue('role') === Role.BA &&
              organizationModal.organizations.length
            ) {
              await addOrganization(
                {
                  data: {
                    userId: user?.id,
                    organizationId: organizationModal.organizations
                  }
                },
                {
                  onSuccess: () => {
                    setOrganizationModal((prev) => ({
                      ...prev,
                      organizations: []
                    }))
                  }
                }
              )
            }

            refetchUserDetail()
          },
          onError: (error) => {
            if (error.response.data.error.key === 'record_not_found') {
              navigate('/users')
              return
            }

            const message =
              Const.UPDATE_USER_ERROR[
                error.response.data.error.key as UpdateUserError
              ]

            message
              ? notification.error({
                  message: t(message)
                })
              : Utils.handleError(error, t, form, message)
          }
        }
      )
  }

  const handleOpenDeleteModal = () => {
    setDeleteModal(true)
  }

  const handleOpenOrganizationModal = () => {
    setOrganizationModal((prev) => ({
      ...prev,
      isOpen: true
    }))
  }

  const handleCloseModal = () => {
    deleteModal && setDeleteModal(false)
    organizationModal &&
      setOrganizationModal((prev) => ({
        ...prev,
        isOpen: false
      }))
  }

  return (
    <Spinner spinning={isValidating || !user}>
      <UserDetailForm
        form={form}
        user={user}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenOrganizationModal={handleOpenOrganizationModal}
        handleSubmit={handleSubmit}
      />

      {deleteModal && (
        <DeleteUserModal
          firstName={user?.firstName}
          lastName={user?.lastName}
          organization={user?.organization?.name}
          handleCloseModal={handleCloseModal}
        />
      )}

      <ManageOrganizationModal
        manageModal={organizationModal}
        setOrganizationModal={setOrganizationModal}
        manageOrganizations={user?.manageOrganizations}
        handleCloseModal={handleCloseModal}
      />
    </Spinner>
  )
}

UserInformation.defaultProps = {
  idUser: undefined
}
