import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, notification, Select, Typography } from 'antd'
import Const from 'constants/constant'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedPoint, selectedPointDetail } from 'store/buildingStore'
import { currentAnnotation } from 'store/krpanoStore'
import useSWR from 'swr'
import { AnnotationType } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import BCPAnnotationCategoryDetail from './BCPAnnotationCategoryDetail'
import BCPConfirmChangeAnnotation from './BCPConfirmChangeAnnotation'
import BCPDeleteAnnotationModal from './BCPDeleteAnnotationModal'

export default function BCPAnnotationModal() {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [form] = Form.useForm()
  const { navigate } = useDirectory()

  const [selectedType, setSelectedType] = useState<AnnotationType>(
    AnnotationType.FIRE
  )
  const [selectedTabs, setSelectedTabs] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isChangeType, setIsChangeType] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [nameParse, setNameParse] = useState<string[]>(['', '', ''])
  const [linkParse, setLinkParse] = useState<string[]>(['', '', ''])
  const [memoParse, setMemoParse] = useState<string[]>(['', '', ''])

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)
  const [, setPointDetail] = useRecoilState(selectedPointDetail)

  const isDataExistsAnnotation =
    !currentSelected?.files?.length &&
    !currentSelected?.link &&
    !currentSelected?.name

  const { trigger, isMutating } = useMutation(`v1/annotations`)

  const { data: bcpCustomFolders } = useSWR({
    url: `v1/bcp/buildings/${id}/folders`
  })

  const annotationList = useMemo(
    () => [
      ...Const.BCP_ANNOTATION_LIST,
      ...(bcpCustomFolders || []).map((item: any) => ({
        value: item.categoryId,
        label: item.name
      }))
    ],
    [bcpCustomFolders]
  )

  const listTabs = useMemo((): { value: number; label: string }[] => {
    if (Object.values(AnnotationType).includes(selectedType || '')) {
      return Const.BCP_ANNOTATION_TABS.map((item) => ({
        value: selectedType + item.value,
        label: item.label
      }))
    }
    return (
      bcpCustomFolders
        ?.find((item: any) => item.categoryId === selectedType)
        ?.childFolders?.map((item: any) => ({
          value: item.categoryId,
          label: item.name
        })) || []
    )
  }, [bcpCustomFolders, selectedType])

  const selectedTabIndex = useMemo(
    () => listTabs.findIndex((item) => item.value === selectedTabs),
    [listTabs, selectedTabs]
  )

  const changeSelectedType = useCallback(
    (value: any) => {
      if (value !== selectedType) {
        setCurrentSelected((prev) => ({
          ...prev,
          type: value
        }))
        if (!isDataExistsAnnotation) {
          setIsChangeType(true)
        } else {
          setSelectedType(value)
          setSelectedTabs(listTabs[0].value)
        }
      }
    },
    [isDataExistsAnnotation, listTabs, selectedType, setCurrentSelected]
  )

  const getUnderlinePosition = () => {
    const leftOffset = selectedTabIndex * 80
    return `left-[${leftOffset}px]`
  }

  const handleCloseModal = () => {
    setCurrentSelected(undefined)
    Krpano.RemoveTempAnnotation()
    notification.destroy('placingAnnotation')
  }

  const hotspotLoadStyle = (krpano: any, data: any) => {
    krpano.call(`remove_annotation(${data.id})`)
    Krpano.AddAnnotation(krpano, [data])
  }

  const createNewAnnotation = (krpano: any, data: any) => {
    krpano.call('hide_temp_hotspot()')
    Krpano.AddAnnotation(krpano, [data])
  }

  const getLinkContent = () => {
    const newContent = [...linkParse]
    if (selectedCategory === 'link') {
      return newContent.map((item: any, index: any) =>
        index === selectedTabIndex ? form.getFieldValue('link').trim() : item
      )
    }
    return newContent
  }

  const getNameContent = () => {
    const newContent = [...nameParse]
    if (selectedCategory === 'link') {
      return newContent.map((item: any, index: any) =>
        index === selectedTabIndex ? form.getFieldValue('name').trim() : item
      )
    }
    return newContent
  }

  const getMemoContent = () => {
    const newContent = [...memoParse]
    if (selectedCategory === 'memo') {
      return newContent.map((item: any, index: any) =>
        index === selectedTabIndex ? form.getFieldValue('memo').trim() : item
      )
    }
    return newContent
  }

  const handleFillParseValue = () => {
    setLinkParse(getLinkContent())
    setMemoParse(getMemoContent())
    setNameParse(getNameContent())
  }

  const handleCategoryBack = () => {
    handleFillParseValue()
    setSelectedCategory('')
  }

  const handleChangeTab = (tab: number) => {
    handleFillParseValue()
    setSelectedTabs(tab)
  }

  const handleAddOrEditAnnotation = () => {
    const krpano = document.getElementById('embedpano-full') as any
    const convertedValue = {
      ...currentSelected,
      name: getNameContent()[0],
      name_occurence: getNameContent()[1],
      name_recovery: getNameContent()[2],
      link: getLinkContent()[0],
      link_occurence: getLinkContent()[1],
      link_recovery: getLinkContent()[2],
      memo: getMemoContent()[0],
      memo_occurence: getMemoContent()[1],
      memo_recovery: getMemoContent()[2],
      type: selectedType,
      files:
        currentSelected?.files && currentSelected?.files.map((item) => item.id)
    }
    !isMutating &&
      trigger(
        {
          method: 'put',
          data: {
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint,
            annotation: convertedValue
          },
          successMessage: t('building.saved')
        },
        {
          onSuccess: ({ data }) => {
            if (!convertedValue.id) {
              setPointDetail(
                (prev) =>
                  prev && {
                    ...prev,
                    annotations: [...(prev?.annotations || []), data]
                  }
              )
              createNewAnnotation(krpano, data)
            } else {
              setPointDetail(
                (prev) =>
                  prev && {
                    ...prev,
                    annotations: prev.annotations.map((item) =>
                      item.id === data.id ? data : item
                    )
                  }
              )
              hotspotLoadStyle(krpano, data)
            }
            handleCloseModal()
          },
          onError: (error) => {
            krpano.call('hide_temp_hotspot()')
            notification.destroy('placingAnnotation')
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const handleDeleteAnnotation = () => {
    setIsDeleting(true)
  }

  useEffect(() => {
    if (currentSelected?.type) {
      setSelectedType(currentSelected.type)
    }
  }, [currentSelected?.type])

  useEffect(() => {
    if (
      currentSelected?.name ||
      currentSelected?.name_occurence ||
      currentSelected?.name_recovery
    ) {
      setNameParse([
        currentSelected?.name || '',
        currentSelected?.name_occurence || '',
        currentSelected?.name_recovery || ''
      ])
    }
  }, [
    currentSelected?.name,
    currentSelected?.name_occurence,
    currentSelected?.name_recovery
  ])

  useEffect(() => {
    if (
      currentSelected?.link ||
      currentSelected?.link_occurence ||
      currentSelected?.link_recovery
    ) {
      setLinkParse([
        currentSelected?.link || '',
        currentSelected?.link_occurence || '',
        currentSelected?.link_recovery || ''
      ])
    }
  }, [
    currentSelected?.link,
    currentSelected?.link_occurence,
    currentSelected?.link_recovery
  ])

  useEffect(() => {
    if (
      currentSelected?.memo ||
      currentSelected?.memo_occurence ||
      currentSelected?.memo_recovery
    ) {
      setMemoParse([
        currentSelected?.memo || '',
        currentSelected?.memo_occurence || '',
        currentSelected?.memo_recovery || ''
      ])
    }
  }, [
    currentSelected?.memo,
    currentSelected?.memo_occurence,
    currentSelected?.memo_recovery
  ])

  useEffect(() => {
    if (listTabs.length) {
      setSelectedTabs(listTabs[0].value)
    }
  }, [listTabs])

  return (
    <>
      <Modal width={840} className="paddingless-modal">
        <div className="pt-10 px-[60px] pb-16">
          <Title title={t('building.titleAddAnnotation')} />
          <div className="flex items-center gap-5 -mt-5">
            <Typography className="text-[#021120] text-[14px] leading-[1]">
              {t('building.annotationType')}
            </Typography>
            <Select
              value={selectedType}
              onSelect={changeSelectedType}
              options={annotationList}
              suffixIcon={<DropdownIconCustom colorIcon="#000000" />}
              style={{
                width: 240,
                fontSize: 14,
                color: '#021120'
              }}
            />
          </div>
          <div className="flex items-center bg-[#247F52] mt-[10px]">
            {selectedCategory ? (
              <div
                className="cursor-pointer h-6 ml-5"
                onClick={handleCategoryBack}
              >
                <LeftArrowIcon size={24} />
              </div>
            ) : null}
            <div className="flex relative">
              {listTabs.map((item) => (
                <div
                  key={item.value}
                  className="flex w-20 h-[50px] cursor-pointer"
                  onClick={() => handleChangeTab(item.value)}
                >
                  <div className="text-base text-white m-auto">
                    {t(item.label)}
                  </div>
                </div>
              ))}
              <div
                className={`absolute ${getUnderlinePosition()} bottom-0 transition-all w-20 h-[5px] bg-[#A1C6B4]`}
              />
            </div>
          </div>
          <div>
            {!selectedCategory ? (
              Const.BCP_ANNOTATION_TAB_CATEGORIES.map((item) => (
                <div
                  key={item.value}
                  className="flex items-center justify-between h-[60px] border-0 border-b border-solid border-[#d9d9d9] cursor-pointer"
                  onClick={() => setSelectedCategory(item.value)}
                >
                  <div className="text-base text-[#021120] font-medium ml-[10px]">
                    {t(item.label)}
                  </div>
                  <div className="mr-5">
                    <RightArrowIcon color="#021120" />
                  </div>
                </div>
              ))
            ) : (
              <BCPAnnotationCategoryDetail
                form={form}
                selectedType={selectedType}
                selectedTabs={selectedTabs}
                selectedTabIndex={selectedTabIndex}
                selectedCategory={selectedCategory}
                linkParse={linkParse}
                nameParse={nameParse}
                memoParse={memoParse}
              />
            )}
          </div>
          <div
            className={`flex pl-5 pr-[30px] ${
              selectedCategory
                ? 'mt-8 pt-5 border-0 border-t border-solid border-[#EEEFF2]'
                : 'mt-16'
            }`}
          >
            {currentSelected?.id ? (
              <Button
                className="bg-[#B80808]"
                sizing="w-[140px] h-[50px]"
                onClick={handleDeleteAnnotation}
              >
                {t('common.delete')}
              </Button>
            ) : null}
            <div className="flex gap-10 ml-auto">
              <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
                {t('common.cancel')}
              </Button>
              <Button
                type="primary"
                sizing="w-[140px] h-[50px]"
                onClick={handleAddOrEditAnnotation}
              >
                {t('common.confirm')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {isDeleting && (
        <BCPDeleteAnnotationModal
          handleCancelDelete={() => setIsDeleting(false)}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isChangeType && (
        <BCPConfirmChangeAnnotation
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setIsOpenConfirm={setIsChangeType}
        />
      )}
    </>
  )
}
