import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import SmallNotificationList from 'pages/notifications/components/SmallNotificationList'

import Title from 'components/atoms/Title'

export default function Homepage() {
  const { t } = useTranslation()

  const { logout } = useAuth()

  const directories = [
    {
      name: 'shimizu-route',
      path: '/buildings',
      bg: '/logo/filled_header.png'
    },
    {
      name: 'bcp-route',
      path: '/bcp/buildings',
      bg: '/logo/digitori360_bcp_route.png'
    },
    {
      name: 'tour-route',
      path: '/tour/buildings',
      bg: '/logo/digitori360_tour_route.png'
    }
  ]

  return (
    <div className="w-[90vw] h-full flex justify-center items-center gap-16 mx-auto">
      <div className="w-full max-w-[800px] flex flex-col items-center gap-44">
        <div className="w-full px-5">
          <div
            className="w-full aspect-[758/140]"
            style={{
              backgroundImage: "url('/logo/digitori360_logo_white.png')",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }}
          />
        </div>
        <SmallNotificationList />
      </div>

      <div className="relative">
        <div className="w-[480px] flex-shrink-0 h-[510px] rounded-[20px] bg-white px-10 py-[60px]">
          <Title title="シリーズ選択" />

          <div className="flex flex-col gap-6 pt-2">
            {directories.map((item) => (
              <Link to={item.path} key={item.name}>
                <div className="flex gap-10 h-[70px]" key={item.name}>
                  <img src={item.bg} alt={item.name} />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div
          className="absolute right-0 -bottom-16 cursor-pointer text-[#F4F8FD] text-[20px] leading-[1] font-medium underline underline-offset-4"
          onClick={logout}
        >
          {t('user.logout')}
        </div>
      </div>
    </div>
  )
}
