import { Link as NavLink, LinkProps } from 'react-router-dom'
import useDirectory from 'hooks/useDirectory'

export default function Link(props: LinkProps) {
  const { getDirectoryBasename } = useDirectory()

  const { to, ...rest } = { ...props }

  return <NavLink to={`${getDirectoryBasename()}${to}`} {...rest} />
}
