import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Empty } from 'antd'
import { CurrentUser, Role } from 'models/User'
import Api from 'services/api'
import useSWR from 'swr'
import { ListNotification, NotificationDetail } from 'types/notification'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import HeaderTransparent from 'components/organisms/Header/HeaderTransparent'

import 'quill/dist/quill.snow.css'

export default function NotificationDetailPage() {
  const { t } = useTranslation()
  const qlContainerRef = useRef<HTMLDivElement>(null)
  const { id: notifyId } = useParams()
  const navigate = useNavigate()

  const [isLoadingContent, setIsLoadingContent] = useState(false)

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', Api.get, {
    revalidateOnMount: false
  })

  const { data: notifications, isValidating: isValidatingList } =
    useSWR<ListNotification>(
      notifyId === 'newest'
        ? {
            url: '/v1/notifications-public',
            params: {
              page: 1,
              pageSize: 1
            }
          }
        : null
    )

  const { data, isValidating } = useSWR<NotificationDetail>(
    notifyId && !isValidatingList
      ? {
          url: `/v1/notifications${
            profile?.role === Role.SA ? '' : '-public'
          }/${notifyId === 'newest' ? notifications?.edges[0].id : notifyId}`
        }
      : null,
    {
      onSuccess: async (data) => {
        if (data?.s3FileURL) {
          setIsLoadingContent(true)
          const response = await fetch(data.s3FileURL)
          const blob = await response.blob()
          const reader = new FileReader()
          reader.onload = async () => {
            const text = reader.result as string
            if (qlContainerRef.current) {
              qlContainerRef.current.innerHTML = text
            }
            setIsLoadingContent(false)
          }
          reader.readAsText(blob)
        }
      },
      onError: () => {
        navigate('/404')
      }
    }
  )

  const handleBack = () => {
    window.close()
  }

  return (
    <div className="min-h-screen grid grid-rows-[auto_1fr_auto] bg-[#F9FBFF]">
      <HeaderTransparent />

      <div className="grow h-full flex z-10">
        <div
          id="iframe-responsive"
          className="h-[600px] my-auto pt-[50px] px-[30px] pb-10 flex flex-col items-center gap-5 bg-white rounded-[20px] shadow-[0_4px_14px_0_#00000040]"
        >
          <div className="flex justify-center flex-col">
            <p className="text-[20px] leading-[30px] text-center text-[#3163B7] mb-0 h-[30px] font-bold">
              {t('translation.notification')}
            </p>
            <hr className="w-20 border-0 border-b border-solid border-[#3163B7]" />
          </div>

          <div className="grow w-full overflow-auto">
            {(isValidating || isLoadingContent) && <Spinner />}

            {data && !isValidatingList && !isValidating && (
              <div className="ql-container ql-snow">
                <div ref={qlContainerRef} className="ql-editor" />
              </div>
            )}

            {!data && !isValidatingList && !isValidating && (
              <div className="mt-10 mb-5 h-80 flex">
                <Empty className="m-auto" />
              </div>
            )}
          </div>

          <div className="flex items-center">
            <Button sizing="w-[140px] h-[50px]" onClick={handleBack}>
              閉じる
            </Button>
          </div>
        </div>
      </div>

      <div className="ml-auto px-7 py-5">
        <img src="/logo/filled_footer.png" alt="test" className="h-[50px]" />
      </div>
    </div>
  )
}
