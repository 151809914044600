import { useMemo } from 'react'
import { CurrentUser } from 'models/User'
import useSWR from 'swr'

export default function useDetectVersion() {
  const { data: profile } = useSWR<CurrentUser | null>(
    {
      url: 'v1/users/me'
    },
    {
      revalidateOnFocus: true
    }
  )

  const isVersionAccessable = useMemo(() => {
    if (profile?.hasAccessToVersion === 0 || profile?.hasAccessToVersion) {
      switch (profile.hasAccessToVersion) {
        case 1:
          return ['torisetsu']
        case 2:
          return ['bcp']
        case 3:
          return ['torisetsu', 'bcp']
        case 4:
          return ['tour']
        case 5:
          return ['torisetsu', 'tour']
        case 6:
          return ['bcp', 'tour']
        case 7:
          return ['torisetsu', 'bcp', 'tour']
        default:
          return []
      }
    }
  }, [profile?.hasAccessToVersion])

  return { isVersionAccessable }
}
