export default function HeaderTransparent() {
  return (
    <div className="flex items-center gap-7 px-7 py-5">
      <img
        src="/logo/filled_header.png"
        alt="logo"
        className="w-[207px] h-[38px]"
      />
    </div>
  )
}
