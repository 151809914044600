import { useRef, useState } from 'react'
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polyline,
  useMapEvents
} from 'react-leaflet'
import { notification } from 'antd'
import { CRS, LatLngTuple } from 'leaflet'
import {
  numberIcon,
  smallNumberIcon
} from 'pages/building/floor/area/CustomMarker'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingModeState,
  floorDetailState,
  listLinkState,
  listPointState,
  selectedPoint
} from 'store/buildingStore'
import { currentPovState } from 'store/krpanoStore'
import { KeyedMutator } from 'swr'
import { Point } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import Spinner from 'components/atoms/Spinner'
import CloseIcon from 'components/icons/CloseIcon'

type Props = {
  imagePath: string | undefined
  isValidating: boolean
  mutate: KeyedMutator<any>
}

export default function MenuMiniMap({
  imagePath,
  isValidating,
  mutate
}: Props) {
  const mapContainerRef = useRef<any>(null)
  const mapContainerPreviewRef = useRef<any>(null)

  const [imgBounds, setImgBounds] = useState<LatLngTuple>([0, 0])
  const [previewImgBounds, setPreviewImgBounds] = useState<LatLngTuple>([0, 0])
  const [openPreview, setOpenPreview] = useState(false)

  const points = useRecoilValue(listPointState)
  const links = useRecoilValue(listLinkState)
  const [currentSelectedPoint, setCurrentSelectedPoint] =
    useRecoilState(selectedPoint)
  const [currentPov, setCurrentPov] = useRecoilState(currentPovState)
  const [, setFloorDetail] = useRecoilState(floorDetailState)
  const [buildingMode, setBuildingMode] = useRecoilState(buildingModeState)

  const markerEventHandler = (point: Point) => {
    return {
      click() {
        Krpano.LoadChecking(() => {
          if (buildingMode.previewMode && !point.image360) {
            notification.error({
              key: 'redirect',
              message: '画像が設定されていません'
            })
            return
          }
          if (buildingMode.previewMode && buildingMode.selectedMode) {
            setBuildingMode((prev) => ({ ...prev, selectedMode: '' }))
          }
          setCurrentSelectedPoint(point.id)
          currentPov && setCurrentPov(null)
          setOpenPreview(false)
        })
      }
    }
  }

  function MapEventHandler() {
    useMapEvents({
      click() {
        setOpenPreview(true)
      }
    })
    return null
  }

  const initMap = (mapContainerRef: any, setImgBounds: any, img?: any) => {
    const height = img?.naturalHeight
    const width = img?.naturalWidth
    setImgBounds([height, width])
    // init bounds of map
    const initBound = [
      [0, 0],
      [height, width]
    ]
    // calculate and set MinZoom + MaxZoom level
    mapContainerRef.current?.setMinZoom(-999)
    mapContainerRef.current?.fitBounds(initBound, { animate: false })
    mapContainerRef.current?.setMaxBounds(initBound)
    const minZoomLevel = mapContainerRef.current?.getBoundsZoom(initBound)
    mapContainerRef.current?.setView([height / 2, width / 2], minZoomLevel, {
      animate: false
    })
    mapContainerRef.current?.setMinZoom(minZoomLevel)
    mapContainerRef.current?.setMaxZoom(minZoomLevel + 5)
  }

  const mapOnReady = (mapContainerRef: any, setImgBounds: any) => {
    Utils.getMeta(imagePath)
      .then((img) => {
        initMap(mapContainerRef, setImgBounds, img)
      })
      .catch(() => {
        mutate().then((floor: any) => {
          setFloorDetail(floor)
          Utils.getMeta(floor?.mapFile.s3Path).then((img) => {
            initMap(mapContainerRef, setImgBounds, img)
          })
        })
      })
  }

  return imagePath ? (
    <>
      {isValidating ? <Spinner /> : null}
      <div className="p-[10px] h-44 border-0 border-t border-b border-solid theme-border-secondary">
        <MapContainer
          ref={mapContainerRef}
          center={[-99999, -99999]}
          zoom={0}
          zoomSnap={0.1}
          zoomDelta={0.5}
          className="area-map-container theme-bg-third"
          style={{
            outlineStyle: 'none'
          }}
          crs={CRS.Simple}
          zoomControl={false}
          attributionControl={false}
          preferCanvas
          whenReady={() => mapOnReady(mapContainerRef, setImgBounds)}
        >
          <MapEventHandler />

          {points?.map((point) => (
            <Marker
              key={`marker ${point.id}`}
              draggable={false}
              zIndexOffset={
                point.id === currentSelectedPoint ? 450 : 400 + point.order
              }
              // leaflet position is latlng with y=lat x=lng
              position={[point.y, point.x]}
              icon={smallNumberIcon(point, currentSelectedPoint)}
            />
          ))}

          {links?.map((link) => (
            <Polyline
              key={link.id}
              // leaflet position is latlng with y=lat x=lng
              positions={[
                [link.point1.y, link.point1.x],
                [link.point2.y, link.point2.x]
              ]}
              color="#000000"
              weight={2}
            />
          ))}

          <ImageOverlay url={imagePath} bounds={[[0, 0], imgBounds]} />
        </MapContainer>
      </div>

      {openPreview && (
        <div className="absolute top-0 left-0 w-screen h-full flex bg-[#00000099] z-[750]">
          <div className="m-auto flex w-[1006px] h-[580px] bg-white relative rounded-[20px]">
            <div
              className="absolute top-5 right-5 cursor-pointer"
              onClick={() => setOpenPreview(false)}
            >
              <CloseIcon />
            </div>
            <div className="w-[700px] h-[490px] m-auto">
              <MapContainer
                ref={mapContainerPreviewRef}
                center={[-99999, -99999]}
                zoom={0}
                zoomSnap={0.1}
                zoomDelta={0.5}
                className="area-map-container"
                crs={CRS.Simple}
                zoomControl={false}
                attributionControl={false}
                preferCanvas
                whenReady={() => {
                  mapOnReady(mapContainerPreviewRef, setPreviewImgBounds)
                }}
              >
                {points?.map((point) => (
                  <Marker
                    key={`preview-marker-${point.id}`}
                    draggable={false}
                    bubblingMouseEvents={false}
                    eventHandlers={markerEventHandler(point)}
                    zIndexOffset={
                      point.id === currentSelectedPoint
                        ? 450
                        : 400 + point.order
                    }
                    // leaflet position is latlng with y=lat x=lng
                    position={[point.y, point.x]}
                    icon={numberIcon(
                      point,
                      false,
                      point.id === currentSelectedPoint
                    )}
                  />
                ))}

                {links?.map((link) => (
                  <Polyline
                    key={`preview-link-${link.id}`}
                    // leaflet position is latlng with y=lat x=lng
                    positions={[
                      [link.point1.y, link.point1.x],
                      [link.point2.y, link.point2.x]
                    ]}
                    color="#000000"
                    weight={2}
                  />
                ))}

                <ImageOverlay
                  url={imagePath}
                  bounds={[[0, 0], previewImgBounds]}
                />
              </MapContainer>
            </div>
          </div>
        </div>
      )}
    </>
  ) : null
}
