import { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  listPointState,
  selectedPoint,
  selectedPointDetail
} from 'store/buildingStore'
import { BuildingFile } from 'types/building'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import DeleteIcon from 'components/icons/DeleteIcon'

type Props = {
  isSelected: boolean
  subImage: BuildingFile
  handleSelectSubImage: (file: BuildingFile) => void
}

export default function PointSubImage({
  isSelected,
  subImage,
  handleSelectSubImage
}: Props) {
  const { id: buildingId, floorId } = useParams()

  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)
  const [, setPointList] = useRecoilState(listPointState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)

  const { trigger } = useMutation(
    `v1/points/${currentSelectedPoint}/sub-images`
  )

  const handleDeleteSubimage = (e: MouseEvent<HTMLDivElement>, id: number) => {
    e.stopPropagation()
    trigger(
      {
        method: 'delete',
        data: null,
        query: [`${id}`],
        params: {
          building_id: +(buildingId || 0),
          floor_id: +(floorId || 0)
        }
      },
      {
        onSuccess: () => {
          const subImages = pointDetail?.subImages?.filter(
            (item) => item.id !== id
          )
          setPointList((prev) =>
            prev.map((item) =>
              item.id === currentSelectedPoint
                ? { ...item, isSubImages: Boolean(subImages?.length) }
                : item
            )
          )
          setPointDetail(
            (prev) =>
              prev && {
                ...prev,
                isSubImages: Boolean(subImages?.length),
                subImages
              }
          )
        }
      }
    )
  }

  return (
    <div
      className={`min-h-[36px] p-[10px] flex border-0 border-b border-solid theme-border-secondary relative ${
        isSelected ? 'theme-bg-sub-primary' : 'theme-bg-third'
      }`}
      onClick={() => handleSelectSubImage(subImage)}
    >
      <LazyThumbnail
        url={subImage.thumbnail || subImage.s3Path}
        name={subImage.name}
      />
      <div className="absolute top-3 right-3">
        <div
          className="cursor-pointer w-5 h-5 z-20 flex justify-center items-center"
          onClick={(e) => handleDeleteSubimage(e, subImage.id)}
        >
          <DeleteIcon />
        </div>
      </div>
    </div>
  )
}
