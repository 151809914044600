import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useDetectVersion from 'hooks/useDetectVersion'

export default function BcpLayout() {
  const { pathname } = useLocation()
  const { isVersionAccessable } = useDetectVersion()

  if (
    isVersionAccessable &&
    !isVersionAccessable.includes('bcp') &&
    !pathname.includes('version-notfound')
  ) {
    return <Navigate to="/bcp/version-notfound" replace />
  }

  return (
    <div className="bcp-theme">
      <Outlet />
    </div>
  )
}
