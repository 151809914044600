import React, { useCallback, useEffect, useRef, useState } from 'react'

import NoImageIcon from 'components/icons/NoImageIcon'

import Spinner from './Spinner'

type Props = {
  url: string
  name: string
  className?: string
  width?: number
  height?: number
}

export default function LazyThumbnail({
  url,
  name,
  className,
  width,
  height
}: Props) {
  const imageRef = useRef<any>()
  const [imageUrl, setImageUrl] = useState('')

  const [online, setOnline] = useState(false)

  const onlineListener = useCallback(() => setOnline(false), [setOnline])

  useEffect(() => {
    const observer = new IntersectionObserver(() => {
      setImageUrl(url || '/assets/no-image.svg')
    })
    imageRef.current && !online && observer.observe(imageRef.current)
    return () => observer.disconnect()
  }, [imageUrl, online, url])

  useEffect(() => {
    window.addEventListener('online', onlineListener)

    return () => {
      window.removeEventListener('online', onlineListener)
    }
  }, [onlineListener])

  return (
    <div className={`flex relative ${className} z-0 bg-[#CED0D5]`}>
      {!url ? <Spinner /> : null}
      {online ? (
        <div className="m-auto">
          <NoImageIcon width={width} height={height} />
        </div>
      ) : (
        <img
          ref={imageRef}
          className={`w-full h-full object-contain select-none ${
            imageUrl ? '' : 'invisible'
          }`}
          loading="lazy"
          onError={() => imageUrl && setOnline(true)}
          src={imageUrl}
          alt={name}
          draggable={false}
        />
      )}
    </div>
  )
}

LazyThumbnail.defaultProps = {
  className: 'w-full aspect-[2/1] max-w-[220px]',
  width: 220,
  height: 110
}
