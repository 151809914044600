import { Dropdown, MenuProps, Tooltip } from 'antd'
import useAuth from 'hooks/useAuth'
import useDirectory from 'hooks/useDirectory'

import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  t: any
}

export default function UserDropdown({ t }: Props) {
  const { profile, logout } = useAuth()
  const nameCharacterLength =
    (profile?.lastName || '').length + (profile?.firstName || '').length

  const { navigate, getDirectory } = useDirectory()

  const getUserName = () => {
    if (profile?.lastName && profile.firstName) {
      if (profile.lastName.length > 10) {
        return `${profile.lastName.slice(0, 10)}...`
      }

      if (nameCharacterLength > 10) {
        return `${`${profile.lastName}  ${profile.firstName}`.slice(0, 12)}...`
      }

      return `${profile.lastName}  ${profile.firstName}`
    }
    return ''
  }

  const items: MenuProps['items'] = [
    {
      label: t('user.setting'),
      key: '0',
      onClick: () => navigate(`/users/me`)
    },
    {
      label: t('translation.contactUs'),
      onClick: () => navigate(`/contact-us`),
      key: '1'
    },
    {
      label: t('user.logout'),
      key: '2',
      onClick: logout
    }
  ]
  return profile ? (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement="bottomLeft"
      overlayClassName={`${getDirectory()}-theme`}
      className="custom-user-dropdown min-w-40"
    >
      <div className="flex h-full items-center gap-4 justify-end cursor-pointer pr-5">
        <Tooltip
          title={
            nameCharacterLength > 10
              ? `${profile.lastName}  ${profile.firstName}`
              : ''
          }
        >
          <div className="text-white text-[14px] font-[500]">
            {getUserName()}
          </div>
        </Tooltip>
        <div className="w-4 flex items-center">
          <DropdownIconCustom colorIcon="white" />
        </div>
      </div>
    </Dropdown>
  ) : null
}
