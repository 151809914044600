export type Organization = {
  id: number
  emailDomain: string
  name: string
  nameRuby: string
}

export type CurrentUser = {
  id: number
  organizationId: number
  employeeId: number
  firstName: string
  firstNameRuby: string
  lastName: string
  lastNameRuby: string
  email: string
  phone: string
  companyName: string
  role: Role
  latestLoginAt: string
  createdAt: string
  organization?: Organization
  status: string
  manageOrganizations?: Organization[]
  isInvitationExpired: boolean
  deletedAt: string | null
  invitedById?: number | null
  invitedByEmail?: string
  hasAccessToVersion?: number
}

export type ListUsers = {
  edges: CurrentUser[]
  pagination: {
    page: number
    pageSize: number
    total: number
  }
}

export enum Role {
  BA = 'BA',
  SA = 'SA',
  UA = 'UA',
  USER = 'USER'
}
